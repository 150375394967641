@import '../_variables';

.p-treeselect .p-treeselect-label {
    background-color: white;
}

.p-treeselect .p-treeselect-trigger {
    background-color: white;
}

.p-treeselect-panel .p-treeselect-header {
    background-color: white;
}

body .p-dataview .p-dataview-header {
    background-color: white;
    border: 1px transparent;
}

body .p-tabview .p-tabview-panels {
    border: 1px transparent !important;
}

.p-grid-search {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
}

.search-invite {
    display: flex;
    align-items: flex-end !important;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
}

.invite-search {
    display: flex;
    align-items: flex-end !important;
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: 0rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.phoneNo {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;

    p {
        color: #000;
    }
}

.planText {
    margin: 0rem;
    color: #666678;
    cursor: pointer;

    &.tier {
        color: #000;
        font-weight: bold;
    }

    &:hover {
        color: #2196F3;
    }
}

* {
    font-family: Roboto;
    font-size: 14px;
}

#resend-invitations-button {
    display: flex;
    justify-content: left !important;
    color: #2196F3;
    font-weight: bold;
    cursor: pointer;
    padding-top: 1rem;

}

#resend-invitations-button:hover {
    text-decoration: underline;
}

#delivered {
    font-weight: 700;
    padding-bottom: 0.3rem;
}

.sms {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    width: 12rem;

    .success {
        color: $success;
    }

    .error {
        color: $error;
    }

    .warning {
        color: $warning;
    }

    div {
        display: flex;
        align-items: left;
        justify-content: left;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    p {
        color: #805b36;
        font-weight: bold;
        text-transform: uppercase;
    }

    span {
        color: #666678;
        word-wrap: break-word;
    }

    button {
        background-color: transparent;
        border: none;
        padding: 5px 5px 5px 0px;
        text-transform: capitalize;
        width: 6.5rem;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            background-color: #9999997b !important;
            color: white;
        }
    }

    & .p-button-label {
        font-family: Roboto;
        font-size: 13px;
        color: #2196F3;
    }

    & #cancel-invite .p-button-label {
        font-family: Roboto;
        font-size: 13px;
        color: black !important;
    }
}

.p-datatable-responsive-table {
    padding: 1rem;

    .p-checkbox-box {
        width: 16px !important;
        height: 16px !important;
        border: 1px solid #333 !important;

        &.p-highlight {
            background-color: #fff !important;
            color: #333;
            border-color: #000 !important;
        }

        .p-checkbox-icon {
            color: #333 !important;
            font-size: 12px !important;
        }
    }

    .p-datatable-tbody>tr>td {
        color: #666678;
        border: 0;
        border-bottom: 1px solid #e0e0e0;
    }

    .p-datatable-tbody>tr {
        background-color: #fff !important;
        cursor: default;
    }

    .p-datatable-tbody>tr.p-highlight {
        background-color: #f5f5f5f5 !important;

        td {
            border: 0;
        }
    }


    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
        background-color: #304dae42 !important;
    }

    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
    .p-sortable-column.p-highlight {
        background-color: #304dae42 !important;
        color: #666678;
    }
}

.p-button-label>.cancel-invite {
    color: #000 !important;
}

.p-card .p-card-body {
    padding: 0rem 0.5rem !important;
}


.p-card .p-card-content {
    padding: 0.2rem 0rem;
}

.p-column-header-content {
    display: flex;
    align-items: center;
}

.p-toast-message.p-toast-message-warn {
    background: #FFC107 !important;
    color: #000 !important;
    font-weight: bolder;
    font-size: small;
    opacity: 1;
}

.clear-button {
    padding: 0.8rem;
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px;
}

.action-group {
    display: flex;
    text-align: justify;
    align-items: center;
    padding: 0.5rem;
}

#invitations_length {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

#invitations_selected {
    font-family: Roboto;
    font-size: 14px;
    color: #495057;
}

#invitations-count {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

.invitations-text {
    font-family: Roboto;
    font-size: 14px;
    color: #495057;
    margin-left: 0.2rem;
}

.x-button {
    background-color: red;
    margin-left: 0px;
    color: white;
    border: 1px solid #E0E0E0;
    border-left: none;
}

.send-sms {
    padding: 0.8rem;
    margin-left: 1rem;
    margin-right: 0.8rem;
    font-family: Roboto;
    font-size: 14px;
    color: #495057;
    border-radius: 4px;
    border: 1px solid darkgrey;
}

#cancel-button {
    background-color: transparent;
    color: black;
    border: 1px solid #E0E0E0;
    border-left: none;
}

.filter-group {
    display: flex;
    text-align: justify;
    align-items: center;
    padding: 0;
}

#invitations-card {
    min-width: 98%;
    padding: 0rem;
    height: 5.8rem;
    border-radius: 8px;
    margin-bottom: 0.1rem;
    margin: 1.5rem 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.input-filters {
    width: 17rem;
    margin-right: 0.7rem;
    margin-left: 0.7rem;
}

#cancel-invite {
    border: none;
    color: #000;
}

#invitations_search_input {
    padding: 0.8rem;
    border-right: none;
    background-color: transparent;
    margin-left: 1.5rem;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.invite_form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.invite_dialog {
    text-align: justify;
    display: flex;
    justify-content: space-between;
}

.dialog_header {
    @include fontStyle("RobotoCondensed-Bold", 14px, #f2f2f2);
}

.first_phone_no {
    @include fontStyle("Roboto-Bold", 14px, #d0021b);
    margin: 0rem 0.2rem 0rem 0.2rem;
}

.first_blue_background {
    height: 5vh;
    background-color: #b3e5fc;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.blue_background {
    margin-top: 0.4rem;
    height: 5vh;
    background-color: rgba(179, 229, 252, 0.5);
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.green_background {
    margin-top: 0.4rem;
    height: 5vh;
    background-color: rgba(202, 231, 203, 0.5);
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.new_tier_header {
    @include fontStyle("Roboto-Bold", 14px, #23547B);
}

.current_tier_header {
    margin-top: 1rem;
    @include fontStyle("Roboto-Bold", 14px, #23547B);
}

.first_span {
    @include fontStyle("Roboto-Bold", 14px, #23547B);
}

.long_description {
    height: 5.5rem;
    background-color: #ffd8b2;
    border-left: 3px solid #805b36;
    margin-top: 1.2rem;
}

.second_phoneNo {
    @include fontStyle("Roboto-Bold", 12px, #d0021b);
}

.span_1 {
    @include fontStyle("Roboto-Bold", 12px, #805b36);
    margin-left: 0.4rem;
}

.span_2 {
    @include fontStyle("Roboto-Bold", 12px, #23547B);
    margin: 0rem 0rem 0rem 0.4rem;
}

.span_3 {
    @include fontStyle("Roboto-Bold", 12px, #805b36);
    margin: 0rem 0.2rem 0rem 0.2rem;
}

.red_texts {
    @include fontStyle("Roboto-Bold", 12px, #d0021b);
}

.or_text {
    @include fontStyle("Roboto-Bold", 12px, #805b36);
    margin: 0rem 0.2rem 0rem 0.2rem;
}

.existing_name {
    @include fontStyle("Roboto-Bold", 14px, #23547B);
    margin-left: 0.4rem;
}

.desired_name {
    @include fontStyle("Roboto-Bold", 14px, #256029);
    margin-left: 0.4rem;
}

.amounts {
    @include fontStyle("Roboto-Bold", 14px, #d0021b);
}

.p_hr_tag {
    margin-top: 1.5rem;
    background-color: #666678;
    height: 1px;
}

.description_p {
    padding: 0.5rem;
}

.at_text1 {
    @include fontStyle("Roboto", 14px, #23547B);
    margin: 0rem 0.3rem 0rem 0.3rem;
}

.at_text2 {
    @include fontStyle("Roboto", 14px, #256029);
    margin: 0rem 0.3rem 0rem 0.3rem;
}

.accepted_status {
    background: #c8e6c9;
    color: #256029;
    font-size: 10px;
    border-radius: 15px;
    padding: 8px 15px 8px 15px;
    font-family: "Roboto";
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
    max-width: 100px;
}

.error_status {
    background: #ffcdd2;
    color: #c63737;
    font-size: 10px;
    border-radius: 15px;
    padding: 8px 20px 8px 20px;
    font-family: "Roboto";
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
    max-width: 100px;
}

.pending_status {
    background: #ffd8b2;
    color: #805b36;
    font-size: 10px;
    border-radius: 15px;
    padding: 8px 19px 8px 19px;
    font-family: "Roboto";
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
    max-width: 100px;
}

.p-datatable .p-datatable-thead>tr>th.p-align-center.status-invitations>.p-column-header-content {
    display: flex;
    justify-content: left !important;
    align-content: left !important;
}

.p-column-header-content {
    display: flex !important;
    text-align: left !important;
}

.status-invitations .p-align-center {
    display: flex;
    text-align: left;
}

#invitations_dataTable .p-datatable-thead>tr>th.p-align-center>.p-column-header-content {
    justify-content: left;
}

@media screen and (min-width: 1584px) and (max-width: 2195px) {
    .p-datatable .p-datatable-thead>tr>.p-selection-column {
        padding-left: 0rem;
    }

    .p-datatable .p-datatable-tbody>tr>.table-checkbox-header>div {
        padding-left: 0.35rem;
    }
}

@media screen and (min-width: 1586px) and (max-width: 1669px) {
    .p-datatable .p-datatable-thead>tr>.p-selection-column {
        padding-left: 0rem;
    }

    .p-datatable .p-datatable-tbody>tr>.table-checkbox-header>div {
        padding-left: 0rem;
    }
}

.status_template_invitations {
    min-width: 3rem;
    min-height: 6rem;
    display: flex;
    align-items: center;
}

.subsInfoTemplate_invitations {
    min-width: 12rem;
    padding-bottom: 0.4rem;
}

.planGroup-name-invitations {
    @include fontStyle("Roboto", 11px, #000);
    padding-bottom: 0.3rem;
}

.billing_start {
    @include fontStyle("Roboto-bold", 11px, #000);
    margin-bottom: 0rem;
}

.startDate_invitations {
    @include fontStyle("Roboto", 11px, #000);
    margin-bottom: 3px;
}