* {
  font-family: Roboto;
}


.datatable-demo .p-datatable-responsive .p-datatable-tbody>tr.p-datatable-row>td:last-child .p-column-title {
  display: none;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-details>.p-grid {
  border-radius: 3px;
  margin: 0.3em;
  text-align: center;
  padding: 2em 0 2.25em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-title {
  font-weight: 700;
  font-size: 20px;
  margin-top: 24px;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-subtitle {
  margin: 0.25em 0 2em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button {
  margin-left: 0.5em;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button:first-child {
  margin-left: 0;
}

.p-dataview .car-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
}

.p-dataview .car-details>div {
  display: flex;
  align-items: center;
}

.p-dataview .car-details>div img {
  margin-right: 14px;
}

.p-dataview .car-detail {
  padding: 0 1em 1em 1em;
  margin: 1em;
}

@media (max-width: 1024px) {
  .p-dataview .car-details img {
    width: 75px;
  }
}

/* DataViewDemo.css */

.p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.product-description {
  margin: 0 0 1rem 0;
}

.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

.product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.product-list-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}

.product-list-item .product-list-detail {
  flex: 1 1 0;
}

.product-list-item .p-rating {
  margin: 0 0 0.5rem 0;
}

.product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  align-self: flex-end;
}

.product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}

.product-list-item .p-button {
  margin-bottom: 0.5rem;
}

.product-grid-item {
  margin: 0.5em;
  border: 1px solid #dee2e6;
  padding: 2rem;
}

.product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-grid-item img {
  width: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 2rem 0;
}

.product-grid-item .product-grid-item-content {
  text-align: center;
}

.product-grid-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;
  }

  .product-list-item img {
    width: 75%;
    margin: 2rem 0;
  }

  .product-list-item .product-list-detail {
    text-align: center;
  }

  .product-list-item .product-price {
    align-self: center;
  }

  .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .product-list-action {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

/* DataTableDemo.css */

.datatable-templating-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datatable-templating-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* FormDemo.css */

.form-demo .card {
  min-width: 450px;
}

.form-demo .card form {
  margin-top: 2rem;
}

.form-demo .card .p-field {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}

@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}

.form_size .card {
  min-width: 300px !important;
}

/* ToastDemo.css */

.toast-demo button {
  min-width: 10rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  .toast-demo button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.p-dialog .p-dialog-header {
  /* background-color: white !important; */
  
}

.p-float-label>.disabled {
  color: black !important;

}

/* InputText[type=text] {
  font-weight: bold;
  color: red !important;
} */

.deduction-info {
  margin-top: 0.4rem;
  color: grey;
}

.edit-form {
  height: 36.0rem;
  width: 58.0rem;
}


.subscriber_sort_icon {
  color: blue !important;
  background-color: white !important;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background-color: #fff;
}