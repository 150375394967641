@import "../__variables";

.plans-card-title-overlay {
    background: #fe3031;
    width: 23%;
    border-radius: 10px;
    color: #fff;
    min-width: 7%;
    height: 8em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.toggle_switch {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-left: 1rem;
}

#filters-card {
    .grid {
        justify-content: space-between;
    }
}

body .p-inputswitch .p-inputswitch-slider {
    background-color: #949ba1 !important;
    border-color: #949ba1;
    height: 1.4rem;
    width: 2.6rem;
    border-radius: 18px;
}

body .p-inputswitch.p-highlight .p-inputswitch-slider {
    background-color: #73b6ec !important;
    border-color: #73b6ec;
    height: 1.4rem;
    width: 2.6rem;
    border-radius: 18px;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background-color: #e0e2e4 !important;
}

.pi-info-circle {
    margin-right: 0px !important;
}

body .car-buttons .pi,
body .car-buttons .ui-icon {
    font-size: 1.2rem;
}

.action-button {
    color: grey !important;
    background-color: transparent !important;
    border: none !important;
}

.table_action_button>.action-button>.pi-lock {
    color: grey;
}

.car-buttons .pi-info-circle {
    color: #2196F3;
}

.car-buttons .pi-user-plus {
    color: #6ebe72;
}

.car-buttons .pi-lock {
    color: #d2132b;
}

.car-buttons .tier-disabled .pi-user-plus {
    color: grey;
}

.car-buttons .tier-archive-disabled .pi-lock {
    color: grey !important;
}

.car-buttons .tier-archive-disabled .pi-user-plus {
    color: grey;
}

.car-buttons .tier-change {
    color: #dd4a5c !important;
}

.car-buttons .tier-archive-disabled {
    color: grey !important;
}

.car-buttons .tier-disabled {
    color: grey !important;
}

.car-buttons .tier-archive-disabled {
    color: grey !important;
}

#pi-lock-disabled {
    color: grey !important;
}

.exclusive {
    background-color: #2196F3;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    font-size: 13px !important;
    font-family: "RobotoCondensed-Bold";
    text-align: center;
    height: fit-content;
    width: fit-content;
}

.non-exclusive {
    background-color: #9047f6;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    font-size: 13px !important;
    font-family: "RobotoCondensed-Bold";
    text-align: center;
    height: fit-content;
    width: fit-content;
}

.archive-icon {
    width: 0.9rem !important;
}

.archive-icon .p-button.p-button-icon-only>.p-button-icon {
    color: #FDC237;
}

.car-buttons>.pi .ui-icon {
    font-size: 1.2rem;
}

.active-action-button {
    color: #d2132b !important;
    background-color: white !important;
    border: none !important;
    cursor: pointer !important;
}

.archive-action-button {
    color: #d2132b !important;
    background-color: #2e9924 !important;
    border: none !important;
    cursor: pointer !important;
}

.table_action_button>button {
    margin-right: 0.5rem;
}

.tier_action_button>button {
    margin-right: 0.5rem;
}

#productName {
    @include fontStyle("Roboto-SemiBold", 14px, #000000, 600);
    margin-bottom: 0.4rem;
    text-align: left;
    cursor: pointer;
}

#productName:hover {
    color: #008DD5;
}

#plan_name {
    @include fontStyle("Roboto-SemiBold", 14px, #000000, 600);
    margin-bottom: 0.4rem;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    width: 30rem;
}

#plan_name:hover {
    color: #008DD5;
}

#tierName {
    @include fontStyle("Roboto-Medium", 13px, #000000);
}

#tierPolicy {
    @include fontStyle("Roboto", 12px, #000);
    display: flex;
    justify-content: flex-start !important;
}

.planBillingPeriod {
    @include fontStyle("Roboto-Bold", 12px, #D0021B);
    display: flex;
    justify-content: flex-start !important;
}

.graceDaysLabel,
.reminderDaysLabel {
    @include fontStyle("Roboto-Bold", 12px, #495057);
    display: flex;
    justify-content: flex-start !important;
}

.planGraceDays,
.planReminderDays {
    @include fontStyle("Roboto", 12px, #000);
    padding-left: 0.2rem;
}

.planCost {
    @include fontStyle("Roboto-Bold", 12px, #000);
    display: flex;
    justify-content: flex-start !important;
    margin-top: 0.2rem;
}

#tierDesc {
    @include fontStyle("Roboto", 12px, #000000);
}

#planName:hover {
    @include fontStyle("Roboto-SemiBold", 14px, #000000);
    cursor: pointer;
    color: #03b2cb;
}

#createdOn {
    @include fontStyle("Roboto", 14px, #495057);
    display: flex;
    justify-content: flex-start !important;
}

.group-name {
    @include fontStyle("Roboto", 14px, #666678);
    font-weight: bold;
    margin: 0rem;
    margin-top: 1rem !important;
}

.plan-desc {
    @include fontStyle("Roboto", 12px, #3b3b3b);
    display: flex;
    justify-content: left;
    text-align: left;
    min-width: 25rem;
}

.table_dropdown {
    width: 0.7rem !important;
}

.datatable-cell-default .name-body {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
}

.archive-cell {
    background-color: #f9f9f9 !important;
}

.archive_container>button {
    background-color: #f5f5f5 !important;
}

.archive-cell>.tier_action_button>button {
    background-color: #f5f5f5 !important;
}

.changePriceDisabled>.tier-change {
    opacity: 0;
    cursor: default;
}

.changePriceDisable>.tier-change {
    opacity: 0;
    cursor: default;
}

.list-items {
    padding: 0.4rem;
}

.list-items :hover {
    padding: 0.4rem;
    border-radius: 5px;
}

.list-button :hover {
    background-color: #D8F1FD;
    border-radius: 3px;
}

.tier_action_button>.more-actions-button {
    background-color: white !important;
    color: #585757 !important;
    border: none !important;
}

.tier_action_button>.more-actions-button :hover {
    background-color: white !important;
    color: #363636 !important;
    border: none !important;
}

.tier_action_button>.more-actions-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #d1e6f8;
    -moz-box-shadow: 0 0 0 0.2em #d1e6f8;
    box-shadow: 0 0 0 0.2em #d1e6f8;
}

.plan-balance {
    padding-right: 1.5rem;
    display: flex;

    strong {
        @include fontStyle("Roboto-Bold", 14px, #000, 600);
    }
}

#planDesc {
    margin-bottom: 0.4rem;
    @include fontStyle("Roboto", 12px, #3b3b3b);
}

.action-button .pi {
    font-size: 1.2rem;
}

.header-title {
    color: #777787 !important;
}

.plans-dropdown {
    background-color: #ffff;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #495057;
}

.p-datatable .expanded .p-datatable-thead>tr>th {
    background-color: aliceblue !important;
}

#expanded-table {
    padding: 0px 1rem;

    th.datatable-header-default {
        background: #e6f4fb;

        .p-column-title {
            background-color: aliceblue;
            @include fontStyle("Roboto", 14px, #666678);
            display: flex;
        }
    }

    .datatable-cell-default {
        padding: 0rem 0px;
    }

    .datatable-cell-default.groupName {
        @include fontStyle("Roboto", 12px, #000);
    }

    .deduction {
        @include fontStyle("Roboto-SemiBold", 12px, #000);
    }
}

#expanded-table .datatable-cell-default {
    padding: 0.1rem 0.5rem !important;
}

#expanded-table th.datatable-header-default {
    padding-left: 0.5rem !important;
}

body .p-datatable .p-datatable-tbody>tr>td.chevron .p-row-toggler {
    &:hover {
        color: #343a40;
        border-color: transparent;
        background: #e9ecef;
    }

    &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #c7d2fe;
    }
}

.hearder-style {
    color: red !important;
}

.createdDate {
    display: flex;
    justify-content: flex-start !important;
    margin-block: 1rem;
}

.table_action_button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
    margin: 1.2rem;
}

.tier_action_button {
    display: flex;
    align-items: center;
    margin: 1.5rem 0.2rem 1.5rem 0.2rem;
}

#archived_icon>.p-button-icon {
    font-size: 20px;
    margin-top: 1.8rem;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0.429rem;
}

#plans-dropdown {
    background-color: #fff;
    padding: 0.35rem;
    height: 3rem;
}

.search_box {
    border: 1px #E0E0E0 solid !important;
}

body .p-datatable .p-datatable-tbody>tr .archivedTier .datatable-cell-default {
    background-color: #F5F5F550 !important;
}

body .p-datatable .p-datatable-tbody>tr .archivedTier>.p-column-title {
    color: #777787 !important;
}

.archivedTier .tier_action_button {
    display: flex;
    justify-content: flex-start;
}

#billing {
    @include fontStyle("Roboto-Bold", 25px, #fff);
}

#shortUrl {
    border: none;
}

.shortUrl_div {
    margin-left: 1.3rem;
    margin-bottom: 1rem;
    border: 0.1rem solid #787878;
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    width: 35vw;
    justify-content: space-around;
    height: 15vh;
}

.copy_btn.p-button {
    background-color: #008DD5;
    border: #008DD5;
    color: white;
    width: 6rem;
    height: 5vh;
    border-radius: 2rem;
    margin-left: 0.5rem;
}

.text-style {
    font-weight: 800;
}

body .p-datatable .p-datatable-tbody>tr>td {
    padding-left: 1em;
    padding-right: 1em;
}

#footer-btn-cancel {
    color: #008DD5;
    background-color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    height: 3rem;
    width: auto;
}

.edit_product_footer,
.edit_plan_footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 7px;
    margin: 2rem 0rem 1rem 0rem;
    font-family: "Roboto";
}

.editNameError {
    color: #D0021B;
    font-size: 12px;
}

.plan_edit_labels {
    @include fontStyle("Roboto", 12px, #000);
    font-weight: bold;
}

#archive-btn {
    background-color: #D0021B;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    height: 3rem;
}

#rename-btn {
    background-color: #D0021B;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    height: 3rem;
    width: 6rem;
}

.duplicate-footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 7px;
    margin-bottom: 1rem;
    font-family: "Roboto";
}

.archive-notice {
    font-size: 13px;
    font-family: "Roboto";
    font-weight: bolder;
    color: #D0021B;
}

.archive-dialog-content {
    justify-content: center;
    padding: 10px 10px 0px 10px;
}

.center-align {
    display: flex;
    align-items: center;
}

.exclusivity_hint_on_middle {
    @include fontStyle("Roboto-Bold", 11px, #d0021b);
}

.exclusivity_hint_off_middle {
    @include fontStyle("Roboto-Bold", 11px, #d0021b);
    margin-left: 0.2rem;
}

.card-main-div {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    justify-content: space-between;
}

.subscription-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 0px 7px 0px;
}

.plan-template {
    margin-block: 1rem;
    width: 25rem;
}

.plan-no-desc {
    margin-block: 2rem;
}

.status-renewed,
.status-suscribed,
.status-complete,
.status-completed,
.status-processed,
.status-pending,
.status-accepted,
.status-available,
.status-error,
.status-active,
.status-pending {
    border-radius: 10px;
    padding: 4.5px 21px !important;
}

.status-dates {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 8rem;
}

.stat-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.status-align {
    width: 6rem !important;
    margin: 0 0 0 2rem;
}

.labelOn {
    font-family: "Roboto-Bold";
    font-size: 12px;
    color: #666678;
    font-weight: normal;
    display: block;
    margin: 5px 0 3px 7px;
}

.labelOnDate {
    font-family: "Roboto";
    font-size: 12px;
    color: #495057;
    font-weight: normal;
    display: block;
    flex-wrap: wrap;
    margin-bottom: 3px;
}

.products-labelOnDate {
    font-family: "Roboto";
    font-size: 12px;
    color: #495057;
    font-weight: normal;
    display: block;
    margin-bottom: 3px;
}

.name-desc,
.amt-col,
.bill-period,
.payment-col,
.rev {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.amt-col,
.bill-period,
.payment-col,
.subs-col,
.rev {
    width: 12rem;
}

.name-desc {
    width: 35rem;
}

#planPeriod {
    margin-bottom: 5px;
    color: #d0021b !important;
    font-weight: bold;
}

.no-rev,
.no-revn {
    text-align: left;
}

.no-rev-flex {
    display: flex;
    text-align: left;
    font-weight: bold;
    padding-bottom: 0.2rem;
}

.amt-col,
.revn,
.subs,
.collected {
    font-weight: bold;
}

.revn {
    color: #008DD5;
}

.collected {
    color: #66BB6A;
}

.coll-title {
    color: #66BB6A;
    font-size: 11px;
    margin: 4px 0 0 4px;
}

.rev-title {
    color: #008DD5;
    font-size: 11px;
    margin: 4px 0 0 4px;
}

.subs-title {
    font-size: 11px;
    margin: 3px 0 0 4px;
}

.name-desc:last-child {
    text-align: left;
}

.stat-header {
    padding-left: 2rem !important;
}

.plans-tabs-btn {
    background-color: #f7f7f7;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
}

body .p-button:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em #f492b4;
    -moz-box-shadow: 0 0 0 0.2em #f492b4;
    box-shadow: 0 0 0 0.2em #008dd5;
}

.datesMod {
    padding-top: 0.4rem;
    display: flex;
    flex-direction: row;
}

.plan-amt {
    display: flex;
    padding-bottom: 0.2rem;
}

.plan-info-main {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0rem;
}

@media (min-width:"2560px") {
    .stat-cont {
        width: 3rem;
    }
}

@media (min-width:"768px") and (max-width:"1440px") {
    .stat-cont {
        width: 7rem;
    }

    .datesMod {
        flex-direction: column;
        text-align: left;
    }

    .plan-amt {
        flex-direction: column;
    }

    .createdDate {
        margin-right: 1rem;
        text-align: left;
    }

    .plan-info-main {
        text-align: left;
    }

    .product_date {
        margin-bottom: 2px;
    }
}

.card-spacing {
    text-align: left;
    margin-top: 5px;
}

.card-spacing-hrv {
    text-align: left;
    margin-top: 20px;
}

#setup-m-b {
    margin-bottom: 0.3rem;
}

.upcoming-customer-name {
    @include fontStyle("Roboto", 12px, #495057);
    display: flex;
    text-align: start;
    padding-bottom: 0.3rem;
}

.main-card {
    height: auto;
    margin-bottom: -1rem;
}

.form-style {
    padding-inline: 0.75rem;
}

.old-amt {
    margin-bottom: 0.3rem;
    font-size: 11px;
}

.main-amt {
    height: 3rem;
    font-size: 14px;
}

.btn-style {
    background-color: #d2132b !important;
    font-weight: bold;
    width: 75px !important;
    align-items: end;
}

.btn-div {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 1rem 0 !important;
    font-family: "Roboto";
}

.more-actions-overlaypanel:before,
.more-actions-overlaypanel:after {
    border: solid rgb(149 35 35 / 0%) !important;
    border-bottom: solid white !important;
    border-width: 6px !important;
    left: 20px !important;
}

.more-actions-overlaypanel {
    box-shadow: 0px 1px 3px #d0021b !important;
    /* Optional: adds shadow for emphasis */
}

.payments-body-text {
    color: #000;
    font-size: 12px;
    padding-right: 0.3rem;
}