@import "../__variables";

.PhoneInputInput {
  font-size: 14px;
  color: #212121;
  background: #fff;
  padding: 0.429em;
  border: 1px solid #e0e0e0;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
  appearance: none;
  border-radius: 3px;
}

.column {
  display: flex;
  flex-direction: column;
}

.totalTransfers {
  margin-top: 2rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.5rem;
    color: #666;
    font-weight: 600;
  }

  .actionButton {
    margin-left: auto;

    button {
      margin-left: 1rem;
    }

    .reset {
      background-color: transparent;
      border: #000 solid 1px;
      color: #000;
    }
  }
}

.TotalAmount {
  width: 60%;
  padding: 0px;
  border-radius: 3px;

  h2,
  h3 {
    margin: 0;
  }

  .headerBar {
    background-color: #424a7d;
    padding: 0.5rem;
    padding-left: 1rem;

    .title {
      color: #fff;
      font-size: 1rem;
    }
  }

  .content {
    padding: 1rem;
  }

  .subtitle {
    color: #e91e63;
    font-size: 2rem;
    padding: 1rem;
    padding-left: 0;
    text-transform: uppercase;
  }

  .amounts {
    flex-wrap: wrap;
    justify-content: space-between;

    .amount {
      font-size: 1rem;
      align-items: center;
      min-width: 15rem;
      margin-bottom: 1rem;

      .box {
        border: 1px #424a7d solid;
        width: 90%;
        justify-content: space-around;
        padding: 0.7rem 0.2rem;
        align-items: center;
        border-radius: 5px;
      }

      h3 {
        font-weight: 800;
      }

      button {
        margin-left: 0.5rem;
        background-color: #424a7d;
      }
    }
  }
}

.step {
  max-width: 20rem;
}

.p-dialog-header,
.p-dialog .p-dialog-footer {
  border: none;
}

@media screen and (max-width: 780px) {
  .totalTransfers {
    flex-direction: column;

    .title {
      font-size: 1.2rem;
    }

    .amounts {
      width: 100%;

      .amount {
        font-size: 1rem;
      }
    }
  }
}

.favorite-dropdown {
  margin-left: 1.3rem;
  margin-top: 5px;
}

.favorite-dropdown-title {
  margin-left: 2rem;
  margin-top: 10px;
  height: 4px;
}

.confirmDialog {
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 900;
  text-align: center;

  strong {
    background-color: #e91e6330;
  }
}

.favorite-carousel {
  border: solid 2px $rev_pri;
  border-radius: 5px;
  margin: 5px;
  background-color: white;
  padding: 0 1rem;
}

.card-background {
  background-color: #f2f2f2;
  border: none;
}

.no-background {
  background-color: #f2f2f2;
}

.summary-card {
  background-color: #f2f2f2;
}

.pills-available {
  padding: 0.01rem 0.3rem;
  font-weight: 300;
  font-size: 6px;
  background-color: #c8e6c9;
  color: #256029;
  margin-top: 0.3rem;
}

.pills-pending {
  padding: 0.03rem 0.8rem;
  font-weight: 300;
  font-size: 6px;
  background-color: #ffd8b2;
  color: #805b36;
}

.pills-transactions-completed {
  padding: 0.01rem 0.4rem;
  font-weight: lighter;
  font-size: x-small;
  background-color: #c8e6c9 !important;
  color: #256029
}

.pills-transactions-reversed {
  font-weight: lighter;
  font-size: x-small;
  background-color: #a59e9e;
  color: black;
}

.equal-margin {
  margin-block: 0.5rem;
}

.pills-transactions-completed>.p-tag-value {
  font-size: 12px;
}

.pills-transactions-reversed>.p-tag-value {
  font-size: 12px;
}

.pills-transactions-pending>.p-tag-value {
  font-size: 12px;
}

.pills-transactions-pending {
  padding: 0.02rem 0.5rem;
  font-weight: lighter;
  font-size: 12px;
}

.pills-transactions-pending>.p-tag-value {
  font-size: 12px;
}

.pills-transactions-pending {
  padding: 0.02rem 0.5rem;
  font-weight: lighter;
  font-size: 12px;
  background-color: #fac031;
  color: black;
}

.favourite-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  color: black;
  font-weight: bold !important;
  font-size: 16px !important;
  margin-top: 0.2rem;
}

.carousel-demo .p-carousel .p-carousel-indicators {
  display: none;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-prev {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-next {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.carousel-demo .product-item .product-item-content {
  border: 2px solid $rev_pri;
  background-color: white;
  border-radius: 10px;
  margin: 0.3rem;
  text-align: center;
  padding: 0;
}

.carousel-demo .product-item .product-image {
  width: 50%;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.wallet-table {
  margin-left: -1rem;
}

.payout-width {
  width: 18rem !important;
}

.recipient_text_field {
  height: 3rem;
}

.inner-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.search {
  border: 1px solid rgb(224, 224, 224);
  border-bottom: none;
  margin-right: 1rem;
  margin-bottom: -1rem;
  border-radius: 8px 8px 0px 0px;
}

.card-details {
  padding: 0 1rem;
  background-color: white !important;
  margin-left: 0;
  height: auto;
}

.remove-fav {
  display: flex;
  justify-content: flex-end;
  color: $rev_pri;
  margin-top: 0.3rem;
  margin-right: 0.3rem;
  cursor: pointer;
}

#cancel-fav {
  font-weight: bolder;
  font-size: 1.3rem;
  padding: 0;
}

#cancel-fav {
  font-weight: bolder;
  font-size: 1.3rem;
}

#overview-balance {
  background-color: #2d8cf6;
  color: white;
  height: 8.5rem;
}

#overview-available {
  background-color: #66bb6a;
  color: white;
  height: 8.5rem;
}

#overview-pending {
  background-color: #fbc02d;
  color: white;
  height: 8.5rem;
}

.overview-title {
  font-size: large;
  font-weight: 700;
}

.overview-amount {
  font-size: 16px;
  font-weight: 400;
}

#wallet-search {
  background-color: #f5f5f5;
  color: #cccc;
  border: 1px solid #e0e0e0;
  padding: 0.3rem;
  border-left: none;
}

.wallet-search {
  background-color: red;
  margin-left: 0px;
  color: white;
  border: 1px solid #e0e0e0;
  border-left: none;
}

.wallet-search-field {
  width: 17rem;
  margin-right: 0.7rem;
  margin-left: 0.7rem;
}

.wallet-titles,
.wallet-titles-1 {
  color: #008dd5;
  font-weight: bold;
  font-family: "Roboto";
  font-size: 12px;
  margin-top: 0;
  margin-left: 2px;
}

.wallet-titles-1 {
  margin-bottom: -1.3rem;
}

.action_bar_cards1 {
  margin-top: 2rem;
}

.p-card .p-card-content {
  padding: 0.5rem 0 !important;
}

#wallet-button {
  width: 30px;
  height: 30px;
  background-color: $rev_pri;
}

#wallet-favorite {
  width: 30px;
  height: 30px;
  color: white;
  background-color: $rev_pri;
}

#wallet-unfavorite {
  width: 30px;
  height: 30px;
}

.wallet-pending {
  margin-bottom: 5px;
  @include fontStyle("Roboto", 15px, #000, 500);

  div {
    display: flex;
    align-items: center;
  }

  .status-badge {
    margin-top: 0;
  }
}

.wallet-available {
  margin-bottom: 8px;
  @include fontStyle("Roboto", 15px, #000);

  div {
    display: flex;
    align-items: center;
  }

  .status-badge {
    margin-top: 0;
  }
}

.wallet-balance-title {
  @include fontStyle("Roboto", 13px, #495057);
  margin-bottom: 5px;
}

.wallet-balance {
  margin-bottom: 5px;
  @include fontStyle("Roboto", 14px, #000, bold);
}

.wallet-name-description {
  @include fontStyle("Roboto", 12px, #495057);
  margin-bottom: 5px;
}

.wallet-name {
  margin-bottom: 0.2rem;
  @include fontStyle("Roboto", 14px, #000, bold);
}

.payoutChannel-name {
  @include fontStyle("Roboto", 14px, #000, bold);
}

.wallet-inner-card-details {
  color: black;
  font-weight: 600;
  font-size: 12px;
}

.wallet-inner-data {
  color: black;
  font-weight: lighter;
  font-size: 14px;
}

.requster-name {
  margin-bottom: 0.3rem;
}

.receiver-name {
  margin-bottom: 0.3rem;
}

.main-cannel {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.channel-logo {
  padding-right: 2rem;
  display: flex;
}

.channel-info {
  width: max-content;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.bold-info {
  @include fontStyle("Roboto-Bold", 12px, #000);
  margin-bottom: 0.3rem;
}

.trans-normal-text {
  @include fontStyle("Roboto-Bold", 14px, #000);

  strong {
    color: $rev_gray;
  }
}

.amount {
  @include fontStyle("Roboto-Bold", 14px, #FE3031);
}

trans-normal-text-1 {
  @include fontStyle("Roboto", 12px, #000);
}

.fee {
  @include fontStyle("Roboto-Bold", 11px, #495057);
}

.trans-label {
  @include fontStyle("Roboto-Bold", 11px, #000);
}

.fee1 {
  @include fontStyle("Roboto", 11px, #666678);
}

#normal-info {
  @include fontStyle("Roboto", 11px, #000);
}

#channel-bold-info {
  @include fontStyle("RobotoCondensed-SemiBold", 11px, #000);
}

#channel-normal-info {
  @include fontStyle("Roboto", 10px, #000);
}

#count {
  @include fontStyle("Roboto-Bold", 14px, #000);
}

#count-text {
  @include fontStyle("Roboto", 14px, #495057);
}

.paginator {
  margin: 1rem;
}

#filters-card {
  margin: 1rem;
}

#payment-action-bar {
  padding: 0;
  margin-left: 0px;
}

#payment-action-bar .p-card {
  padding: 0;
  margin-left: 0px;
  padding: 0px !important;
}

#payment-action-bar>.p-card-body {
  padding: 0;
}

#payment-action-bar>.p-card-body .p-card-content {
  padding: 0;
}

#payment-action-bar {
  padding: 0;
  margin-left: 0px;
}

#payment-action-bar .p-card {
  padding: 0;
  margin-left: 0px;
  padding: 0px !important;
}

#payment-action-bar>.p-card-body {
  padding: 0;
}

#payment-action-bar>.p-card-body .p-card-content {
  padding: 0;
}

#payment-action-bar {
  padding: 0;
  margin-left: 0px;
}

#payment-action-bar .p-card {
  padding: 0;
  margin-left: 0px;
  padding: 0px !important;
}

#payment-action-bar>.p-card-body {
  padding: 0;
}

#payment-action-bar>.p-card-body .p-card-content {
  padding: 0;
}

#payment-action-bar {
  padding: 0;
  margin-left: 0px;
}

#payment-action-bar .p-card {
  padding: 0;
  margin-left: 0px;
  padding: 0px !important;
}

#payment-action-bar>.p-card-body {
  padding: 0;
}

#payment-action-bar>.p-card-body .p-card-content {
  padding: 0;
}

.p-dropdown .p-dropdown-clear-icon {
  right: 2rem;
  color: $rev_pri !important;
}

body {

  .p-dropdown-panel .p-dropdown-header,
  .p-dropdown-panel .p-dropdown-filter-container {
    background: transparent;
    border-bottom: 0px;
  }
}

.account-number {
  justify-content: center;
  font-family: "Roboto";
  font-size: 14px;
  color: #000;
  font-weight: normal;
}

.card-buttons {
  display: flex;
  justify-content: center;
}

.payoutChannel-name_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#expanded-table {
  padding: 0px 1rem;

  th.datatable-header-default {
    background: rgba(#008dd5, 0.1);
    height: 35px;

    .p-column-title {
      @include fontStyle("Roboto-Bold", 12px, #495057);
    }
  }

  .datatable-cell-default {
    padding: 0.5rem 0px;
    background-color: #f9f9f9;
    height: 45px;
  }

  .datatable-cell-default.groupName {
    @include fontStyle("Roboto-Bold", 12px, #000);
  }

  .deduction {
    @include fontStyle("Roboto", 12px, #000);
  }
}

body .p-datatable .p-datatable-tbody>tr>td.chevron .p-row-toggler {
  &:hover {
    color: #343a40;
    border-color: transparent;
    background: #e9ecef;
  }

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
  }
}

.availableTransaction {
  font-weight: 700;
  letter-spacing: .3px;
  color: #689f38;
  font-size: 14.5px;
  border-radius: 0.3rem;
  margin-top: 0.1rem;
}

.pendingTransaction {
  font-weight: 700;
  letter-spacing: .3px;
  color: #fbc02d;
  font-size: 14.5px;
  border-radius: 0.3rem;
  margin-top: 1rem;
}

.transaction_card {
  margin: 0rem 0rem 1rem 0rem;
  border-radius: 20px !important;
  height: fit-content;
  padding: 0.9rem 0.5rem 0.1rem !important
}

.active_status {
  background-color: #c8e6c9;
  color: #256029;
  border-radius: 10px;
  padding: 4px 25px !important;
  font-family: "Roboto";
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  width: fit-content;
}

.archive_status {
  background-color: #ffd8b2;
  color: #8a5340;
  border-radius: 10px;
  padding: 4px 25px !important;
  font-family: "Roboto";
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  width: fit-content;
}

.exclusive_tag {
  background-color: #2196F3;
  color: white;
  padding: 0.1rem 0.5rem;
  border-radius: 3px;
  font-size: 12px !important;
  font-family: "RobotoCondensed-Bold";
  text-align: center;
  height: fit-content;
  width: fit-content;
}

.non-exclusive_tag {
  background-color: #9047f6;
  color: white;
  padding: 0.1rem 0.5rem;
  border-radius: 3px;
  font-size: 12px !important;
  font-family: "RobotoCondensed-Bold";
  text-align: center;
  height: fit-content;
  width: fit-content;
}

.createdOn_des,
.last_debited,
.recent_debit {
  font-family: Roboto-Bold;
  font-size: 14px;
  font-weight: large;
  color: #666678;
}

.wallet-favorite,
.wallet-unfavorite {
  margin-top: 3rem;
}

.stat-pill {
  height: 1.5rem
}

.reference {
  @include fontStyle("Roboto-Bold", 14px, #008DD5);
}

.requested_on_header,
.processed_on_header {
  @include fontStyle("Roboto-Bold", 12px, #666678);
  width: 7rem;
}

#requested_on,
#processed_on {
  @include fontStyle("Roboto", 12px, #000);
  width: 7.25rem;
  text-align: end;
}

.prossessed {
  padding: 0.35rem 1.2rem !important;
  color: #256029 !important;
  background-color: #c8e6c9 !important;
}

.requested-info {
  @include fontStyle("Roboto-Bold", 12px, #666678);
  margin-bottom: 0.3rem;
}

.requested_normal-info {
  @include fontStyle("Roboto", 12px, #000);
}

#channel_name {
  @include fontStyle("Roboto", 14px, #000);
}

.account_number {
  @include fontStyle("Roboto", 14px, #000);
  padding: 0.4rem 0rem 0.4rem 0rem
}

.account_name {
  @include fontStyle("Roboto-Bold", 14px, #000);
}

#channel_type {
  @include fontStyle("RobotoCondensed-Bold", 12px, #008DD5);
}

#hr {
  background-color: #666678;
  height: 0.9rem;
  width: 0.1rem;
  margin: 0rem 0.2rem 0rem 0.2rem;
}

#data_table .datatable-header-default .p-column-title {
  margin: 1rem 0rem 1rem 0rem;
  @include fontStyle("Roboto", 12px, #000);
}

.channel-info_div {
  display: flex;
  text-align: start;
  flex-direction: column;
}

#expanded-table th.datatable-header-default .p-column-title {
  background-color: transparent;
}

.activityTemplateHeader {
  text-align: left;
  display: flex;
}

#data_table .datatable-cell-default {
  background-color: #ffffff;
}

.statuses {
  display: flex;
  height: 50px;
  justify-content: center;
}

.reversed_status {
  height: 5px;
}

.reversalReason {
  display: flex;
  flex-direction: column;
}

.status {
  padding: 7px 0px;
}

.wallet_headers {
  font-size: 12px !important;
  color: #666678 !important;
  font-family: "Roboto-Bold";
  font-weight: 700 !important;
}

.wallet_info_header {
  display: flex;
  text-align: left;
}

#remove_no_btn {
  color: #008DD5;
}

#remove_yes_btn {
  background-color: #D0021B;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  height: 3rem;
}

.p-datepicker-buttonbar>button[aria-label="Today"] .p-button-label {
  color: #008DD5;
  font-weight: bold;
}

.p-datepicker-buttonbar>button[aria-label="Clear"] .p-button-label {
  color: #D0021B;
  font-weight: bold;
}

.activity-header {
  & div {
    color: #666678 !important;
    justify-content: left !important;
    text-align: left !important;
    align-items: flex-start !important;
  }

  & span {
    font-size: 12px !important;
    color: #666678;
    font-family: "Roboto-Bold";
  }
}

.status-header {
  & div {
    color: #666678 !important;
    align-items: flex-start !important;
  }

  & span {
    font-size: 12px !important;
    color: #666678;
    font-family: "Roboto-Bold";
  }
}

.p-dropdown-item:hover {
  color: #495057 !important;
  background-color: #cfcfcf !important;
}

.wallet {
  padding-bottom: 6px;
  width: 100%;
  display: block;
}

.wallet-info {
  font-family: "RobotoCondensed";
  font-weight: bold;
  font-size: 18px;
  color: #000;
}

.p-items-hidden .p-carousel-item {
  visibility: visible !important;
}

.addFav-btn {
  background-color: #008DD5 !important;
  color: #fff;
  border: none !important;
}

.errorborder {
  border: 1px solid #EF5350;
}

.error_message {
  color: #73000c;
}

.phone-input {
  width: fit-content !important;
}

.p-inputgroup .p-float-label {
  width: 19rem !important;
  height: 42px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.button-group {
  margin-left: -0.6rem;
}

.autocorrect-placeholder .p-autocomplete-input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #c2c0c0 !important;
}

.autocorrect-placeholder .p-autocomplete-input::placeholder {
  opacity: 1 !important;
}

.but,
.but-1 {
  border-radius: 0px !important;
  outline: none !important;
  box-shadow: none !important;
}

.but-1 {
  border-color: #E91E63 !important;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 1px 5px 7px 5px !important;
  border: 0 none;
  color: #c0c0c0;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0px !important;
}

.fav_icon {
  width: 5rem;
  margin: 0 0 0 0.5rem;
}

.tooltip-content {
  background-color: #fff;
  padding: 0.7rem;
  margin: -0.5rem !important;
  border-radius: 5px;
}

.text-tooltip {
  font-style: italic;
  font-weight: 900;
}

.bal-size {
  font-size: 20px;
}

.plan-name {
  font-size: 1.3rem;
  color: #000;
}

.withdrawListScreen .card-titles {
  margin-left: 0px !important;
}

.withdrawListScreen .customerBodyTemplate {
  width: 30rem
}

.recipient-col {
  width: 55rem
}

.amt-col {
  width: 13rem
}

.withdrawListScreen .wallet-titles {
  margin-left: 0px !important;
}

.withdrawListScreen .products-report-content {
  height: 17rem !important;
}

@media (max-width:"1024px") {
  .withdrawListScreen .products-report-content {
    height: 28rem !important;
  }

  .space-bottom {
    margin-bottom: 1rem;
  }

  .created-space {
    margin-left: 2rem !important;
  }
}

.exclusivity-tags {
  padding: 0rem 0.1rem;
}

.equal-margin {
  margin-block: 1rem;
}

.bal-value {
  @include fontStyle("Roboto", 20px, #495057)
}

.avail-value {
  @include fontStyle("Roboto", 14px, #495057)
}

.pending-value {
  @include fontStyle("Roboto", 12px, #495057)
}

.planModifiedDate {
  color: #666678;
  font-size: 16px;
}

#customer_search {
  background-color: transparent !important;
  border: #d1d5db !important;
  border-radius: 3px !important;
  color: #e5e7eb !important;
  height: 3rem;
  min-width: 25rem;
}

#withdrawals_cancel_btn :active {
  background-color: red;
  box-shadow: none;
  border: none;
}