@charset "UTF-8";

:root {
    --surface-a: #fff;
    --surface-b: #f8f9fa;
    --surface-c: #e9ecef;
    --surface-d: #dee2e6;
    --text-color: #495057;
    --text-color-secondary: #6c757d;
    --font-family: cabin, sans-serif;
    --surface-0: #fff;
    --surface-50: #fafafa;
    --surface-100: #f5f5f5;
    --surface-200: #eee;
    --surface-300: #e0e0e0;
    --surface-400: #bdbdbd;
    --surface-500: #9e9e9e;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #fafafa;
    --gray-100: #f5f5f5;
    --gray-200: #eee;
    --gray-300: #e0e0e0;
    --gray-400: #bdbdbd;
    --gray-500: #9e9e9e;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding: 1rem;
    --border-radius: 4px;
    --surface-ground: #f8f9fa;
    --surface-section: #fff;
    --surface-card: #fff;
    --surface-overlay: #fff;
    --surface-border: #dee2e6;
    --surface-hover: #e9ecef;
    --maskbg: rgba(0, 0, 0, .4);
    --focus-ring: 0 0 0 0.2rem #f6a5c1
}

body .ui-widget {
    font-family: cabin, sans-serif;
    font-size: 1rem;
    text-decoration: none
}

body .ui-icon {
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0;
    overflow: visible;
    position: static;
    text-indent: 0;
    vertical-align: baseline
}

body .pi,
body .ui-icon {
    font-size: 1rem
}

body .ui-widget-overlay {
    animation-duration: .4s;
    animation-fill-mode: forwards;
    -webkit-animation-name: modal-in;
    animation-name: modal-in;
    animation-timing-function: cubic-bezier(.05, .74, .2, .99)
}

body .ui-helper-reset {
    line-height: normal
}

body .ui-state-disabled {
    opacity: .6
}

body .ui-widget-overlay {
    background: rgba(0, 0, 0, .4)
}

:root {
    --blue-50: #f4fafe;
    --blue-100: #cae6fc;
    --blue-200: #a0d2fa;
    --blue-300: #75bef8;
    --blue-400: #4baaf5;
    --blue-500: #2196f3;
    --blue-600: #1c80cf;
    --blue-700: #1769aa;
    --blue-800: #125386;
    --blue-900: #0d3c61;
    --green-50: #f6fbf6;
    --green-100: #d4ecd5;
    --green-200: #b2ddb4;
    --green-300: #90cd93;
    --green-400: #6ebe71;
    --green-500: #4caf50;
    --green-600: #419544;
    --green-700: #357b38;
    --green-800: #2a602c;
    --green-900: #1e4620;
    --yellow-50: #fffcf5;
    --yellow-100: #fef0cd;
    --yellow-200: #fde4a5;
    --yellow-300: #fdd87d;
    --yellow-400: #fccc55;
    --yellow-500: #fbc02d;
    --yellow-600: #d5a326;
    --yellow-700: #b08620;
    --yellow-800: #8a6a19;
    --yellow-900: #644d12;
    --cyan-50: #f2fcfd;
    --cyan-100: #c2eff5;
    --cyan-200: #91e2ed;
    --cyan-300: #61d5e4;
    --cyan-400: #30c9dc;
    --cyan-500: #00bcd4;
    --cyan-600: #00a0b4;
    --cyan-700: #008494;
    --cyan-800: #006775;
    --cyan-900: #004b55;
    --pink-50: #fef4f7;
    --pink-100: #fac9da;
    --pink-200: #f69ebc;
    --pink-300: #f1749e;
    --pink-400: #ed4981;
    --pink-500: #e91e63;
    --pink-600: #c61a54;
    --pink-700: #a31545;
    --pink-800: #801136;
    --pink-900: #5d0c28;
    --indigo-50: #f5f6fb;
    --indigo-100: #d1d5ed;
    --indigo-200: #acb4df;
    --indigo-300: #8893d1;
    --indigo-400: #6372c3;
    --indigo-500: #3f51b5;
    --indigo-600: #36459a;
    --indigo-700: #2c397f;
    --indigo-800: #232d64;
    --indigo-900: #192048;
    --teal-50: #f2faf9;
    --teal-100: #c2e6e2;
    --teal-200: #91d2cc;
    --teal-300: #61beb5;
    --teal-400: #30aa9f;
    --teal-500: #009688;
    --teal-600: #008074;
    --teal-700: #00695f;
    --teal-800: #00534b;
    --teal-900: #003c36;
    --orange-50: #fff8f2;
    --orange-100: #fde0c2;
    --orange-200: #fbc791;
    --orange-300: #f9ae61;
    --orange-400: #f79530;
    --orange-500: #f57c00;
    --orange-600: #d06900;
    --orange-700: #ac5700;
    --orange-800: #874400;
    --orange-900: #623200;
    --bluegray-50: #f7f9f9;
    --bluegray-100: #d9e0e3;
    --bluegray-200: #bbc7cd;
    --bluegray-300: #9caeb7;
    --bluegray-400: #7e96a1;
    --bluegray-500: #607d8b;
    --bluegray-600: #526a76;
    --bluegray-700: #435861;
    --bluegray-800: #35454c;
    --bluegray-900: #263238;
    --purple-50: #faf4fb;
    --purple-100: #e7cbec;
    --purple-200: #d4a2dd;
    --purple-300: #c279ce;
    --purple-400: #af50bf;
    --purple-500: #9c27b0;
    --purple-600: #852196;
    --purple-700: #6d1b7b;
    --purple-800: #561561;
    --purple-900: #3e1046;
    --red-50: #fff5f5;
    --red-100: #ffd1ce;
    --red-200: #ffada7;
    --red-300: #ff8980;
    --red-400: #ff6459;
    --red-500: #ff4032;
    --red-600: #d9362b;
    --red-700: #b32d23;
    --red-800: #8c231c;
    --red-900: #661a14;
    --primary-50: #fef4f7;
    --primary-100: #fac9da;
    --primary-200: #f69ebc;
    --primary-300: #f1749e;
    --primary-400: #ed4981;
    --primary-500: #e91e63;
    --primary-600: #c61a54;
    --primary-700: #a31545;
    --primary-800: #801136;
    --primary-900: #5d0c28
}

body .ui-button {
    background: #e91e63;
    border: 1px solid #e91e63;
    border-radius: 4px;
    color: #fff;
    margin: 0;
    outline: 0 none;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-button.ui-state-hover {
    background: #d81557;
    border-color: #d81557;
    color: #fff
}

body .ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-button.ui-state-active,
body .ui-button.ui-state-down {
    background: #c0134e;
    border-color: #c0134e;
    color: #fff
}

body .ui-button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #e91e63
}

body .ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(233, 30, 99, .04);
    border: 1px solid;
    color: #e91e63
}

body .ui-button.ui-button-outlined.ui-state-active,
body .ui-button.ui-button-outlined.ui-state-down {
    background: rgba(233, 30, 99, .16);
    border: 1px solid;
    color: #e91e63
}

body .ui-button.ui-button-outlined.ui-button-plain {
    border-color: #6c757d;
    color: #6c757d
}

body .ui-button.ui-button-outlined.ui-button-plain.ui-state-hover {
    background: #e9ecef;
    color: #6c757d
}

body .ui-button.ui-button-outlined.ui-button-plain.ui-state-active,
body .ui-button.ui-button-outlined.ui-button-plain.ui-state-down {
    background: #dee2e6;
    color: #6c757d
}

body .ui-button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #e91e63
}

body .ui-button.ui-button-flat.ui-state-hover {
    background: rgba(233, 30, 99, .04);
    border-color: transparent;
    color: #e91e63
}

body .ui-button.ui-button-flat.ui-state-active,
body .ui-button.ui-button-flat.ui-state-down {
    background: rgba(233, 30, 99, .16);
    border-color: transparent;
    color: #e91e63
}

body .ui-button.ui-button-flat.ui-button-plain {
    color: #6c757d
}

body .ui-button.ui-button-flat.ui-button-plain.ui-state-hover {
    background: #e9ecef;
    color: #6c757d
}

body .ui-button.ui-button-flat.ui-button-plain.ui-state-active,
body .ui-button.ui-button-flat.ui-button-plain.ui-state-down {
    background: #dee2e6;
    color: #6c757d
}

body .ui-button.ui-button-text-only .ui-button-text {
    padding: .5rem 1rem
}

body .ui-button.ui-button-text-icon-left .ui-button-text {
    padding: .5rem 1.5rem .5rem 2.5rem
}

body .ui-button.ui-button-text-icon-left .ui-icon {
    left: 50%;
    left: .5rem;
    margin-top: -.5rem;
    position: absolute;
    top: 50%
}

body .ui-button.ui-button-text-icon-right .ui-button-text {
    padding: .5rem 2rem .5rem 1rem
}

body .ui-button.ui-button-text-icon-right .ui-icon {
    margin-top: -.5rem;
    position: absolute;
    right: 50%;
    right: .5rem;
    top: 50%
}

body .ui-button.ui-button-icon-only {
    width: 2.357rem
}

body .ui-button.ui-button-icon-only .ui-button-text {
    padding: .5rem
}

body .ui-button.ui-button-icon-only .ui-icon {
    left: 50%;
    margin-left: -.5rem;
    margin-top: -.5rem;
    position: absolute;
    top: 50%
}

body .ui-button.ui-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

body .ui-button.rounded-button {
    border-radius: 2rem
}

body .ui-button.rounded-button.ui-button-icon-only {
    border-radius: 50%;
    height: 2.357rem
}

body .ui-button .ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-button .ui-icon-triangle-1-s:before {
    content: "\e902"
}

body .ui-selectbooleanbutton,
body .ui-selectmanybutton>.ui-button,
body .ui-selectonebutton>.ui-button {
    background: #fff;
    border: 1px solid #ced4da;
    color: #495057;
    outline: 0 none;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-selectbooleanbutton .ui-icon,
body .ui-selectmanybutton>.ui-button .ui-icon,
body .ui-selectonebutton>.ui-button .ui-icon {
    color: #6c757d
}

body .ui-selectbooleanbutton.ui-state-hover,
body .ui-selectmanybutton>.ui-button.ui-state-hover,
body .ui-selectonebutton>.ui-button.ui-state-hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057
}

body .ui-selectbooleanbutton.ui-state-hover .ui-icon,
body .ui-selectmanybutton>.ui-button.ui-state-hover .ui-icon,
body .ui-selectonebutton>.ui-button.ui-state-hover .ui-icon {
    color: #6c757d
}

body .ui-selectbooleanbutton.ui-state-active,
body .ui-selectmanybutton>.ui-button.ui-state-active,
body .ui-selectonebutton>.ui-button.ui-state-active {
    background: #e91e63;
    border-color: #e91e63;
    color: #fff
}

body .ui-selectbooleanbutton.ui-state-active .ui-icon,
body .ui-selectmanybutton>.ui-button.ui-state-active .ui-icon,
body .ui-selectonebutton>.ui-button.ui-state-active .ui-icon {
    color: #fff
}

body .ui-selectbooleanbutton.ui-state-active:not(.ui-state-disabled):hover,
body .ui-selectmanybutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover,
body .ui-selectonebutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover {
    background: #d81557;
    border-color: #d81557;
    color: #fff
}

body .ui-selectbooleanbutton.ui-state-active:not(.ui-state-disabled):hover .ui-icon,
body .ui-selectmanybutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-icon,
body .ui-selectonebutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-icon {
    color: #fff
}

body .ui-selectmanybutton>.ui-button,
body .ui-selectonebutton>.ui-button {
    border-radius: 0
}

body .ui-selectmanybutton>.ui-button:first-child,
body .ui-selectonebutton>.ui-button:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

body .ui-selectmanybutton>.ui-button:last-child,
body .ui-selectonebutton>.ui-button:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-selectmanybutton>.ui-button.ui-state-focus,
body .ui-selectonebutton>.ui-button.ui-state-focus {
    z-index: 1
}

body .ui-selectmanybutton>.ui-button:not(:last-child),
body .ui-selectonebutton>.ui-button:not(:last-child) {
    border-right-width: 0
}

@media (max-width:640px) {

    body .ui-selectmanybutton>div.ui-button:not(:last-child),
    body .ui-selectonebutton>div.ui-button:not(:last-child) {
        border-bottom-width: 0;
        border-right-width: 1px
    }

    body .ui-selectmanybutton>div.ui-button:first-child,
    body .ui-selectonebutton>div.ui-button:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px
    }

    body .ui-selectmanybutton>div.ui-button:last-child,
    body .ui-selectonebutton>div.ui-button:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-width: 1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0
    }

    body .ui-dataview-layout-options>div.ui-button:not(:last-child) {
        border-bottom-width: 1px;
        border-right-width: 0
    }

    body .ui-dataview-layout-options>div.ui-button:first-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 0
    }

    body .ui-dataview-layout-options>div.ui-button:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 4px
    }
}

body .ui-button-group .ui-button {
    border-radius: 0;
    margin: 0
}

body .ui-button-group .ui-button:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

body .ui-button-group .ui-button:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-button-group .ui-button.ui-state-focus {
    z-index: 1
}

body .ui-button.ui-button-secondary,
body .ui-splitbutton.ui-button-secondary>.ui-button {
    background: #607d8b;
    border: 1px solid #607d8b;
    color: #fff
}

body .ui-button.ui-button-secondary.ui-state-hover,
body .ui-button.ui-button-secondary:not(:disabled):hover,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-state-hover,
body .ui-splitbutton.ui-button-secondary>.ui-button:not(:disabled):hover {
    background: #56717d;
    border-color: #56717d;
    color: #fff
}

body .ui-button.ui-button-secondary.ui-state-focus,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #beccd2
}

body .ui-button.ui-button-secondary.ui-state-active,
body .ui-button.ui-button-secondary:active,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-state-active,
body .ui-splitbutton.ui-button-secondary>.ui-button:active {
    background: #4d646f;
    border-color: #4d646f;
    color: #fff
}

body .ui-button.ui-button-secondary.ui-button-outlined,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #607d8b
}

body .ui-button.ui-button-secondary.ui-button-outlined:enabled:hover,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-outlined:enabled:hover {
    background: rgba(96, 125, 139, .04);
    border: 1px solid;
    color: #607d8b
}

body .ui-button.ui-button-secondary.ui-button-outlined:enabled:active,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-outlined:enabled:active {
    background: rgba(96, 125, 139, .16);
    border: 1px solid;
    color: #607d8b
}

body .ui-button.ui-button-secondary.ui-button-flat,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #607d8b
}

body .ui-button.ui-button-secondary.ui-button-flat:enabled:hover,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-flat:enabled:hover {
    background: rgba(96, 125, 139, .04);
    border-color: transparent;
    color: #607d8b
}

body .ui-button.ui-button-secondary.ui-button-flat:enabled:active,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-flat:enabled:active {
    background: rgba(96, 125, 139, .16);
    border-color: transparent;
    color: #607d8b
}

body .ui-button.ui-button-success,
body .ui-splitbutton.ui-button-success>.ui-button {
    background: #689f38;
    border: 1px solid #689f38;
    color: #fff
}

body .ui-button.ui-button-success.ui-state-hover,
body .ui-splitbutton.ui-button-success>.ui-button.ui-state-hover {
    background: #5e8f32;
    border-color: #5e8f32;
    color: #fff
}

body .ui-button.ui-button-success.ui-state-focus,
body .ui-splitbutton.ui-button-success>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #c2e0a8
}

body .ui-button.ui-button-success.ui-state-active,
body .ui-splitbutton.ui-button-success>.ui-button.ui-state-active {
    background: #537f2d;
    color: #fff
}

body .ui-button.ui-button-success.ui-button-outlined,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #689f38
}

body .ui-button.ui-button-success.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(104, 159, 56, .04);
    border: 1px solid;
    color: #689f38
}

body .ui-button.ui-button-success.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(104, 159, 56, .16);
    border: 1px solid;
    color: #689f38
}

body .ui-button.ui-button-success.ui-button-flat,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #689f38
}

body .ui-button.ui-button-success.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(104, 159, 56, .04);
    border-color: transparent;
    color: #689f38
}

body .ui-button.ui-button-success.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(104, 159, 56, .16);
    border-color: transparent;
    color: #689f38
}

body .ui-button.ui-button-warning,
body .ui-splitbutton.ui-button-warning>.ui-button {
    background: #fbc02d;
    border: 1px solid #fbc02d;
    color: #212529
}

body .ui-button.ui-button-warning.ui-state-hover,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-state-hover {
    background: #fab710;
    border-color: #fab710;
    color: #212529
}

body .ui-button.ui-button-warning.ui-state-focus,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #fde6ab
}

body .ui-button.ui-button-warning.ui-state-active,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-state-active {
    background: #e8a704;
    border-color: #e8a704;
    color: #212529
}

body .ui-button.ui-button-warning.ui-button-outlined,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #fbc02d
}

body .ui-button.ui-button-warning.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(251, 192, 45, .04);
    border: 1px solid;
    color: #fbc02d
}

body .ui-button.ui-button-warning.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(251, 192, 45, .16);
    border: 1px solid;
    color: #fbc02d
}

body .ui-button.ui-button-warning.ui-button-flat,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #fbc02d
}

body .ui-button.ui-button-warning.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(251, 192, 45, .04);
    border-color: transparent;
    color: #fbc02d
}

body .ui-button.ui-button-warning.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(251, 192, 45, .16);
    border-color: transparent;
    color: #fbc02d
}

body .ui-button.ui-button-help,
body .ui-splitbutton.ui-button-help>.ui-button {
    background: #9c27b0;
    border: 1px solid #9c27b0;
    color: #fff
}

body .ui-button.ui-button-help.ui-state-hover,
body .ui-splitbutton.ui-button-help>.ui-button.ui-state-hover {
    background: #8c239e;
    border-color: #8c239e;
    color: #fff
}

body .ui-button.ui-button-help.ui-state-focus,
body .ui-splitbutton.ui-button-help>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #df9eea
}

body .ui-button.ui-button-help.ui-state-active,
body .ui-splitbutton.ui-button-help>.ui-button.ui-state-active {
    background: #7d1f8d;
    border-color: #7d1f8d;
    color: #fff
}

body .ui-button.ui-button-help.ui-button-outlined,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #9c27b0
}

body .ui-button.ui-button-help.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(156, 39, 176, .04);
    border: 1px solid;
    color: #9c27b0
}

body .ui-button.ui-button-help.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(156, 39, 176, .16);
    border: 1px solid;
    color: #9c27b0
}

body .ui-button.ui-button-help.ui-button-flat,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #9c27b0
}

body .ui-button.ui-button-help.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(156, 39, 176, .04);
    border-color: transparent;
    color: #9c27b0
}

body .ui-button.ui-button-help.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(156, 39, 176, .16);
    border-color: transparent;
    color: #9c27b0
}

body .ui-button.ui-button-info,
body .ui-splitbutton.ui-button-info>.ui-button {
    background: #0288d1;
    border: 1px solid #0288d1;
    color: #fff
}

body .ui-button.ui-button-info.ui-state-hover,
body .ui-splitbutton.ui-button-info>.ui-button.ui-state-hover {
    background: #027abc;
    border-color: #027abc;
    color: #fff
}

body .ui-button.ui-button-info.ui-state-focus,
body .ui-splitbutton.ui-button-info>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #89d4fe
}

body .ui-button.ui-button-info.ui-state-active,
body .ui-splitbutton.ui-button-info>.ui-button.ui-state-active {
    background: #026da7;
    border-color: #026da7;
    color: #fff
}

body .ui-button.ui-button-info.ui-button-outlined,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #0288d1
}

body .ui-button.ui-button-info.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(2, 136, 209, .04);
    border: 1px solid;
    color: #0288d1
}

body .ui-button.ui-button-info.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(2, 136, 209, .16);
    border: 1px solid;
    color: #0288d1
}

body .ui-button.ui-button-info.ui-button-flat,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #0288d1
}

body .ui-button.ui-button-info.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(2, 136, 209, .04);
    border-color: transparent;
    color: #0288d1
}

body .ui-button.ui-button-info.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(2, 136, 209, .16);
    border-color: transparent;
    color: #0288d1
}

body .ui-button.ui-button-danger,
body .ui-splitbutton.ui-button-danger>.ui-button {
    background: #d32f2f;
    border: 1px solid #d32f2f;
    color: #fff
}

body .ui-button.ui-button-danger.ui-state-hover,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-state-hover {
    background: #c02929;
    border-color: #c02929;
    color: #fff
}

body .ui-button.ui-button-danger.ui-state-focus,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #edacac
}

body .ui-button.ui-button-danger.ui-state-active,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-state-active {
    background: #aa2424;
    border-color: #aa2424;
    color: #fff
}

body .ui-button.ui-button-danger.ui-button-outlined,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #d32f2f
}

body .ui-button.ui-button-danger.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(211, 47, 47, .04);
    border: 1px solid;
    color: #d32f2f
}

body .ui-button.ui-button-danger.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(211, 47, 47, .16);
    border: 1px solid;
    color: #d32f2f
}

body .ui-button.ui-button-danger.ui-button-flat,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #d32f2f
}

body .ui-button.ui-button-danger.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(211, 47, 47, .04);
    border-color: transparent;
    color: #d32f2f
}

body .ui-button.ui-button-danger.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(211, 47, 47, .16);
    border-color: transparent;
    color: #d32f2f
}

body .ui-commandlink,
body .ui-link {
    color: #e91e63;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-commandlink:hover,
body .ui-link:hover {
    color: #d81557;
    text-decoration: underline
}

body .ui-commandlink:active,
body .ui-link:active {
    color: #c0134e
}

body .ui-splitbutton {
    padding: 0
}

body .ui-splitbutton .ui-button:first-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    margin: 0;
    position: relative
}

body .ui-splitbutton .ui-button:first-child.ui-state-focus {
    z-index: 1
}

body .ui-splitbutton .ui-splitbuttonmenu {
    min-width: 100%
}

body .ui-splitbutton .ui-button.ui-splitbutton-menubutton {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    cursor: pointer;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative
}

body .ui-splitbutton .ui-button.ui-splitbutton-menubutton .ui-icon.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-splitbutton .ui-button.ui-splitbutton-menubutton .ui-icon.ui-icon-triangle-1-s:before {
    content: "\e902"
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container {
    background: #f8f9fa;
    border-bottom: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container .ui-splitbuttonmenu-filter.ui-inputfield {
    padding: .5rem .5rem .5rem 2rem;
    width: 100%
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container .ui-icon {
    background: none;
    bottom: auto;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    left: 1.5rem;
    margin-top: -.5rem;
    position: absolute;
    right: auto;
    top: 50%
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container .ui-icon:before {
    content: "\e908"
}

body .ui-speeddial-button.ui-button.ui-button-icon-only {
    height: 4rem;
    width: 4rem
}

body .ui-speeddial-button.ui-button.ui-button-icon-only .ui-button-icon {
    font-size: 1.3rem
}

body .ui-speeddial-action {
    background: #495057;
    color: #fff;
    height: 3rem;
    width: 3rem
}

body .ui-speeddial-action:hover {
    background: #343a40;
    color: #fff
}

body .ui-speeddial-direction-up .ui-speeddial-item {
    margin: .25rem 0
}

body .ui-speeddial-direction-up .ui-speeddial-item:first-child {
    margin-bottom: .5rem
}

body .ui-speeddial-direction-down .ui-speeddial-item {
    margin: .25rem 0
}

body .ui-speeddial-direction-down .ui-speeddial-item:first-child {
    margin-top: .5rem
}

body .ui-speeddial-direction-left .ui-speeddial-item {
    margin: 0 .25rem
}

body .ui-speeddial-direction-left .ui-speeddial-item:first-child {
    margin-right: .5rem
}

body .ui-speeddial-direction-right .ui-speeddial-item {
    margin: 0 .25rem
}

body .ui-speeddial-direction-right .ui-speeddial-item:first-child {
    margin-left: .5rem
}

body .ui-speeddial-circle .ui-speeddial-item,
body .ui-speeddial-circle .ui-speeddial-item:first-child,
body .ui-speeddial-circle .ui-speeddial-item:last-child,
body .ui-speeddial-quarter-circle .ui-speeddial-item,
body .ui-speeddial-quarter-circle .ui-speeddial-item:first-child,
body .ui-speeddial-quarter-circle .ui-speeddial-item:last-child,
body .ui-speeddial-semi-circle .ui-speeddial-item,
body .ui-speeddial-semi-circle .ui-speeddial-item:first-child,
body .ui-speeddial-semi-circle .ui-speeddial-item:last-child {
    margin: 0
}

body .ui-speeddial-mask {
    background-color: rgba(0, 0, 0, .4)
}

body .ui-carousel .ui-carousel-content .ui-carousel-next,
body .ui-carousel .ui-carousel-content .ui-carousel-prev {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    margin: .5rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-carousel .ui-carousel-content .ui-carousel-next:hover,
body .ui-carousel .ui-carousel-content .ui-carousel-prev:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-carousel .ui-carousel-content .ui-carousel-next:focus,
body .ui-carousel .ui-carousel-content .ui-carousel-prev:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-carousel .ui-carousel-indicators {
    padding: 1rem
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator {
    margin-bottom: .5rem;
    margin-right: .5rem
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator button {
    background-color: #e9ecef;
    border-radius: 0;
    height: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    width: 2rem
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator button:hover {
    background: #dee2e6
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator.ui-state-highlight button {
    background: #e91e63;
    color: #fff
}

body .ui-chronoline .ui-chronoline-event-marker {
    background-color: #fff;
    border: 2px solid #e91e63;
    border-radius: 50%;
    height: 1rem;
    width: 1rem
}

body .ui-chronoline .ui-chronoline-event-connector {
    background-color: #dee2e6
}

body .ui-chronoline.ui-chronoline-vertical .ui-chronoline-event-content,
body .ui-chronoline.ui-chronoline-vertical .ui-chronoline-event-opposite {
    padding: 0 1rem
}

body .ui-chronoline.ui-chronoline-vertical .ui-chronoline-event-connector {
    width: 2px
}

body .ui-chronoline.ui-chronoline-horizontal .ui-chronoline-event-content,
body .ui-chronoline.ui-chronoline-horizontal .ui-chronoline-event-opposite {
    padding: 1rem 0
}

body .ui-chronoline.ui-chronoline-horizontal .ui-chronoline-event-connector {
    height: 2px
}

body .ui-datagrid .ui-datagrid-footer,
body .ui-datagrid .ui-datagrid-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-datagrid .ui-datagrid-header {
    border-bottom: 0
}

body .ui-datagrid .ui-datagrid-footer {
    border-top: 0
}

body .ui-datagrid .ui-datagrid-content {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-datagrid .ui-paginator {
    padding: 1rem
}

body .ui-datagrid .ui-paginator.ui-paginator-top {
    border-bottom: 0
}

body .ui-datagrid .ui-paginator.ui-paginator-bottom {
    border-top: 0
}

body .ui-datalist .ui-datalist-footer,
body .ui-datalist .ui-datalist-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-datalist .ui-datalist-header {
    border-bottom: 0
}

body .ui-datalist .ui-datalist-footer {
    border-top: 0
}

body .ui-datalist .ui-datalist-content {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-datalist .ui-paginator {
    padding: 1rem
}

body .ui-datalist .ui-paginator.ui-paginator-top {
    border-bottom: 0
}

body .ui-datalist .ui-paginator.ui-paginator-bottom {
    border-top: 0
}

body .ui-datascroller .ui-datascroller-footer,
body .ui-datascroller .ui-datascroller-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-datascroller .ui-datascroller-header {
    border-bottom: 0
}

body .ui-datascroller .ui-datascroller-footer {
    border-top: 0
}

body .ui-datascroller .ui-datascroller-content {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-datatable .ui-datatable-header {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-width: 1px 0;
    color: #495057;
    font-weight: 600;
    padding: 1rem
}

body .ui-datatable .ui-datatable-footer {
    background: #f8f9fa;
    border: solid #e9ecef;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-width: 0 0 1px;
    color: #495057;
    font-weight: 600;
    padding: 1rem
}

body .ui-datatable .ui-datatable-footer,
body .ui-datatable .ui-datatable-header {
    text-align: left
}

body .ui-datatable .ui-paginator {
    padding: 1rem
}

body .ui-datatable .ui-paginator.ui-paginator-bottom,
body .ui-datatable .ui-paginator.ui-paginator-top {
    border-radius: 0;
    border-width: 0 0 1px
}

body .ui-datatable thead th {
    background: #f8f9fa;
    border: solid #e9ecef;
    border-width: 0 0 1px;
    box-sizing: content-box;
    color: #495057;
    padding: 1rem;
    text-align: left;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-datatable thead th .ui-column-title {
    font-weight: 700;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon {
    margin: 0 0 0 .5rem
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s:before {
    content: "\e99e"
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n:before {
    content: "\e99f"
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s:before {
    content: "\e9a0"
}

body .ui-datatable thead th .ui-sortable-column-badge {
    background: #e91e63;
    border-radius: 50%;
    color: #fff;
    font-size: .75rem;
    height: 1.143rem;
    line-height: 1.143rem;
    margin-left: .5rem;
    min-width: 1.143rem
}

body .ui-datatable thead th.ui-sortable-column.ui-state-focus {
    box-shadow: inset 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datatable thead th.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-datatable thead th.ui-state-active {
    background: #f8f9fa;
    color: #e91e63
}

body .ui-datatable thead th.ui-state-active.ui-state-hover {
    background: #e9ecef;
    color: #e91e63
}

body .ui-datatable .ui-datatable-data>tr {
    background: #fff;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-datatable .ui-datatable-data>tr>td {
    border: solid rgba(0, 0, 0, .08);
    border-width: 0 0 1px;
    padding: 1rem
}

body .ui-datatable .ui-datatable-data>tr>td.ui-datatable-subtable-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
    font-weight: 700
}

body .ui-datatable .ui-datatable-data>tr.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-datatable .ui-datatable-data>tr.ui-state-highlight .ui-row-editor>a,
body .ui-datatable .ui-datatable-data>tr.ui-state-highlight .ui-row-toggler {
    color: #fff
}

body .ui-datatable .ui-datatable-data>tr.ui-datatable-selectable {
    cursor: pointer
}

body .ui-datatable .ui-datatable-data>tr.ui-datatable-selectable>.ui-grouped-column {
    background: #fff;
    color: #495057;
    cursor: default
}

body .ui-datatable .ui-datatable-data>tr.ui-datatable-summaryrow,
body .ui-datatable .ui-datatable-data>tr.ui-rowgroup-header {
    font-weight: 700
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a.ui-row-editor-check {
    margin-right: .5rem
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil:before {
    content: "\e942"
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-check {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-check:before {
    content: "\e909"
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-close {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-close:before {
    content: "\e90b"
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-e:before {
    content: "\e901"
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-s:before {
    content: "\e902"
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler {
    margin-right: 1rem
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-e:before {
    content: "\e901"
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-s:before {
    content: "\e902"
}

body .ui-datatable .ui-column-resizer-helper,
body .ui-datatable .ui-datatable-data>tr.ui-datatable-rowordering {
    background: #e91e63
}

body .ui-datatable tfoot td {
    background: #f8f9fa;
    border: solid #e9ecef;
    border-width: 0 0 1px;
    box-sizing: content-box;
    color: #495057;
    font-weight: 700;
    padding: 1rem;
    text-align: left
}

body .ui-datatable>.ui-icon-arrowthick-1-s {
    background: none;
    display: inline-block;
    display: none !important;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datatable>.ui-icon-arrowthick-1-s:before {
    content: "\e919"
}

body .ui-datatable>.ui-icon-arrowthick-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    padding-top: 1.5rem !important
}

body .ui-datatable>.ui-icon-arrowthick-1-n:before {
    content: "\e91c"
}

body .ui-datatable .ui-datatable-scrollable-body {
    outline: 0 none
}

body .ui-datatable .ui-datatable-scrollable-footer,
body .ui-datatable .ui-datatable-scrollable-header {
    background: #f8f9fa
}

body .ui-datatable .ui-selection-column .ui-chkbox-all {
    height: auto;
    width: auto
}

body .ui-datatable.ui-datatable-striped .ui-datatable-data>tr.ui-datatable-odd {
    background: #fbfcfc
}

body .ui-datatable.ui-datatable-striped .ui-datatable-data>tr.ui-datatable-odd.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-datatable.ui-datatable-striped .ui-datatable-data>tr.ui-datatable-odd.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-datatable.ui-datatable-sm thead>tr>th {
    padding: .5rem
}

body .ui-datatable.ui-datatable-sm .ui-datatable-scrollable-theadclone>tr>th {
    padding-bottom: 0;
    padding-top: 0
}

body .ui-datatable.ui-datatable-sm .ui-datatable-data>tr>td,
body .ui-datatable.ui-datatable-sm tfoot>tr>td {
    padding: .5rem
}

body .ui-datatable.ui-datatable-lg thead>tr>th {
    padding: 1.25rem
}

body .ui-datatable.ui-datatable-lg .ui-datatable-scrollable-theadclone>tr>th {
    padding-bottom: 0;
    padding-top: 0
}

body .ui-datatable.ui-datatable-lg .ui-datatable-data>tr>td,
body .ui-datatable.ui-datatable-lg tfoot>tr>td {
    padding: 1.25rem
}

body .ui-datatable.ui-datatable-gridlines .ui-datatable-data>tr>td,
body .ui-datatable.ui-datatable-gridlines .ui-datatable-header,
body .ui-datatable.ui-datatable-gridlines thead>tr>th {
    border-width: 1px
}

body .ui-datatable.ui-datatable-gridlines .ui-datatable-scrollable-theadclone>tr>th {
    border-bottom: 0;
    border-top: 0;
    padding-bottom: 0;
    padding-top: 0
}

body .ui-datatable.ui-datatable-gridlines tfoot>tr>td {
    border-width: 1px
}

body .ui-columntoggler,
body .ui-datatable.ui-datatable-sticky.ui-sticky {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-columntoggler {
    background: #fff;
    border: 1px solid #ced4da
}

body .ui-columntoggler .ui-columntoggler-close {
    display: none
}

body .ui-columntoggler .ui-columntoggler-items {
    padding: .5rem 0
}

body .ui-columntoggler .ui-columntoggler-items .ui-columntoggler-item {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem
}

body .ui-columntoggler .ui-columntoggler-items .ui-columntoggler-item .ui-chkbox {
    margin-right: .5rem;
    margin-top: 0;
    position: static;
    vertical-align: middle
}

body .ui-columntoggler .ui-columntoggler-items .ui-columntoggler-item label {
    display: inline;
    padding: 0
}

@media (max-width:640px) {
    body .ui-datatable-reflow .ui-datatable-data tr {
        border-bottom: 1px solid rgba(0, 0, 0, .08)
    }

    body .ui-datatable-reflow .ui-datatable-data tr.ui-state-highlight,
    body .ui-datatable-reflow .ui-datatable-data tr:hover {
        border-bottom: 1px solid rgba(0, 0, 0, .08);
        border-left: 0;
        border-right: 0
    }

    body .ui-datatable-reflow .ui-datatable-data tr.ui-expanded-row-content>td {
        display: block;
        width: 100%
    }
}

body .ui-dataview .ui-paginator.ui-paginator-bottom,
body .ui-dataview .ui-paginator.ui-paginator-top {
    border-radius: 0;
    border-width: 0 0 1px
}

body .ui-dataview .ui-dataview-header {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 1px 0;
    color: #495057;
    font-weight: 600;
    padding: 1rem
}

body .ui-dataview .ui-dataview-footer {
    background: #f8f9fa;
    border: solid #e9ecef;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-width: 0 0 1px;
    color: #495057;
    font-weight: 600;
    padding: 1rem
}

body .ui-dataview .ui-dataview-content {
    background: #fff;
    border: 0;
    color: #495057;
    padding: 0
}

body .ui-dataview.ui-dataview-list .ui-dataview-row {
    border: solid #e9ecef;
    border-width: 0 0 1px
}

body .fc .fc-scrollgrid {
    border-color: #dee2e6
}

body .fc .fc-daygrid-day.fc-day-today,
body .fc .fc-timegrid-col.fc-day-today {
    background-color: #f6a5c1
}

body .fc th {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057
}

body .fc th>.fc-scrollgrid-sync-inner {
    padding: 1rem
}

body .fc td {
    border: 1px solid #dee2e6
}

body .fc td.fc-widget-content {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057
}

body .fc td.fc-head-container {
    border: 1px solid #dee2e6
}

body .fc .fc-row {
    border-right: 1px solid #dee2e6
}

body .fc .fc-event,
body .fc .fc-event .fc-event-main {
    background: #d81557;
    border: 0 solid #d81557;
    color: #fff
}

body .fc .fc-divider {
    background: #f8f9fa;
    border: 1px solid #dee2e6
}

body .fc .fc-toolbar .fc-button {
    background: #e91e63;
    border: 1px solid #e91e63;
    color: #fff;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .fc .fc-toolbar .fc-button:not(:disabled):hover {
    background: #d81557;
    border-color: #d81557;
    color: #fff
}

body .fc .fc-toolbar .fc-button:focus {
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .fc .fc-toolbar .fc-button.fc-button-active,
body .fc .fc-toolbar .fc-button:active {
    background: #c0134e;
    border-color: #c0134e;
    color: #fff
}

body .ui-fluid .fc .fc-toolbar .ui-button {
    width: auto
}

body .ui-orderlist .ui-orderlist-controls {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    margin-right: 0
}

body .ui-orderlist .ui-orderlist-controls .ui-button {
    margin-bottom: .5rem
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-n:before {
    content: "\e933"
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-n:before {
    content: "\e92f"
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-s:before {
    content: "\e930"
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-s:before {
    content: "\e92c"
}

body .ui-orderlist .ui-orderlist-caption {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-orderlist .ui-g>div {
    padding: 0
}

body .ui-orderlist .ui-g>div.ui-orderlist-controls {
    padding: 0 .5rem 0 0
}

body .ui-orderlist .ui-orderlist-list {
    background: #fff;
    border: 1px solid #dee2e6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #495057;
    padding: .5rem 0
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
    border: 0;
    border-radius: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight.ui-sortable-placeholder {
    background: #c0134e
}

body .ui-orderlist.ui-orderlist-controls-right .ui-g>div.ui-orderlist-controls {
    padding: 0 0 0 .5rem
}

@media (max-width:640px) {
    body .ui-orderlist.ui-grid-responsive .ui-orderlist-controls {
        -ms-flex-direction: row;
        flex-direction: row
    }

    body .ui-orderlist.ui-grid-responsive .ui-orderlist-controls .ui-button {
        margin-right: .5rem;
        margin-top: .5rem
    }
}

body .ui-paginator {
    background: #fff;
    border: 0 solid #e9ecef;
    padding: 1rem
}

body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-last,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-prev {
    border: 1px solid transparent;
    border-radius: 4px;
    color: #6c757d;
    height: 2.286em;
    margin: 0 .125em;
    padding: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    vertical-align: top;
    width: 2.286em
}

body .ui-paginator .ui-paginator-first span,
body .ui-paginator .ui-paginator-last span,
body .ui-paginator .ui-paginator-next span,
body .ui-paginator .ui-paginator-prev span {
    display: none
}

body .ui-paginator .ui-paginator-first.ui-state-hover,
body .ui-paginator .ui-paginator-last.ui-state-hover,
body .ui-paginator .ui-paginator-next.ui-state-hover,
body .ui-paginator .ui-paginator-prev.ui-state-hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-paginator .ui-paginator-first.ui-state-focus,
body .ui-paginator .ui-paginator-last.ui-state-focus,
body .ui-paginator .ui-paginator-next.ui-state-focus,
body .ui-paginator .ui-paginator-prev.ui-state-focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-paginator .ui-paginator-first:before,
body .ui-paginator .ui-paginator-last:before,
body .ui-paginator .ui-paginator-next:before,
body .ui-paginator .ui-paginator-prev:before {
    line-height: inherit;
    position: relative;
    top: -1px
}

body .ui-paginator .ui-paginator-first {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-first:before {
    content: "\e92d";
    position: relative
}

body .ui-paginator .ui-paginator-prev {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-prev:before {
    content: "\e931";
    position: relative
}

body .ui-paginator .ui-paginator-next {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-next:before {
    content: "\e932";
    position: relative
}

body .ui-paginator .ui-paginator-last {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-last:before {
    content: "\e92e";
    position: relative
}

body .ui-paginator .ui-paginator-current {
    color: #6c757d
}

body .ui-paginator .ui-paginator-pages {
    display: inline-block;
    padding: 0
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page {
    border: 1px solid transparent;
    border-radius: 4px;
    color: #6c757d;
    height: 2.286em;
    line-height: 2.286em;
    margin: 0 .125em;
    min-width: 2.286em;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background: #e91e63;
    color: #fff
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-paginator .ui-paginator-current,
body .ui-paginator .ui-paginator-rpp-options {
    display: inline-block;
    margin: .5rem;
    vertical-align: middle
}

body .ui-picklist .ui-picklist-buttons {
    padding: 0 .5rem
}

body .ui-picklist .ui-picklist-buttons .ui-button {
    margin-bottom: .5rem
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-n:before {
    content: "\e933"
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-n:before {
    content: "\e92f"
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-s:before {
    content: "\e930"
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-s:before {
    content: "\e92c"
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-e:before {
    content: "\e932"
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-e:before {
    content: "\e92e"
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-w {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-w:before {
    content: "\e931"
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-w {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-w:before {
    content: "\e92d"
}

body .ui-picklist .ui-picklist-caption {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter {
    margin-bottom: .5rem;
    padding: .5rem .5rem .5rem 2rem;
    width: 100%
}

body .ui-picklist .ui-picklist-filter-container .ui-icon {
    background: none;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    left: .5rem;
    margin-top: -.75rem;
    position: absolute;
    right: auto;
    top: 50%
}

body .ui-picklist .ui-picklist-filter-container .ui-icon:before {
    content: "\e908"
}

body .ui-picklist .ui-picklist-list {
    background: #fff;
    border: 1px solid #dee2e6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #495057;
    padding: .5rem 0
}

body .ui-picklist .ui-picklist-list .ui-picklist-item {
    border: 0;
    border-radius: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight.ui-sortable-placeholder {
    background: #c0134e
}

@media (max-width:640px) {
    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button {
        margin-right: .5rem;
        margin-top: .5rem
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button:last-child {
        margin-right: 0
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-e {
        background: none;
        display: inline-block;
        font-family: primeicons !important;
        font-style: normal;
        font-weight: 400
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-e:before {
        content: "\e930"
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-e {
        background: none;
        display: inline-block;
        font-family: primeicons !important;
        font-style: normal;
        font-weight: 400
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-e:before {
        content: "\e92c"
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-w {
        background: none;
        display: inline-block;
        font-family: primeicons !important;
        font-style: normal;
        font-weight: 400
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-w:before {
        content: "\e933"
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-w {
        background: none;
        display: inline-block;
        font-family: primeicons !important;
        font-style: normal;
        font-weight: 400
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-w:before {
        content: "\e92f"
    }

    body .ui-picklist.ui-picklist-responsive>div.ui-helper-hidden-accessible {
        display: none
    }
}

body .ui-tagcloud {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-tagcloud a {
    border-radius: 4px;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-tagcloud a.ui-state-hover {
    background: #e91e63;
    color: #fff
}

body .timeline-frame {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057
}

body .timeline-frame .timeline-navigation {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .timeline-frame .timeline-navigation:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .timeline-frame .timeline-navigation:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomin {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomin:before {
    content: "\e98f"
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomout {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomout:before {
    content: "\e990"
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-w {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-w:before {
    content: "\e91f"
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-e:before {
    content: "\e920"
}

body .timeline-frame .timeline-event {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057
}

body .timeline-frame .timeline-event.ui-state-active {
    background: #e91e63;
    color: #fff
}

body .vis-timeline,
body .vis-timeline .vis-item {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057
}

body .vis-timeline .vis-item .vis-item-content {
    color: #495057
}

body .vis-timeline .vis-item.vis-dot {
    border-width: 4px
}

body .vis-timeline .vis-item.vis-selected {
    background: #e91e63;
    border-color: #e91e63
}

body .vis-timeline .vis-item.vis-selected .vis-item-content {
    color: #fff
}

body .vis-timeline .vis-time-axis .vis-text {
    color: #495057
}

body .vis-timeline .vis-panel .vis-shadow {
    box-shadow: none
}

body .ui-tree {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #495057;
    padding: 1rem;
    width: 100%
}

body .ui-tree .ui-tree-container {
    margin: 0;
    padding: 0
}

body .ui-tree .ui-tree-container .ui-treenode {
    padding: .143em 0
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    display: inline-block;
    float: none;
    height: 2rem;
    line-height: 2rem;
    margin: 0 .5rem 0 0;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-e:before {
    content: "\e901"
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-s:before {
    content: "\e902"
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-w {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-w:before {
    content: "\e900"
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-leaf-icon {
    height: 2rem;
    margin: 0 .5rem 0 0;
    width: 2rem
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
    margin: 0 .5rem 0 0
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    border-radius: 4px;
    margin: 0;
    padding: .286em
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
    margin-right: 1rem
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon-minus {
    background: none;
    color: #495057;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon-minus:before {
    content: "\e90f"
}

body .ui-tree .ui-tree-container .ui-tree-droppoint.ui-state-hover {
    background: #e91e63
}

body .ui-tree .ui-tree-container .ui-treenode-outline {
    box-shadow: inset 0 0 0 .15rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-tree.ui-tree-horizontal {
    padding-left: 0;
    padding-right: 0
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #495057;
    padding: 1rem
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler {
    margin-right: .5rem;
    vertical-align: middle
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-minus {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-minus:before {
    content: "\e90f"
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-plus {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-plus:before {
    content: "\e90d"
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-treenode-icon {
    margin-right: .5rem;
    vertical-align: middle
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-treenode-label {
    padding: 0;
    vertical-align: middle
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-chkbox {
    margin-right: .5rem
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-chkbox .ui-icon-minus {
    background: none;
    color: #495057;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-chkbox .ui-icon-minus:before {
    content: "\e90f"
}

body .ui-tree.ui-tree-horizontal .ui-treenode:before {
    background-color: #fff
}

body .ui-tree .ui-tree-filter-container {
    margin: 0 0 .5rem
}

body .ui-tree .ui-tree-filter-container .ui-icon {
    background: none;
    bottom: auto;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    left: .5rem;
    margin-top: -.5rem;
    position: absolute;
    right: auto;
    top: 50%
}

body .ui-tree .ui-tree-filter-container .ui-icon:before {
    content: "\e908"
}

body .ui-tree .ui-tree-filter-container .ui-inputfield {
    padding: .5rem .5rem .5rem 2rem;
    width: 100%
}

body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
    margin-left: .5rem;
    margin-right: 0
}

body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon,
body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-leaf-icon {
    margin: 0 0 0 .5rem
}

body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
    margin-left: 2.5rem;
    margin-right: 0
}

body .ui-tree-draghelper {
    border: 1px solid #e91e63
}

body .ui-fluid .ui-tree {
    width: 100%
}

body .ui-treetable .ui-treetable-header {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 1px 0;
    color: #495057;
    font-weight: 600;
    padding: 1rem
}

body .ui-treetable .ui-treetable-footer {
    background: #f8f9fa;
    border: solid #e9ecef;
    border-width: 0 0 1px;
    color: #495057;
    font-weight: 600;
    padding: 1rem
}

body .ui-treetable .ui-treetable-footer,
body .ui-treetable .ui-treetable-header {
    text-align: left
}

body .ui-treetable .ui-paginator {
    padding: 1rem
}

body .ui-treetable .ui-paginator.ui-paginator-bottom,
body .ui-treetable .ui-paginator.ui-paginator-top {
    border-radius: 0;
    border-width: 0 0 1px
}

body .ui-treetable thead th {
    background: #f8f9fa;
    border: solid #e9ecef;
    border-width: 0 0 1px;
    box-sizing: content-box;
    color: #495057;
    padding: 1rem;
    text-align: left;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-treetable thead th .ui-column-title {
    font-weight: 700;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon {
    margin: 0 0 0 .5rem
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s:before {
    content: "\e99e"
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n:before {
    content: "\e99f"
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s:before {
    content: "\e9a0"
}

body .ui-treetable thead th .ui-sortable-column-badge {
    background: #e91e63;
    border-radius: 50%;
    color: #fff;
    font-size: .75rem;
    height: 1.143rem;
    line-height: 1.143rem;
    margin-left: .5rem;
    min-width: 1.143rem
}

body .ui-treetable thead th.ui-sortable-column.ui-state-focus {
    box-shadow: inset 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-treetable thead th.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-treetable thead th.ui-state-active {
    background: #f8f9fa;
    color: #e91e63
}

body .ui-treetable thead th.ui-state-active.ui-state-hover {
    background: #e9ecef;
    color: #e91e63
}

body .ui-treetable .ui-treetable-data>tr {
    background: #fff;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-treetable .ui-treetable-data>tr>td {
    border: solid rgba(0, 0, 0, .08);
    border-width: 0 0 1px;
    padding: 1rem
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    margin-right: .5rem;
    margin-top: 0;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-e:before {
    content: "\e901"
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-s:before {
    content: "\e902"
}

body .ui-treetable .ui-treetable-data>tr>td .ui-chkbox.ui-selection {
    margin-right: 1rem;
    vertical-align: middle
}

body .ui-treetable .ui-treetable-data>tr>td .ui-chkbox.ui-selection .ui-icon-minus {
    background: none;
    color: #495057;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-treetable .ui-treetable-data>tr>td .ui-chkbox.ui-selection .ui-icon-minus:before {
    content: "\e90f"
}

body .ui-treetable .ui-treetable-data>tr.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr.ui-state-highlight {
    background: #e91e63;
    color: #fff;
    cursor: default
}

body .ui-treetable .ui-treetable-data>tr.ui-state-highlight .ui-treetable-toggler,
body .ui-treetable .ui-treetable-data>tr.ui-state-highlight .ui-treetable-toggler:hover {
    color: #fff
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil:before {
    content: "\e942"
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-check {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin-right: .5rem
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-check:before {
    content: "\e909"
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-close {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-close:before {
    content: "\e90b"
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-row-editor-check,
body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-row-editor-close {
    display: none
}

body .ui-treetable .ui-treetable-data>tr.ui-row-editing .ui-row-editor-check {
    display: inline-block
}

body .ui-treetable .ui-treetable-data>tr.ui-row-editing .ui-row-editor-close {
    color: #fff;
    display: inline-block
}

body .ui-treetable .ui-treetable-data>tr.ui-row-editing .ui-icon {
    color: #fff
}

body .ui-treetable tfoot td {
    background: #f8f9fa;
    border: solid #e9ecef;
    border-width: 0 0 1px;
    box-sizing: content-box;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-treetable .ui-treetable-scrollable-footer,
body .ui-treetable .ui-treetable-scrollable-header {
    background: #f8f9fa
}

body .ui-treetable .ui-column-resizer-helper {
    background: #e91e63
}

body .ui-treetable.ui-treetable-sm thead>tr>th {
    padding: .5rem
}

body .ui-treetable.ui-treetable-sm .ui-treetable-scrollable-theadclone>tr>th {
    padding-bottom: 0;
    padding-top: 0
}

body .ui-treetable.ui-treetable-sm .ui-treetable-data>tr>td,
body .ui-treetable.ui-treetable-sm tfoot>tr>td {
    padding: .5rem
}

body .ui-treetable.ui-treetable-lg thead>tr>th {
    padding: 1.25rem
}

body .ui-treetable.ui-treetable-lg .ui-treetable-scrollable-theadclone>tr>th {
    padding-bottom: 0;
    padding-top: 0
}

body .ui-treetable.ui-treetable-lg .ui-treetable-data>tr>td,
body .ui-treetable.ui-treetable-lg tfoot>tr>td {
    padding: 1.25rem
}

body .ui-treetable.ui-treetable-gridlines .ui-treetable-header {
    border-width: 1px 1px 0
}

body .ui-treetable.ui-treetable-gridlines thead>tr>th {
    border: 1px solid #e9ecef
}

body .ui-treetable.ui-treetable-gridlines .ui-treetable-data>tr>td {
    border-width: 1px
}

body .ui-treetable.ui-treetable-gridlines .ui-treetable-scrollable-theadclone>tr>th {
    border-bottom: 0;
    border-top: 0;
    padding-bottom: 0;
    padding-top: 0
}

body .ui-treetable.ui-treetable-gridlines tfoot>tr>td {
    border-width: 1px
}

body .ui-fileupload .ui-fileupload-buttonbar {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #495057;
    padding: 1rem
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
    margin-right: .5rem
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-choose .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-choose .ui-icon:before {
    content: "\e90d"
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-upload .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-upload .ui-icon:before {
    content: "\e934"
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-cancel .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-cancel .ui-icon:before {
    content: "\e90b"
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-state-disabled {
    display: none
}

body .ui-fileupload .ui-fileupload-content {
    background: #fff;
    border: 1px solid #dee2e6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0;
    color: #495057;
    padding: 1rem
}

body .ui-fileupload .ui-fileupload-content .ui-fileupload-cancel .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-fileupload .ui-fileupload-content .ui-fileupload-cancel .ui-icon:before {
    content: "\e90b"
}

body .ui-fileupload .ui-fileupload-content .ui-fileupload-files .ui-fileupload-row>div {
    padding: 1rem
}

body .ui-fileupload-simple .ui-icon-plusthick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-fileupload-simple .ui-icon-plusthick:before {
    content: "\e90d"
}

body .ui-autocomplete .ui-autocomplete-input.ui-autocomplete-dd-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: .5rem
}

body .ui-autocomplete .ui-autocomplete-dropdown {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: auto;
    position: relative;
    right: auto;
    width: 2.357rem
}

body .ui-autocomplete .ui-autocomplete-dropdown .ui-button-text {
    padding: .5rem
}

body .ui-autocomplete .ui-autocomplete-dropdown .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-autocomplete .ui-autocomplete-dropdown .ui-icon:before {
    content: "\e902"
}

body .ui-autocomplete .ui-autocomplete-multiple-container {
    padding: .25rem .5rem
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token {
    background: #dee2e6;
    border-radius: 4px;
    color: #495057;
    display: inline-block;
    float: none;
    margin: 0 .5rem 0 0;
    padding: 1px 0;
    vertical-align: middle
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token .ui-autocomplete-token-label {
    padding: 0 2rem 0 .5rem
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token .ui-autocomplete-token-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin-top: -.5rem;
    position: absolute
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token .ui-autocomplete-token-icon:before {
    content: "\e90b"
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
    display: inline-block;
    float: none;
    margin: 0;
    padding: .25rem 0;
    vertical-align: middle
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
    -webkit-appearance: none;
    appearance: none;
    color: #495057;
    font-family: cabin, sans-serif;
    font-size: 1rem;
    margin: 0;
    padding: 0
}

body .ui-autocomplete .ui-autocomplete-multiple-container.ui-autocomplete-dd-multiple-container {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

body .ui-autocomplete-panel {
    background: #fff;
    border: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    padding: 0
}

body .ui-autocomplete-panel .ui-autocomplete-empty-message,
body .ui-autocomplete-panel .ui-autocomplete-emptyMessage {
    border: 0;
    border-radius: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem
}

body .ui-autocomplete-panel .ui-autocomplete-items {
    border: 0;
    line-height: normal;
    padding: .5rem 0
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item {
    border: 0;
    border-radius: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item.ui-autocomplete-row {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .08);
    color: #495057
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item.ui-autocomplete-row>td {
    padding: 1rem
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-autocomplete-itemtip {
    padding: 0
}

body .ui-autocomplete-itemtip.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-fluid .ui-autocomplete .ui-autocomplete-input.ui-autocomplete-dd-input {
    width: calc(100% - 2.357rem)
}

body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
    width: 2.357rem
}

body .ui-fluid .ui-autocomplete-multiple-container.ui-autocomplete-dd-multiple-container {
    width: calc(100% - 2.357rem)
}

body .ui-cascadeselect {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-cascadeselect:not(.ui-state-disabled):hover {
    border-color: #e91e63
}

body .ui-cascadeselect:not(.ui-state-disabled).ui-state-focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-cascadeselect .ui-cascadeselect-label {
    background: transparent;
    border: 0;
    padding: .5rem
}

body .ui-cascadeselect .ui-cascadeselect-label.ui-placeholder {
    color: #6c757d
}

body .ui-cascadeselect .ui-cascadeselect-label:enabled:focus {
    box-shadow: none;
    outline: 0 none
}

body .ui-cascadeselect .ui-cascadeselect-trigger {
    background: transparent;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    color: #6c757d;
    width: 2.357rem
}

body .ui-cascadeselect-panel {
    background: #fff;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-cascadeselect-panel .ui-cascadeselect-items {
    padding: .5rem 0
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #495057;
    margin: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item .ui-cascadeselect-item-content {
    padding: .5rem 1rem
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item .ui-cascadeselect-item-content:focus {
    box-shadow: inset 0 0 0 .15rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
    background: #e9ecef;
    color: #495057
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item .ui-cascadeselect-group-icon {
    font-size: 1rem
}

body .ui-input-filled .ui-cascadeselect {
    background: #f8f9fa
}

body .ui-input-filled .ui-cascadeselect:not(.ui-state-disabled).p-focus,
body .ui-input-filled .ui-cascadeselect:not(.ui-state-disabled):hover {
    background-color: #f8f9fa
}

body .ui-chkbox {
    display: inline-block;
    height: 1.5rem;
    margin: 0;
    vertical-align: middle;
    width: 1.5rem
}

body .ui-chkbox .ui-chkbox-box {
    background: #fff;
    border: 2px solid #ced4da;
    border-radius: 4px;
    height: 1.5rem;
    position: relative;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 1.5rem
}

body .ui-chkbox .ui-chkbox-box.ui-state-hover {
    border-color: #e91e63
}

body .ui-chkbox .ui-chkbox-box.ui-state-focus {
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-chkbox .ui-chkbox-box.ui-state-active {
    background: #e91e63;
    border-color: #e91e63;
    color: #fff
}

body .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-hover {
    background: #d81557
}

body .ui-chkbox .ui-chkbox-box.ui-state-error {
    border-color: #f44336
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    left: 50%;
    margin-left: -.5rem;
    margin-top: -.5rem;
    overflow: visible;
    position: absolute;
    top: 50%
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-check {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-check:before {
    content: "\e909"
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-closethick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-closethick:before {
    content: "\e90b"
}

body .ui-input-filled .ui-chkbox .ui-chkbox-box,
body .ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-hover,
body.ui-input-filled .ui-chkbox .ui-chkbox-box,
body.ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-hover {
    background-color: #f8f9fa
}

body .ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active,
body.ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active {
    background: #e91e63
}

body .ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-hover,
body.ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-hover {
    background: #d81557
}

body .ui-state-highlight .ui-chkbox .ui-chkbox-box.ui-state-active {
    border-color: #fff
}

body .ui-chips .ui-chips-container {
    padding: .25rem .5rem
}

body .ui-chips .ui-chips-container .ui-chips-token {
    background: #dee2e6;
    border-radius: 4px;
    color: #495057;
    margin: .125rem;
    padding: 1px 0
}

body .ui-chips .ui-chips-container .ui-chips-token .ui-chips-token-label {
    padding: 0 2rem 0 .5rem
}

body .ui-chips .ui-chips-container .ui-chips-token .ui-chips-token-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin-top: -.5rem;
    position: absolute
}

body .ui-chips .ui-chips-container .ui-chips-token .ui-chips-token-icon:before {
    content: "\e90b"
}

body .ui-chips .ui-chips-container .ui-chips-input-token {
    margin: 0;
    padding: .25rem 0
}

body .ui-chips .ui-chips-container .ui-chips-input-token input {
    -webkit-appearance: none;
    appearance: none;
    color: #495057;
    font-family: cabin, sans-serif;
    font-size: 1rem;
    margin: 0;
    padding: 0
}

body .ui-colorpicker .ui-button {
    border-radius: 4px
}

body .ui-fluid .ui-colorpicker .ui-button {
    min-width: auto;
    width: auto
}

body .ui-datepicker {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
    padding: .5rem;
    width: auto
}

body .ui-datepicker .ui-datepicker-header {
    background: #fff;
    border: solid #dee2e6;
    border-radius: 0;
    border-width: 0 0 1px;
    color: #495057;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 1rem
}

body .ui-datepicker .ui-datepicker-next {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    right: 0
}

body .ui-datepicker .ui-datepicker-next:before {
    content: "\e901"
}

body .ui-datepicker .ui-datepicker-prev {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    left: 0
}

body .ui-datepicker .ui-datepicker-prev:before {
    content: "\e900"
}

body .ui-datepicker .ui-datepicker-next,
body .ui-datepicker .ui-datepicker-prev {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    cursor: pointer;
    height: 2rem;
    line-height: 2rem;
    margin-top: -1rem;
    padding: 0;
    text-align: center;
    top: 50%;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-datepicker .ui-datepicker-next:hover,
body .ui-datepicker .ui-datepicker-prev:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-datepicker .ui-datepicker-next:focus,
body .ui-datepicker .ui-datepicker-prev:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datepicker .ui-datepicker-next span,
body .ui-datepicker .ui-datepicker-prev span {
    display: none
}

body .ui-datepicker .ui-datepicker-title {
    line-height: 1;
    margin: 0
}

body .ui-datepicker .ui-datepicker-title select {
    margin: 0;
    width: 40%
}

body .ui-datepicker .ui-datepicker-title select:first-child {
    margin-right: .5rem
}

body .ui-datepicker .ui-datepicker-calendar {
    margin: .5rem 0
}

body .ui-datepicker .ui-datepicker-calendar th {
    padding: .5rem;
    text-align: center
}

body .ui-datepicker .ui-datepicker-calendar th>span {
    height: 2.5rem;
    width: 2.5rem
}

body .ui-datepicker .ui-datepicker-calendar td {
    padding: .5rem;
    text-align: center
}

body .ui-datepicker .ui-datepicker-calendar td a {
    border: 0;
    border-radius: 50%;
    color: #495057;
    display: inline-block;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 2.5rem
}

body .ui-datepicker .ui-datepicker-calendar td a:hover {
    background: #e9ecef;
    border-color: transparent
}

body .ui-datepicker .ui-datepicker-calendar td a:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datepicker .ui-datepicker-calendar td a.ui-state-active {
    background: #e91e63;
    border: 0;
    color: #fff
}

body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day a,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today a {
    background: transparent;
    border: 0;
    color: #c0134e;
    font-weight: 600
}

body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day a:hover,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today a:hover {
    background: #e9ecef;
    border-color: transparent
}

body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day a.ui-state-active,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today a.ui-state-active {
    background: #e91e63;
    border: 0;
    color: #fff
}

body .ui-datepicker.ui-input-overlay {
    border: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-datepicker .ui-timepicker-div dl {
    margin: .857em 0
}

body .ui-datepicker .ui-timepicker-div dl dt {
    padding: 0
}

body .ui-datepicker .ui-timepicker-div dl dt.ui_tpicker_time_label {
    padding: .5em 0
}

body .ui-datepicker .ui-timepicker-div dl dd {
    margin: 0 .714em .714em 40%;
    padding: .5em 0
}

body .ui-datepicker .ui-timepicker-timeinput input {
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
    font-size: 1rem;
    outline: 0 none;
    padding: .5rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: auto
}

body .ui-datepicker .ui-timepicker-timeinput input:hover {
    border-color: #e91e63
}

body .ui-datepicker .ui-timepicker-timeinput input:focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-datepicker .ui-timepicker-timeinput .ui-separator>span {
    font-size: 1rem;
    padding: .5rem
}

body .ui-datepicker .ui-datepicker-buttonbar button,
body .ui-datepicker .ui-datepicker-buttonpane button {
    background: #607d8b;
    border: 1px solid #607d8b;
    border-radius: 4px;
    color: #fff;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-state-hover,
body .ui-datepicker .ui-datepicker-buttonbar button:not(:disabled):hover,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-state-hover,
body .ui-datepicker .ui-datepicker-buttonpane button:not(:disabled):hover {
    background: #56717d;
    border-color: #56717d;
    color: #fff
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-state-focus,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #beccd2
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-state-active,
body .ui-datepicker .ui-datepicker-buttonbar button:active,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-state-active,
body .ui-datepicker .ui-datepicker-buttonpane button:active {
    background: #4d646f;
    border-color: #4d646f;
    color: #fff
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-outlined,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #607d8b
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-outlined:enabled:hover,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-outlined:enabled:hover {
    background: rgba(96, 125, 139, .04);
    border: 1px solid;
    color: #607d8b
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-outlined:enabled:active,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-outlined:enabled:active {
    background: rgba(96, 125, 139, .16);
    border: 1px solid;
    color: #607d8b
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-flat,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-flat {
    background-color: transparent;
    border-color: transparent;
    color: #607d8b
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-flat:enabled:hover,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-flat:enabled:hover {
    background: rgba(96, 125, 139, .04);
    border-color: transparent;
    color: #607d8b
}

body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-flat:enabled:active,
body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-flat:enabled:active {
    background: rgba(96, 125, 139, .16);
    border-color: transparent;
    color: #607d8b
}

body .ui-datepicker .ui-datepicker-buttonbar .ui-g-6:first-child,
body .ui-datepicker .ui-datepicker-buttonpane .ui-g-6:first-child {
    text-align: left
}

body .ui-datepicker .ui-datepicker-buttonbar .ui-g-6:last-child,
body .ui-datepicker .ui-datepicker-buttonpane .ui-g-6:last-child {
    text-align: right
}

body .ui-datepicker .ui-datepicker-buttonbar {
    border-top: solid #dee2e6;
    padding: 1rem 0
}

body .ui-datepicker .ui-monthpicker-month {
    border: 4px;
    color: #495057;
    margin: .5rem 0;
    padding: .5rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-datepicker .ui-monthpicker-month:hover {
    background: #e9ecef;
    border-color: transparent
}

body .ui-datepicker .ui-monthpicker-month:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datepicker .ui-monthpicker-month.ui-state-active {
    background: #e91e63;
    border: 0;
    color: #fff
}

body .ui-datepicker .ui-datepicker-group {
    padding: .5rem
}

body .ui-datepicker .ui-picker-up {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-datepicker .ui-picker-up:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-datepicker .ui-picker-up:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datepicker .ui-picker-up .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datepicker .ui-picker-up .ui-icon:before {
    content: "\e903"
}

body .ui-datepicker .ui-picker-down {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-datepicker .ui-picker-down:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-datepicker .ui-picker-down:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-datepicker .ui-picker-down .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-datepicker .ui-picker-down .ui-icon:before {
    content: "\e902"
}

body .ui-datepicker.ui-datepicker-multi {
    width: auto !important
}

body .ui-calendar .ui-button.ui-button-icon-only {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 2.357rem
}

body .ui-calendar .ui-button.ui-button-icon-only .ui-icon-calendar {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-calendar .ui-button.ui-button-icon-only .ui-icon-calendar:before {
    content: "\e927"
}

body .ui-calendar.ui-trigger-calendar input.hasDatepicker {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

body .ui-fluid .ui-trigger-calendar input.hasDatepicker {
    padding-right: 0;
    width: calc(100% - 2.357rem)
}

body .ui-fluid .ui-trigger-calendar .ui-datepicker-trigger.ui-button {
    position: absolute;
    right: -2.357rem;
    width: 2.357rem
}

body .ui-fluid .ui-trigger-calendar .ui-datepicker-trigger.ui-button .ui-button-text {
    padding: .5rem
}

body .ui-fluid .p-datepicker .ui-datepicker-trigger.ui-button {
    right: 0
}

body .ui-inplace .ui-inplace-display {
    border-radius: 4px;
    padding: .5rem
}

body .ui-inplace .ui-inplace-display.ui-state-highlight {
    background: #e9ecef;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-inplace .ui-inplace-display.ui-state-focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-inplace .ui-inplace-save .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-inplace .ui-inplace-save .ui-icon:before {
    content: "\e909"
}

body .ui-inplace .ui-inplace-cancel {
    margin-left: .25rem
}

body .ui-inplace .ui-inplace-cancel .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-inplace .ui-inplace-cancel .ui-icon:before {
    content: "\e90b"
}

body .ui-inplace .ui-inplace-editor {
    margin-left: .5rem
}

body .ui-inplace .ui-inplace-editor .ui-button-icon-only .ui-button-text {
    padding: .5rem
}

body ::-webkit-input-placeholder {
    color: #6c757d
}

body :-moz-placeholder,
body ::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

body :-ms-input-placeholder {
    color: #6c757d
}

body .p-field small.ui-state-error {
    color: #f44336
}

body .ui-float-label>label {
    color: #6c757d;
    margin-left: .5rem
}

body .ui-float-label>.ui-inputwrapper-filled~label,
body .ui-float-label>.ui-inputwrapper-focus~label,
body .ui-float-label>input.ui-state-filled~label,
body .ui-float-label>input:focus~label,
body .ui-float-label>textarea.ui-state-filled~label,
body .ui-float-label>textarea:focus~label {
    color: #495057
}

body .ui-float-label>input:-webkit-autofill~label,
body .ui-float-label>textarea:-webkit-autofill~label {
    color: #495057
}

body .ui-input-icon-left,
body .ui-input-icon-right {
    display: inline-block;
    position: relative
}

body .ui-fluid .ui-input-icon-left,
body .ui-fluid .ui-input-icon-right {
    display: block
}

body .ui-input-icon-left>i,
body .ui-input-icon-right>i {
    margin-top: -.5rem;
    position: absolute;
    top: 50%
}

body .ui-input-icon-left>i:first-of-type {
    color: #6c757d;
    left: .5rem
}

body .ui-input-icon-left>.ui-inputfield {
    padding-left: 2rem
}

body .ui-input-icon-right>i:last-of-type {
    color: #6c757d;
    right: .5rem
}

body .ui-input-icon-right>.ui-inputfield {
    padding-right: 2rem
}

body .ui-inputfield {
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
    font-size: 1rem;
    outline: 0 none;
    padding: .5rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-inputfield.ui-state-hover {
    border-color: #e91e63
}

body .ui-inputfield.ui-state-focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-inputfield.ui-state-error {
    border-color: #f44336
}

body .ui-input-filled .ui-inputfield,
body .ui-input-filled .ui-inputfield.ui-state-focus,
body .ui-input-filled .ui-inputfield.ui-state-hover,
body.ui-input-filled .ui-inputfield,
body.ui-input-filled .ui-inputfield.ui-state-focus,
body.ui-input-filled .ui-inputfield.ui-state-hover {
    background-color: #f8f9fa
}

body .ui-inputtextarea {
    vertical-align: top
}

body .ui-inputgroup .ui-inputgroup-addon,
body .ui-inputgroup .ui-inputgroup-addon-checkbox {
    background: #e9ecef;
    border-color: #ced4da;
    color: #6c757d;
    min-width: 2.357rem;
    padding: .5rem
}

body .ui-inputgroup .ui-inputgroup-addon-checkbox:first-child,
body .ui-inputgroup .ui-inputgroup-addon:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

body .ui-inputgroup .ui-inputgroup-addon-checkbox:last-child,
body .ui-inputgroup .ui-inputgroup-addon:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-inputgroup button:first-child,
body .ui-inputgroup input:first-child,
body .ui-inputgroup textarea:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

body .ui-inputgroup .ui-float-label:first-child input,
body .ui-inputgroup .ui-float-label:first-child textarea {
    border-bottom-left-radius: 4px;
    border-right-width: 0;
    border-top-left-radius: 4px
}

body .ui-inputgroup button:last-child,
body .ui-inputgroup input:last-child,
body .ui-inputgroup textarea:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-inputgroup .ui-float-label:last-child input,
body .ui-inputgroup .ui-float-label:last-child textarea {
    border-bottom-right-radius: 4px;
    border-left-width: 0;
    border-top-right-radius: 4px
}

body .ui-inputgroup>.ui-float-label>.ui-widget,
body .ui-inputgroup>.ui-widget {
    border-radius: 0
}

body .ui-inputgroup>.ui-float-label>.ui-widget.ui-state-focus,
body .ui-inputgroup>.ui-float-label>.ui-widget.ui-state-focus~label,
body .ui-inputgroup>.ui-widget.ui-state-focus,
body .ui-inputgroup>.ui-widget.ui-state-focus~label {
    z-index: 1
}

body .ui-inputgroup .ui-inputgroup-addon-checkbox {
    padding: 0;
    position: relative
}

body .ui-inputgroup .ui-inputgroup-addon-checkbox .ui-chkbox {
    left: 50%;
    margin-left: -.75rem;
    margin-top: -.75rem;
    position: absolute;
    top: 50%;
    vertical-align: baseline
}

body .ui-inputswitch {
    background: #dee2e6;
    border-radius: 8px;
    height: 14px;
    overflow: visible;
    width: 34px !important
}

body .ui-inputswitch .ui-inputswitch-handle {
    background: #fff;
    border: 2px solid #dee2e6;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    height: 20px !important;
    left: -2px;
    top: -4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 20px !important
}

body .ui-inputswitch .ui-inputswitch-handle.ui-state-focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-inputswitch .ui-inputswitch-off span,
body .ui-inputswitch .ui-inputswitch-on,
body .ui-inputswitch .ui-inputswitch-on span {
    visibility: hidden
}

body .ui-inputswitch.ui-inputswitch-checked {
    background: #e91e63
}

body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-handle {
    background: #fff;
    border-color: #c0134e;
    margin-left: 2px
}

body .keypad-popup {
    background: #fff;
    border: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    color: #495057;
    margin: 0;
    padding: 0;
    width: auto
}

body .keypad-popup button {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
    margin: 2px;
    outline: 0 none;
    padding: .5rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .keypad-popup button.ui-state-hover {
    background: #ebebef;
    border-color: #ced4da;
    color: #495057
}

body .keypad-popup button.ui-state-active {
    background: #c0134e;
    border-color: #c0134e;
    color: #fff
}

body .keypad-popup button.keypad-back,
body .keypad-popup button.keypad-clear,
body .keypad-popup button.keypad-close,
body .keypad-popup button.keypad-enter,
body .keypad-popup button.keypad-shift,
body .keypad-popup button.keypad-spacebar {
    background: #e91e63;
    border: 1px solid #e91e63;
    color: #fff
}

body .keypad-popup button.keypad-back.ui-state-hover,
body .keypad-popup button.keypad-clear.ui-state-hover,
body .keypad-popup button.keypad-close.ui-state-hover,
body .keypad-popup button.keypad-enter.ui-state-hover,
body .keypad-popup button.keypad-shift.ui-state-hover,
body .keypad-popup button.keypad-spacebar.ui-state-hover {
    background: #d81557;
    border-color: #d81557;
    color: #fff
}

body .keypad-popup button.keypad-back.ui-state-focus,
body .keypad-popup button.keypad-clear.ui-state-focus,
body .keypad-popup button.keypad-close.ui-state-focus,
body .keypad-popup button.keypad-enter.ui-state-focus,
body .keypad-popup button.keypad-shift.ui-state-focus,
body .keypad-popup button.keypad-spacebar.ui-state-focus {
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .keypad-popup button.keypad-back.ui-state-active,
body .keypad-popup button.keypad-clear.ui-state-active,
body .keypad-popup button.keypad-close.ui-state-active,
body .keypad-popup button.keypad-enter.ui-state-active,
body .keypad-popup button.keypad-shift.ui-state-active,
body .keypad-popup button.keypad-spacebar.ui-state-active {
    background: #c0134e;
    border-color: #c0134e;
    color: #fff
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer {
    border: 1px solid #ced4da;
    margin-right: .5rem;
    padding: 0
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-header {
    background: #f8f9fa;
    border: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list {
    background: #fff;
    border: 0;
    padding: .5rem 0
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list .ui-multiselectlistbox-item {
    background: transparent;
    border-radius: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list .ui-multiselectlistbox-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list .ui-multiselectlistbox-item.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-password .ui-password-icon {
    cursor: pointer
}

body .ui-password.ui-password-masked .ui-password-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-password.ui-password-masked .ui-password-icon:before {
    content: "\e966"
}

body .ui-password.ui-password-unmasked .ui-password-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-password.ui-password-unmasked .ui-password-icon:before {
    content: "\e965"
}

body .ui-password-panel {
    background: #fff;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    color: #495057;
    margin: 0;
    min-width: 150px;
    padding: 1rem;
    width: auto
}

body .ui-radiobutton {
    display: inline-block;
    height: 20px;
    margin: 0;
    vertical-align: middle;
    width: 20px
}

body .ui-radiobutton .ui-radiobutton-box {
    background: #fff;
    border: 2px solid #ced4da;
    border-radius: 50%;
    height: 20px;
    position: relative;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 20px
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-hover {
    border-color: #e91e63
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
    box-shadow: 0 0 0 .2em #f6a5c1
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
    background: #e91e63;
    border-color: #e91e63;
    color: #fff
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-hover {
    background: #d81557
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-icon-bullet {
    background: #fff
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-error {
    border-color: #f44336
}

body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
    border-radius: 50%;
    height: 12px;
    left: 50%;
    margin-left: -6px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    vertical-align: middle;
    width: 12px
}

body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon:before {
    display: none
}

body .ui-input-filled .ui-radiobutton .ui-radiobutton-box,
body .ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-hover,
body.ui-input-filled .ui-radiobutton .ui-radiobutton-box,
body.ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-hover {
    background-color: #f8f9fa
}

body .ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active,
body.ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active {
    background: #e91e63
}

body .ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-hover,
body.ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-hover {
    background: #d81557
}

body .ui-state-highlight .ui-radiobutton .ui-radiobutton-box.ui-state-active {
    border-color: #fff
}

body .ui-rating .ui-rating-cancel {
    display: inline-block;
    float: none;
    margin-right: .5rem;
    overflow: visible
}

body .ui-rating .ui-rating-cancel.ui-state-focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-rating .ui-rating-cancel a {
    background: none;
    color: #e0284f;
    display: inline-block;
    display: block;
    font-family: primeicons !important;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    height: 1.25rem;
    overflow: visible;
    text-indent: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 1.25rem
}

body .ui-rating .ui-rating-cancel a:before {
    content: "\e90c"
}

body .ui-rating .ui-rating-cancel a:hover {
    color: #e0284f
}

body .ui-rating .ui-rating-star {
    display: inline-block;
    float: none;
    margin-right: .5rem;
    overflow: visible
}

body .ui-rating .ui-rating-star.ui-state-focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-rating .ui-rating-star a {
    background: none;
    color: #495057;
    display: inline-block;
    display: block;
    font-family: primeicons !important;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    height: 1.25rem;
    overflow: visible;
    text-indent: 0;
    width: 1.25rem
}

body .ui-rating .ui-rating-star a:before {
    content: "\e937"
}

body .ui-rating .ui-rating-star a:hover {
    color: #d81557
}

body .ui-rating .ui-rating-star-on a {
    background: none;
    color: #e91e63;
    display: inline-block;
    display: block;
    font-family: primeicons !important;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    height: 1.25rem;
    width: 1.25rem
}

body .ui-rating .ui-rating-star-on a:before {
    content: "\e936"
}

body .ui-state-highlight .ui-rating-star a {
    color: #fff
}

body .ui-selectbooleancheckbox,
body .ui-tristatecheckbox {
    -ms-flex-align: center;
    align-items: center;
    display: inline-flex;
    height: auto;
    width: auto
}

body .ui-selectcheckboxmenu {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    position: relative;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    vertical-align: baseline
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-label-container {
    background: transparent;
    border-radius: 4px;
    display: block;
    padding: .5rem 2.357rem .5rem .5rem
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-label-container .ui-selectcheckboxmenu-label {
    background: transparent;
    padding: 0
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-trigger {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background: transparent;
    border: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0;
    position: absolute;
    width: 2.357rem
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-trigger .ui-icon {
    background: none;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    position: static
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-trigger .ui-icon:before {
    content: "\e902"
}

body .ui-selectcheckboxmenu.ui-state-hover {
    border-color: #e91e63
}

body .ui-selectcheckboxmenu.ui-state-focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-selectcheckboxmenu.ui-state-error {
    border-color: #f44336
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container.ui-inputfield {
    padding: .5rem 2.357rem .5rem .5rem
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token {
    background: #dee2e6;
    border-radius: 4px;
    color: #495057;
    margin: 0 .5rem 0 0;
    padding: 1px 0
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token .ui-selectcheckboxmenu-token-label {
    padding: 0 2rem 0 .5rem
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token .ui-selectcheckboxmenu-token-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin-top: -.5rem;
    position: absolute
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token .ui-selectcheckboxmenu-token-icon:before {
    content: "\e90b"
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-input-token {
    margin: 0;
    padding: .25rem 0
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-input-token input {
    font-family: cabin, sans-serif;
    margin: 0;
    padding: 0
}

body .ui-input-filled .ui-selectcheckboxmenu,
body.ui-input-filled .ui-selectcheckboxmenu {
    background: #f8f9fa
}

body .ui-input-filled .ui-selectcheckboxmenu.ui-state-focus,
body .ui-input-filled .ui-selectcheckboxmenu.ui-state-hover,
body.ui-input-filled .ui-selectcheckboxmenu.ui-state-focus,
body.ui-input-filled .ui-selectcheckboxmenu.ui-state-hover {
    background-color: #f8f9fa
}

body .ui-selectcheckboxmenu-panel {
    background: #fff;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    padding: 0
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header {
    -ms-flex-align: center;
    align-items: center;
    background: #f8f9fa;
    border-bottom: 0;
    color: #495057;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: .5rem 1rem;
    position: relative
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-chkbox {
    float: none;
    margin-right: .5rem
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container {
    flex: 1 1 0;
    -ms-flex: 1 1 0px;
    float: none;
    margin: 0 .5rem 0 0;
    padding: 0
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container .ui-inputfield {
    padding: .5rem .5rem .5rem 2rem;
    width: 100%
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container .ui-icon {
    background: none;
    bottom: auto;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    left: .5rem;
    margin-top: -.5rem;
    position: absolute;
    right: auto;
    top: 50%
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container .ui-icon:before {
    content: "\e908"
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    margin-left: auto;
    margin-right: 0;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close .ui-icon {
    background: none;
    display: inline-block;
    float: none;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close .ui-icon:before {
    content: "\e90b"
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items {
    padding: .5rem 0
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item {
    background: transparent;
    border-radius: 0;
    color: #495057;
    margin: 0;
    outline: 0 none;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item .ui-chkbox {
    margin-right: .5rem;
    margin-top: 0;
    position: static;
    top: auto
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item>label {
    display: inline;
    padding-left: 0;
    vertical-align: middle
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item-group {
    background: #fff;
    color: #495057;
    margin: 0;
    padding: .75rem 1rem
}

body .ui-selectmanymenu,
body .ui-selectonelistbox {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    min-width: 12rem;
    padding: 0;
    width: auto
}

body .ui-selectmanymenu .ui-selectlistbox-filter-container,
body .ui-selectonelistbox .ui-selectlistbox-filter-container {
    background: #f8f9fa;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem
}

body .ui-selectmanymenu .ui-selectlistbox-filter-container .ui-selectlistbox-filter.ui-inputfield,
body .ui-selectonelistbox .ui-selectlistbox-filter-container .ui-selectlistbox-filter.ui-inputfield {
    padding: .5rem .5rem .5rem 2rem;
    width: 100%
}

body .ui-selectmanymenu .ui-selectlistbox-filter-container .ui-icon,
body .ui-selectonelistbox .ui-selectlistbox-filter-container .ui-icon {
    background: none;
    bottom: auto;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    left: 1.5rem;
    margin-top: -.5rem;
    position: absolute;
    right: auto;
    top: 50%
}

body .ui-selectmanymenu .ui-selectlistbox-filter-container .ui-icon:before,
body .ui-selectonelistbox .ui-selectlistbox-filter-container .ui-icon:before {
    content: "\e908"
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer {
    border: 0;
    line-height: normal;
    padding: .5rem 0
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list {
    list-style-type: none;
    margin: 0
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-hover,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-highlight,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item .ui-chkbox,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item .ui-chkbox {
    margin-right: .5rem;
    vertical-align: middle
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item>td,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item>td {
    padding: 1rem
}

body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-listbox-outline,
body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-listbox-outline {
    box-shadow: inset 0 0 0 .15rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-input-filled .ui-selectmanymenu.ui-inputfield,
body .ui-input-filled .ui-selectonelistbox.ui-inputfield,
body.ui-input-filled .ui-selectmanymenu.ui-inputfield,
body.ui-input-filled .ui-selectonelistbox.ui-inputfield {
    background: #fff
}

body .ui-selectonemenu {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0;
    position: relative;
    transform: none;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    vertical-align: middle
}

body .ui-selectonemenu .ui-selectonemenu-label {
    background: transparent;
    min-height: calc(2rem + 3px);
    padding-right: 2.357rem
}

body .ui-selectonemenu .ui-selectonemenu-trigger {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background: transparent;
    border: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0;
    position: absolute;
    width: 2.357rem
}

body .ui-selectonemenu .ui-selectonemenu-trigger .ui-icon {
    background: none;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    position: static
}

body .ui-selectonemenu .ui-selectonemenu-trigger .ui-icon:before {
    content: "\e902"
}

body .ui-selectonemenu.ui-state-hover {
    border-color: #e91e63
}

body .ui-selectonemenu.ui-state-focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2em #f6a5c1;
    outline: 0 none
}

body .ui-selectonemenu.ui-state-error {
    border-color: #f44336
}

body .ui-fluid .ui-selectonemenu-label {
    padding: .5rem;
    width: calc(100% - 2.357rem)
}

body .ui-fluid .ui-selectonemenu .ui-selectonemenu-trigger {
    padding: 0;
    width: 2.357rem
}

body .ui-input-filled .ui-selectonemenu,
body.ui-input-filled .ui-selectonemenu {
    background: #f8f9fa
}

body .ui-input-filled .ui-selectonemenu.ui-state-focus,
body .ui-input-filled .ui-selectonemenu.ui-state-hover,
body.ui-input-filled .ui-selectonemenu.ui-state-focus,
body.ui-input-filled .ui-selectonemenu.ui-state-hover {
    background-color: #f8f9fa
}

body .ui-selectonemenu-panel {
    background: #fff;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container {
    background: #f8f9fa;
    border-bottom: 0;
    color: #495057;
    margin: 0;
    padding: .5rem 1rem
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container .ui-selectonemenu-filter.ui-inputfield {
    padding: .5rem .5rem .5rem 2rem;
    width: 100%
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container .ui-icon {
    background: none;
    bottom: auto;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    left: 1.5rem;
    margin-top: -.5rem;
    position: absolute;
    right: auto;
    top: 50%
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container .ui-icon:before {
    content: "\e908"
}

body .ui-selectonemenu-panel .ui-selectonemenu-items {
    padding: .5rem 0
}

body .ui-selectonemenu-panel .ui-selectonemenu-items.ui-selectonemenu-table>thead tr th {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    color: #495057;
    font-weight: 700
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item {
    background: transparent;
    border-radius: 0;
    color: #495057;
    margin: 0;
    outline: 0 none;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-selectonemenu-row {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .08);
    color: #495057
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-selectonemenu-row>td {
    padding: 1rem
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-selectonemenu-row:first-child {
    border-top-color: transparent
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-state-highlight {
    background: #e91e63;
    color: #fff;
    overflow: auto
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item-group {
    background: #fff;
    color: #495057;
    margin: 0;
    padding: .75rem 1rem
}

body .ui-slider {
    background: #dee2e6
}

body .ui-slider .ui-slider-handle {
    background: #fff;
    border: 2px solid #e91e63;
    border-radius: 50%;
    height: 1.143rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 1.143rem
}

body .ui-slider .ui-slider-handle.ui-state-hover {
    background: #e91e63;
    border-color: #e91e63
}

body .ui-slider .ui-slider-handle.ui-state-focus {
    outline: 0 none
}

body .ui-slider.ui-slider-horizontal {
    height: .286rem
}

body .ui-slider.ui-slider-horizontal .ui-slider-handle {
    margin-left: -.5715rem;
    top: -.4285rem
}

body .ui-slider.ui-slider-vertical {
    width: .286rem
}

body .ui-slider.ui-slider-vertical .ui-slider-handle {
    left: -.4285rem;
    margin-top: -.5715rem
}

body .ui-slider .ui-slider-range {
    background: #e91e63
}

body .ui-inputtext+.ui-slider {
    margin-bottom: .5rem
}

body .ui-spinner .ui-spinner-input {
    padding-right: 2.857rem
}

body .ui-spinner .ui-spinner-button.ui-button {
    border-radius: 0;
    position: absolute;
    width: 2.357rem;
    z-index: 1
}

body .ui-spinner .ui-spinner-button.ui-button .ui-icon {
    left: 50%;
    margin-left: -.5rem;
    margin-top: -.5rem;
    position: absolute;
    top: 50%
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-up {
    border-top-right-radius: 4px
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-up .ui-icon-triangle-1-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-up .ui-icon-triangle-1-n:before {
    content: "\e933"
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-down {
    border-bottom-right-radius: 4px
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-down .ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-down .ui-icon-triangle-1-s:before {
    content: "\e930"
}

body .ui-fluid .ui-spinner .ui-spinner-input {
    padding-right: 2.857rem
}

body .ui-toggleswitch {
    display: inline-block;
    height: 1.75rem;
    position: relative;
    width: 3rem
}

body .ui-toggleswitch .ui-toggleswitch-slider {
    background: #dee2e6;
    border-radius: 30px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-toggleswitch .ui-toggleswitch-slider:before {
    background: #fff;
    border-radius: 50%;
    content: "";
    height: 1.25rem;
    left: .25rem;
    margin-top: -.625rem;
    position: absolute;
    top: 50%;
    transition: transform .2s;
    width: 1.25rem
}

body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-slider:before {
    transform: translateX(1.25rem)
}

body .ui-toggleswitch.ui-toggleswitch-focus .ui-toggleswitch-slider {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-slider {
    background: #e91e63
}

body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-slider:before {
    background: #fff
}

body .ui-breadcrumb {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 1rem
}

body .ui-breadcrumb ul li {
    display: inline-block;
    float: none;
    vertical-align: middle
}

body .ui-breadcrumb ul li .ui-menuitem-link {
    border-radius: 4px;
    margin: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-breadcrumb ul li .ui-menuitem-link:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-breadcrumb ul li .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb ul li .ui-menuitem-link .ui-menuitem-icon {
    color: #6c757d
}

body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
    background: none;
    color: #495057;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0 .5rem
}

body .ui-breadcrumb ul li.ui-breadcrumb-chevron:before {
    content: "\e901"
}

body .ui-breadcrumb ul li:not(.ui-breadcrumb-chevron) {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex
}

body .ui-breadcrumb ul li:not(.ui-breadcrumb-chevron):before {
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: none !important;
    color: #495057;
    content: "\e901";
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    margin: 0 .5rem;
    text-indent: 0 !important;
    text-transform: none
}

body .ui-breadcrumb ul li:first-child:not(.ui-breadcrumb-chevron):before {
    display: none
}

body .ui-breadcrumb ul li:first-child a.ui-icon-home {
    background: none;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0
}

body .ui-breadcrumb ul li:first-child a.ui-icon-home:before {
    content: "\e925"
}

body .ui-breadcrumb ul li:first-child a.ui-icon-home span {
    display: none
}

body .ui-breadcrumb ul li:last-child .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb ul li:last-child .ui-menuitem-link .ui-menuitem-icon {
    color: #6c757d
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link {
    border-radius: 4px;
    margin: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link .ui-menuitem-icon {
    color: #6c757d
}

body .ui-breadcrumb .ui-breadcrumb-items li.ui-breadcrumb-chevron {
    background: none;
    color: #495057;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0 .5rem
}

body .ui-breadcrumb .ui-breadcrumb-items li.ui-breadcrumb-chevron:before {
    content: "\e901"
}

body .ui-breadcrumb .ui-breadcrumb-items li:not(.ui-breadcrumb-chevron) {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex
}

body .ui-breadcrumb .ui-breadcrumb-items li:not(.ui-breadcrumb-chevron):before {
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: none !important;
    color: #495057;
    content: "\e901";
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    margin: 0 .5rem;
    text-indent: 0 !important;
    text-transform: none
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child:not(.ui-breadcrumb-chevron):before {
    display: none
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child a.ui-icon-home {
    background: none;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child a.ui-icon-home:before {
    content: "\e925"
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child a.ui-icon-home span {
    display: none
}

body .ui-breadcrumb .ui-breadcrumb-items li:last-child .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb .ui-breadcrumb-items li:last-child .ui-menuitem-link .ui-menuitem-icon {
    color: #6c757d
}

body .ui-menu {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: .25rem 0
}

body .ui-menu.ui-shadow {
    border: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon {
    margin-right: .5rem;
    position: static
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-e:before {
    content: "\e901"
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-s:before {
    content: "\e902"
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header h3 {
    font-size: 1rem;
    padding-left: 0
}

body .ui-menu .ui-menu-list .ui-widget-header {
    background: #fff;
    border: 0;
    border-radius: 0;
    color: #495057;
    float: none;
    margin: 0;
    padding: .75rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 100%
}

body .ui-menu .ui-menu-list .ui-widget-header h3 {
    color: #495057;
    float: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 0
}

body .ui-menu .ui-menu-list .ui-widget-header.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-menu .ui-menu-list .ui-widget-header.ui-state-hover .ui-icon {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem {
    margin: 0
}

body .ui-menu .ui-menu-list .ui-menuitem,
body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link {
    border: 0;
    border-radius: 0;
    float: none;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 100%
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link {
    padding: .75rem 1rem
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link.ui-state-hover {
    background: #e9ecef
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link.ui-state-hover .ui-icon,
body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link.ui-state-hover .ui-menuitem-text {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
    color: #6c757d;
    float: none;
    margin-right: .5rem;
    vertical-align: middle
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-menuitem-text {
    color: #495057;
    float: none;
    vertical-align: middle
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-e:last-child {
    background: none;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin-right: -.5rem;
    position: relative
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-e:last-child:before {
    content: "\e932"
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-s:last-child {
    background: none;
    color: #6c757d;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin-right: -.5rem
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-s:last-child:before {
    content: "\e930"
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-state-hover>.ui-menuitem-link,
body .ui-menu .ui-menu-list .ui-menuitem.ui-state-hover>.ui-menuitem-link .ui-icon {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link {
    background: #e9ecef
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link .ui-icon,
body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link .ui-menuitem-icon,
body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menu-child {
    background: #fff;
    padding: .25rem 0
}

body .ui-menu .ui-menu-list .ui-menu-child.ui-shadow {
    border: 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-menu .ui-menu-list .ui-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0;
    width: 100%
}

body .ui-menu.ui-menubar {
    background: #f8f9fa;
    border-radius: 4px;
    padding: .5rem
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem {
    display: inline-block;
    width: auto
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link {
    border-radius: 4px
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link .ui-menuitem-icon {
    position: static;
    vertical-align: middle
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link>span {
    display: inline-block;
    float: none
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link .ui-icon-triangle-1-s:last-child {
    margin-left: .5rem;
    vertical-align: middle
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem.ui-menubar-options {
    float: right;
    margin-right: .5rem
}

body .ui-menu.ui-slidemenu .ui-slidemenu-backward {
    background: #fff;
    border: 0;
    border-radius: 0;
    color: #495057;
    margin-bottom: 0;
    padding: .75rem 1rem;
    width: 100%
}

body .ui-menu.ui-slidemenu .ui-slidemenu-backward .ui-icon-triangle-1-w {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin-right: .5rem;
    position: relative;
    top: 2px
}

body .ui-menu.ui-slidemenu .ui-slidemenu-backward .ui-icon-triangle-1-w:before {
    content: "\e931"
}

body .ui-menu.ui-slidemenu .ui-menu-child {
    padding: 0
}

body .ui-menu.ui-slidemenu .ui-menu-child.ui-shadow {
    border: 0;
    box-shadow: none
}

body .ui-menu.ui-megamenu .ui-widget-header {
    background: #fff;
    border: 0;
    border-radius: 0;
    color: #495057;
    float: none;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    padding: .75rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 100%
}

body .ui-menu.ui-megamenu .ui-widget-header span {
    float: none;
    padding: 0
}

body .ui-menu.ui-megamenu .ui-menu-list>table>tbody>tr>td {
    padding: 0
}

body .ui-menu.ui-megamenu .ui-menu-list .ui-menuitem-link {
    border-radius: 4px
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical {
    background: #f8f9fa;
    padding: .25rem 0
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical>.ui-menu-list .ui-menuitem {
    width: 100%
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical>.ui-menu-list .ui-menuitem>.ui-menuitem-link {
    border-radius: 0
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical>.ui-menu-list>.ui-menuitem>.ui-menuitem-link .ui-icon:last-child {
    float: right
}

body .ui-menu .ui-divider.ui-widget.ui-divider-horizontal {
    margin: 0
}

body .ui-panelmenu .ui-icon {
    position: static
}

body .ui-panelmenu .ui-panelmenu-panel:first-child .ui-panelmenu-header {
    border-top: 1px solid #dee2e6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 0
}

body .ui-panelmenu .ui-panelmenu-panel:last-child .ui-panelmenu-header {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-panelmenu .ui-panelmenu-panel:last-child .ui-panelmenu-header.ui-state-active {
    border-radius: 0
}

body .ui-panelmenu h3.ui-panelmenu-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0;
    border-top: 0;
    color: #495057;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    outline: 0 none;
    padding: 1rem;
    position: relative;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon {
    margin: 0 .5rem 0 0;
    position: static;
    vertical-align: middle
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-e:before {
    content: "\e901"
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-s:before {
    content: "\e902"
}

body .ui-panelmenu h3.ui-panelmenu-header a {
    color: inherit;
    display: inline;
    outline: 0 none;
    padding: 0;
    text-decoration: none;
    vertical-align: middle
}

body .ui-panelmenu h3.ui-panelmenu-header.ui-state-hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057
}

body .ui-panelmenu h3.ui-panelmenu-header.ui-state-active {
    background: #f8f9fa;
    border-color: #dee2e6;
    color: #495057
}

body .ui-panelmenu h3.ui-panelmenu-header.ui-state-active:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057
}

body .ui-panelmenu .ui-panelmenu-content {
    background: #fff;
    border: 1px solid #dee2e6;
    border-top: 0;
    margin-top: 0;
    padding: .25rem 0;
    position: static
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link {
    color: #495057;
    padding: .75rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover {
    background: #e9ecef
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover .ui-icon,
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover .ui-menuitem-text,
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover .ui-panelmenu-icon {
    color: #495057
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon {
    color: #6c757d;
    display: inline-block;
    margin-right: .5rem;
    position: static;
    vertical-align: middle
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-e:before {
    content: "\e932"
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-s:before {
    content: "\e930"
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link .ui-menuitem-text {
    display: inline-block;
    vertical-align: middle
}

body .ui-steps {
    position: relative
}

body .ui-steps ul {
    display: -ms-flexbox;
    display: flex
}

body .ui-steps .ui-steps-item {
    background: transparent;
    flex: 1 1 0;
    -ms-flex: 1 1 0px;
    float: none;
    opacity: 1;
    text-align: center
}

body .ui-steps .ui-steps-item .ui-menuitem-link {
    background: transparent;
    display: block;
    overflow: hidden;
    padding: 0;
    text-align: center
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background: #fff;
    border: 1px solid #e9ecef;
    border-radius: 50%;
    color: #495057;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.143rem;
    height: 2rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    padding: 0;
    width: 2rem
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
    color: #6c757d;
    display: block;
    padding-top: .5rem
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
    background: #e91e63;
    border-color: #e91e63;
    color: #fff
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
    color: #495057;
    font-weight: 600
}

body .ui-steps .ui-steps-item:not(.ui-state-highlight) {
    opacity: .65
}

body .ui-steps:before {
    border-top: 1px solid #dee2e6;
    content: " ";
    display: block;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%
}

body .ui-tabmenu {
    padding: 0;
    position: static
}

body .ui-tabmenu .ui-tabmenu-nav {
    background: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    position: relative
}

body .ui-tabmenu .ui-tabmenu-nav:before {
    border-bottom: 2px solid #dee2e6;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border-bottom: 2px solid #dee2e6;
    color: #6c757d;
    cursor: pointer;
    display: inline-block;
    display: -ms-flexbox;
    display: flex;
    float: none;
    list-style: none;
    margin: 0;
    padding: 0;
    top: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    vertical-align: top;
    white-space: nowrap
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a {
    color: #6c757d;
    display: block;
    font-weight: 700;
    line-height: 1;
    outline: 0 none;
    padding: 1rem
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a .ui-icon {
    margin-right: .5rem
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover {
    background: #fff;
    border-color: #adb5bd
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover a,
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover a .ui-icon {
    color: #6c757d
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
    background: #fff;
    border-color: #e91e63
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active a,
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active a .ui-icon {
    color: #e91e63
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-menuitem-outline {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

@media screen and (max-width:576px) {
    body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a .ui-menuitem-icon {
        margin-right: 0
    }

    body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a .ui-menuitem-text {
        display: none
    }
}

body .ui-growl {
    top: 85px
}

body .ui-growl .ui-growl-item-container {
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    margin: 0 0 1rem
}

body .ui-growl .ui-growl-item-container .ui-icon-closethick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    right: .5rem;
    top: .5rem
}

body .ui-growl .ui-growl-item-container .ui-icon-closethick:before {
    content: "\e90b"
}

body .ui-growl .ui-growl-item-container .ui-growl-item {
    min-height: 70px;
    padding: 1rem
}

body .ui-growl .ui-growl-item-container.ui-growl-info {
    background: #b3e5fc;
    border: solid #03a9f4;
    border-width: 0 0 0 6px;
    color: #01579b
}

body .ui-growl .ui-growl-item-container.ui-growl-info .ui-growl-image {
    background: none;
    color: #01579b;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400
}

body .ui-growl .ui-growl-item-container.ui-growl-info .ui-growl-image:before {
    content: "\e924"
}

body .ui-growl .ui-growl-item-container.ui-growl-info .ui-icon {
    color: #01579b
}

body .ui-growl .ui-growl-item-container.ui-growl-warn {
    background: #ffecb3;
    border: solid #ffc107;
    border-width: 0 0 0 6px;
    color: #7f6003
}

body .ui-growl .ui-growl-item-container.ui-growl-warn .ui-growl-image {
    background: none;
    color: #7f6003;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400
}

body .ui-growl .ui-growl-item-container.ui-growl-warn .ui-growl-image:before {
    content: "\e922"
}

body .ui-growl .ui-growl-item-container.ui-growl-warn .ui-icon {
    color: #7f6003
}

body .ui-growl .ui-growl-item-container.ui-growl-error,
body .ui-growl .ui-growl-item-container.ui-growl-fatal {
    background: #ffcdd2;
    border: solid #ef5350;
    border-width: 0 0 0 6px;
    color: #b71c1c
}

body .ui-growl .ui-growl-item-container.ui-growl-error .ui-growl-image,
body .ui-growl .ui-growl-item-container.ui-growl-fatal .ui-growl-image {
    background: none;
    color: #b71c1c;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400
}

body .ui-growl .ui-growl-item-container.ui-growl-error .ui-growl-image:before,
body .ui-growl .ui-growl-item-container.ui-growl-fatal .ui-growl-image:before {
    content: "\e90c"
}

body .ui-growl .ui-growl-item-container.ui-growl-error .ui-icon,
body .ui-growl .ui-growl-item-container.ui-growl-fatal .ui-icon {
    color: #b71c1c
}

body .ui-message {
    border-radius: 4px;
    display: inline-block;
    padding: .5rem
}

body .ui-message.ui-message-info {
    background: #b3e5fc;
    border: solid #03a9f4;
    border-width: 0 0 0 3px;
    color: #01579b
}

body .ui-message.ui-message-info .ui-message-info-icon {
    background: none;
    color: #01579b;
    display: inline-block;
    float: none;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    height: auto;
    margin: 0;
    position: relative;
    top: 1px;
    width: auto
}

body .ui-message.ui-message-info .ui-message-info-icon:before {
    content: "\e924"
}

body .ui-message.ui-message-info .ui-message-info-detail {
    margin-left: .5rem
}

body .ui-message.ui-message-warn {
    background: #ffecb3;
    border: solid #ffc107;
    border-width: 0 0 0 3px;
    color: #7f6003
}

body .ui-message.ui-message-warn .ui-message-warn-icon {
    background: none;
    color: #7f6003;
    display: inline-block;
    float: none;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    height: auto;
    margin: 0;
    position: relative;
    top: 1px;
    width: auto
}

body .ui-message.ui-message-warn .ui-message-warn-icon:before {
    content: "\e922"
}

body .ui-message.ui-message-warn .ui-message-warn-detail {
    margin-left: .5rem
}

body .ui-message.ui-message-error,
body .ui-message.ui-message-fatal {
    background: #ffcdd2;
    border: solid #ef5350;
    border-width: 0 0 0 3px;
    color: #b71c1c
}

body .ui-message.ui-message-error .ui-message-error-icon,
body .ui-message.ui-message-fatal .ui-message-error-icon {
    background: none;
    color: #b71c1c;
    display: inline-block;
    float: none;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    height: auto;
    margin: 0;
    position: relative;
    top: 1px;
    width: auto
}

body .ui-message.ui-message-error .ui-message-error-icon:before,
body .ui-message.ui-message-fatal .ui-message-error-icon:before {
    content: "\e90c"
}

body .ui-message.ui-message-error .ui-message-error-detail,
body .ui-message.ui-message-fatal .ui-message-error-detail {
    margin-left: .5rem
}

body .ui-message.ui-message-icon-only {
    height: auto;
    width: auto
}

body .ui-widget+.ui-message {
    margin: 0 0 0 .5rem
}

body .ui-messages {
    border-radius: 4px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    position: relative
}

body .ui-messages>div {
    border-radius: 4px;
    margin: 1rem 0;
    padding: 1.5rem
}

body .ui-messages ul {
    margin: 0
}

body .ui-messages ul .ui-messages-error-summary,
body .ui-messages ul .ui-messages-fatal-summary,
body .ui-messages ul .ui-messages-info-summary,
body .ui-messages ul .ui-messages-warn-summary {
    margin: 0 .5rem 0 0
}

body .ui-messages ul .ui-messages-error-detail,
body .ui-messages ul .ui-messages-fatal-detail,
body .ui-messages ul .ui-messages-info-detail,
body .ui-messages ul .ui-messages-warn-detail {
    margin: 0
}

body .ui-messages .ui-messages-close {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    position: absolute;
    right: -1rem;
    text-align: center;
    top: -1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    transition: background-color .2s;
    user-select: none;
    width: 2rem
}

body .ui-messages .ui-messages-close:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-messages .ui-messages-close:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-messages .ui-messages-close .ui-icon-close {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-messages .ui-messages-close .ui-icon-close:before {
    content: "\e90b"
}

body .ui-messages .ui-messages-info {
    background: #b3e5fc;
    border: solid #03a9f4;
    border-width: 0 0 0 6px;
    color: #01579b
}

body .ui-messages .ui-messages-info .ui-messages-info-icon {
    background: none;
    color: #01579b;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 .5rem 0 0
}

body .ui-messages .ui-messages-info .ui-messages-info-icon:before {
    content: "\e924"
}

body .ui-messages .ui-messages-info .ui-messages-close {
    background: #b3e5fc;
    color: #01579b
}

body .ui-messages .ui-messages-info .ui-messages-close:hover {
    background: #9addfb
}

body .ui-messages .ui-messages-warn {
    background: #ffecb3;
    border: solid #ffc107;
    border-width: 0 0 0 6px;
    color: #7f6003
}

body .ui-messages .ui-messages-warn .ui-messages-warn-icon {
    background: none;
    color: #7f6003;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 .5rem 0 0
}

body .ui-messages .ui-messages-warn .ui-messages-warn-icon:before {
    content: "\e922"
}

body .ui-messages .ui-messages-warn .ui-messages-close {
    background: #ffecb3;
    color: #7f6003
}

body .ui-messages .ui-messages-warn .ui-messages-close:hover {
    background: #ffe69a
}

body .ui-messages .ui-messages-error,
body .ui-messages .ui-messages-fatal {
    background: #ffcdd2;
    border: solid #ef5350;
    border-width: 0 0 0 6px;
    color: #b71c1c
}

body .ui-messages .ui-messages-error .ui-messages-error-icon,
body .ui-messages .ui-messages-error .ui-messages-fatal,
body .ui-messages .ui-messages-fatal .ui-messages-error-icon,
body .ui-messages .ui-messages-fatal .ui-messages-fatal {
    background: none;
    color: #b71c1c;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 .5rem 0 0
}

body .ui-messages .ui-messages-error .ui-messages-error-icon:before,
body .ui-messages .ui-messages-error .ui-messages-fatal:before,
body .ui-messages .ui-messages-fatal .ui-messages-error-icon:before,
body .ui-messages .ui-messages-fatal .ui-messages-fatal:before {
    content: "\e90c"
}

body .ui-messages .ui-messages-error .ui-messages-close,
body .ui-messages .ui-messages-fatal .ui-messages-close {
    background: #ffcdd2;
    color: #b71c1c
}

body .ui-messages .ui-messages-error .ui-messages-close:hover,
body .ui-messages .ui-messages-fatal .ui-messages-close:hover {
    background: #ffb4bb
}

body .ui-avatar {
    background-color: #dee2e6;
    border-radius: 4px
}

body .ui-avatar.ui-avatar-lg {
    font-size: 1.5rem;
    height: 3rem;
    width: 3rem
}

body .ui-avatar.ui-avatar-lg .ui-avatar-icon {
    font-size: 1.5rem
}

body .ui-avatar.ui-avatar-xl {
    font-size: 2rem;
    height: 4rem;
    width: 4rem
}

body .ui-avatar.ui-avatar-xl .ui-avatar-icon {
    font-size: 2rem
}

body .ui-avatar-group .ui-avatar {
    border: 2px solid #fff
}

body .ui-badge {
    background: #e91e63;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    height: 1.5rem;
    line-height: 1.5rem;
    min-width: 1.5rem
}

body .ui-badge:not(.ui-widget) {
    border-radius: 50%;
    display: inline-block;
    text-align: center
}

body .ui-badge.ui-badge-secondary {
    background: #607d8b;
    color: #fff
}

body .ui-badge.ui-badge-success {
    background: #689f38;
    color: #fff
}

body .ui-badge.ui-badge-info {
    background: #0288d1;
    color: #fff
}

body .ui-badge.ui-badge-warning {
    background: #fbc02d;
    color: #212529
}

body .ui-badge.ui-badge-danger {
    background: #d32f2f;
    color: #fff
}

body .ui-badge.ui-badge-lg {
    font-size: 1.125rem;
    height: 2.25rem;
    line-height: 2.25rem;
    min-width: 2.25rem
}

body .ui-badge.ui-badge-xl {
    font-size: 1.5rem;
    height: 3rem;
    line-height: 3rem;
    min-width: 3rem
}

body .ui-overlay-badge {
    display: inline-block;
    position: relative
}

body .ui-overlay-badge .ui-badge {
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%)
}

body .ui-chip {
    background-color: #dee2e6;
    border-radius: 16px;
    color: #495057;
    padding: 0 .5rem
}

body .ui-chip .ui-chip-text {
    line-height: 1.5;
    margin-bottom: .25rem;
    margin-top: .25rem
}

body .ui-chip .ui-chip-icon,
body .ui-chip img {
    margin-right: .5rem
}

body .ui-chip img {
    height: 2rem;
    margin-left: -.5rem;
    width: 2rem
}

body .ui-chip .ui-chip-remove-icon {
    border-radius: 4px;
    margin-left: .5rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-chip .ui-chip-remove-icon:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-clock {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-galleria .ui-galleria-close {
    background: transparent;
    border-radius: 50%;
    color: #f8f9fa;
    height: 4rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    width: 4rem
}

body .ui-galleria .ui-galleria-close .ui-galleria-close-icon {
    font-size: 2rem
}

body .ui-galleria .ui-galleria-close:hover {
    background: hsla(0, 0%, 100%, .1);
    color: #f8f9fa
}

body .ui-galleria .ui-galleria-item-nav {
    background: transparent;
    border-radius: 4px;
    color: #f8f9fa;
    height: 4rem;
    margin: 0 .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    width: 4rem
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-prev-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-prev-icon:before {
    content: "\e900"
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-next-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-next-icon:before {
    content: "\e901"
}

body .ui-galleria .ui-galleria-item-nav:not(.ui-state-disabled):hover {
    background: hsla(0, 0%, 100%, .1);
    color: #f8f9fa
}

body .ui-galleria .ui-galleria-caption-item {
    background: rgba(0, 0, 0, .5);
    color: #f8f9fa;
    padding: 1rem
}

body .ui-galleria .ui-galleria-indicators {
    padding: 1rem
}

body .ui-galleria .ui-galleria-indicators .ui-galleria-indicator button {
    background-color: #e9ecef;
    border-radius: 50%;
    height: 1rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    width: 1rem
}

body .ui-galleria .ui-galleria-indicators .ui-galleria-indicator button:hover {
    background: #dee2e6
}

body .ui-galleria .ui-galleria-indicators .ui-galleria-indicator.ui-state-highlight button {
    background: #e91e63;
    color: #fff
}

body .ui-galleria.ui-galleria-indicators-bottom .ui-galleria-indicator,
body .ui-galleria.ui-galleria-indicators-top .ui-galleria-indicator {
    margin-right: .5rem
}

body .ui-galleria.ui-galleria-indicators-left .ui-galleria-indicator,
body .ui-galleria.ui-galleria-indicators-right .ui-galleria-indicator {
    margin-bottom: .5rem
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators {
    background: rgba(0, 0, 0, .5)
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators .ui-galleria-indicator button {
    background: hsla(0, 0%, 100%, .4)
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators .ui-galleria-indicator button:hover {
    background: hsla(0, 0%, 100%, .6)
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators .ui-galleria-indicator.ui-state-highlight button {
    background: #e91e63;
    color: #fff
}

body .ui-galleria .ui-galleria-thumbnail-container {
    background: rgba(0, 0, 0, .9);
    padding: .8rem .25rem
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev {
    background-color: transparent;
    border-radius: 50%;
    color: #f8f9fa;
    height: 2rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    width: 2rem
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next:hover,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev:hover {
    background: hsla(0, 0%, 100%, .1);
    color: #f8f9fa
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-e,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-e:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-e:before {
    content: "\e901"
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-s,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-s:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-s:before {
    content: "\e902"
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-w,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-w {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-w:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-w:before {
    content: "\e900"
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-n,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-n {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-n:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-n:before {
    content: "\e903"
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content {
    margin: .2rem 0;
    transition: box-shadow .2s
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-galleria.ui-galleria-thumbnails-left .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content,
body .ui-galleria.ui-galleria-thumbnails-right .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content {
    margin: .2rem
}

body .ui-galleria .ui-galleria-link:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

.ui-galleria-mask.ui-widget-overlay {
    background-color: rgba(0, 0, 0, .9)
}

body .ui-log {
    border: 0;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    height: auto;
    padding: 0
}

body .ui-log .ui-log-header {
    background: #fff;
    border-bottom: 0;
    color: #495057;
    font-weight: 700;
    height: auto;
    padding: 1.5rem
}

body .ui-log .ui-log-header .ui-log-button {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    margin-right: .5rem;
    padding: 0;
    position: static;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-log .ui-log-header .ui-log-button:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-log .ui-log-header .ui-log-button:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-trash {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-trash:before {
    content: "\e93d"
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-note {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-note:before {
    content: "\e9a8"
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-info {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-info:before {
    content: "\e924"
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-notice {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-notice:before {
    content: "\e989"
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-search {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-search:before {
    content: "\e908"
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-alert {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-alert:before {
    content: "\e922"
}

body .ui-log .ui-log-content {
    background: #fff;
    border: 0;
    color: #495057;
    overflow: auto;
    padding: 0 1.5rem 2rem
}

body .ui-progressbar {
    background: #efefef;
    border: 0;
    border-radius: 4px;
    height: 1rem
}

body .ui-progressbar .ui-progressbar-value {
    background: #e91e63;
    border: 0;
    border-radius: 4px;
    margin: 0
}

body .ui-progressbar .ui-progressbar-label {
    color: #495057;
    line-height: 1rem;
    margin-top: 0
}

body .ui-scrolltop {
    background: rgba(0, 0, 0, .7);
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    height: 3rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 3rem
}

body .ui-scrolltop:hover {
    background: rgba(0, 0, 0, .8)
}

body .ui-scrolltop .ui-scrolltop-icon {
    color: #f8f9fa;
    font-size: 1.5rem
}

body .ui-skeleton {
    background-color: #e9ecef;
    border-radius: 4px
}

body .ui-skeleton:after {
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .4), hsla(0, 0%, 100%, 0))
}

body .ui-tag {
    background: #e91e63;
    border-radius: 4px;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    padding: .25rem .4rem
}

body .ui-tag:not(.ui-widget) {
    display: inline-block;
    line-height: 1;
    text-align: center
}

body .ui-tag.ui-tag-success {
    background: #689f38;
    color: #fff
}

body .ui-tag.ui-tag-info {
    background: #0288d1;
    color: #fff
}

body .ui-tag.ui-tag-warning {
    background: #fbc02d;
    color: #212529
}

body .ui-tag.ui-tag-danger {
    background: #d32f2f;
    color: #fff
}

body .ui-tag.ui-tag-rounded {
    border-radius: 10rem
}

body .ui-tag .ui-tag-icon {
    font-size: .75rem;
    margin-right: .25rem
}

body .ui-terminal {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-confirm-popup {
    background: #fff;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    color: #495057
}

body .ui-confirm-popup .ui-confirm-popup-content {
    padding: 1rem
}

body .ui-confirm-popup .ui-confirm-popup-footer {
    padding: 0 1rem 1rem;
    text-align: right
}

body .ui-confirm-popup .ui-confirm-popup-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

body .ui-confirm-popup .ui-confirm-popup-footer button:last-child {
    margin: 0
}

body .ui-confirm-popup:after,
body .ui-confirm-popup:before {
    border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #fff;
    border-style: solid
}

body .ui-confirm-popup.ui-confirm-popup-flipped:after,
body .ui-confirm-popup.ui-confirm-popup-flipped:before {
    border-top-color: #fff
}

body .ui-confirm-popup .ui-confirm-popup-icon {
    font-size: 1.5rem
}

body .ui-confirm-popup .ui-confirm-popup-message {
    margin-left: 1rem
}

body .ui-dialog {
    border: 0;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    padding: 0
}

body .ui-dialog .ui-dialog-titlebar {
    background: #fff;
    border-bottom: 0;
    color: #495057;
    font-weight: 700;
    padding: 1.5rem
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
    float: none;
    font-size: 1.25rem;
    margin: 0
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    margin-left: .5rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-closethick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-closethick:before {
    content: "\e90b"
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-minus {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-minus:before {
    content: "\e90f"
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-plus {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-plus:before {
    content: "\e90d"
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-extlink {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-extlink:before {
    content: "\e93b"
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-newwin {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-newwin:before {
    content: "\e93a"
}

body .ui-dialog .ui-dialog-content {
    background: #fff;
    border: 0;
    color: #495057;
    padding: 0 1.5rem 2rem
}

body .ui-dialog .ui-dialog-footer {
    background: #fff;
    border-top: 0;
    color: #495057;
    margin: 0;
    padding: 0 1.5rem 1.5rem;
    text-align: right
}

body .ui-dialog .ui-dialog-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    padding: 0 1.5rem 2rem
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span {
    display: inline-block;
    float: none;
    line-height: 1;
    margin: 0 0 0 .5rem;
    vertical-align: middle
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon {
    font-size: 2.5rem;
    margin-right: .5rem
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-warn {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-warn:before {
    content: "\e922"
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-info {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-info:before {
    content: "\e924"
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-error {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-error:before {
    content: "\e90c"
}

body .ui-dialog.ui-resizable .ui-dialog-content {
    box-sizing: content-box
}

body .ui-dialog.ui-message-dialog .ui-dialog-content {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    padding: 0 1.5rem 2rem
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span {
    display: inline-block;
    float: none;
    line-height: 1;
    margin: 0 0 0 .5rem;
    vertical-align: middle
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message {
    font-size: 2.5rem;
    height: auto;
    margin-right: .5rem;
    width: auto
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-warn-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-warn-icon:before {
    content: "\e922"
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-info-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-info-icon:before {
    content: "\e922"
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-error-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-error-icon:before {
    content: "\e90c"
}

body .ui-lightbox {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-lightbox .ui-lightbox-caption {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
    color: #495057;
    margin: 0
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close .ui-icon:before {
    content: "\e90b"
}

body .ui-lightbox .ui-lightbox-content-wrapper {
    background: #fff;
    border: 0;
    color: #495057;
    overflow: hidden;
    padding: 0
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left {
    background: none;
    color: #fff;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-left: 4px;
    transition: all .2s
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:before {
    content: "\e900"
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover {
    scale: 1.2
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left span {
    display: none
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
    background: none;
    color: #fff;
    display: inline-block;
    font-family: primeicons !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-right: 4px;
    transition: all .2s
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:before {
    content: "\e901"
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
    scale: 1.2
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right span {
    display: none
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content {
    border-radius: 0
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content.ui-lightbox-loading+a {
    display: none
}

body .ui-overlaypanel {
    background: #fff;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    color: #495057;
    padding: 0
}

body .ui-overlaypanel .ui-overlaypanel-content {
    padding: 1rem
}

body .ui-overlaypanel .ui-overlaypanel-close {
    background: #e91e63;
    border-radius: 50%;
    color: #fff;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    position: absolute;
    right: -1rem;
    text-align: center;
    top: -1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 2rem
}

body .ui-overlaypanel .ui-overlaypanel-close:hover {
    background: #d81557;
    color: #fff
}

body .ui-overlaypanel .ui-overlaypanel-close .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    line-height: inherit
}

body .ui-overlaypanel .ui-overlaypanel-close .ui-icon:before {
    content: "\e90b"
}

body .ui-sidebar {
    background: #fff;
    border: 0;
    box-shadow: none;
    color: #495057;
    padding: 1rem
}

body .ui-sidebar .ui-sidebar-close {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-sidebar .ui-sidebar-close:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-sidebar .ui-sidebar-close:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-sidebar .ui-sidebar-close .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-sidebar .ui-sidebar-close .ui-icon:before {
    content: "\e90b"
}

body .ui-sidebar.ui-sidebar-active,
body .ui-tooltip .ui-tooltip-text {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-tooltip .ui-tooltip-text {
    background: #252529;
    border-radius: 4px;
    color: #fff
}

body .ui-tooltip.ui-state-error {
    border: 0
}

body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
    border-right-color: #252529
}

body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
    border-left-color: #252529
}

body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
    border-top-color: #252529
}

body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
    border-bottom-color: #252529
}

body .ui-accordion .ui-accordion-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0;
    border-top: 0;
    color: #495057;
    font-weight: 700;
    margin-top: 0;
    outline: 0 none;
    padding: 1rem;
    position: relative;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

body .ui-accordion .ui-accordion-header:first-child {
    border-top: 1px solid #dee2e6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 0
}

body .ui-accordion .ui-accordion-header.ui-accordion-header-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-accordion .ui-accordion-header.ui-accordion-header-last.ui-state-active {
    border-radius: 0
}

body .ui-accordion .ui-accordion-header.ui-state-hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057
}

body .ui-accordion .ui-accordion-header.ui-state-active {
    background: #f8f9fa;
    border-color: #dee2e6;
    color: #495057
}

body .ui-accordion .ui-accordion-header.ui-state-active:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057
}

body .ui-accordion .ui-accordion-header:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-accordion .ui-accordion-header>.ui-icon {
    display: inline-block;
    margin: 0 .5rem 0 0;
    position: static
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-e:before {
    content: "\e901"
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-s {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-s:before {
    content: "\e902"
}

body .ui-accordion .ui-accordion-content {
    background: #fff;
    border: 1px solid #dee2e6;
    border-top: 0;
    color: #495057;
    margin: 0;
    padding: 1rem;
    position: static
}

body .ui-accordion .ui-accordion-content:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-card {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    color: #495057
}

body .ui-card .ui-card-body {
    padding: 1rem
}

body .ui-card .ui-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .5rem
}

body .ui-card .ui-card-subtitle {
    color: #6c757d;
    font-weight: 400;
    margin-bottom: .5rem
}

body .ui-card .ui-card-content {
    padding: 1rem 0
}

body .ui-card .ui-card-footer {
    padding: 1rem 0 0
}

body .ui-dashboard .ui-sortable-placeholder {
    background-color: #e91e63
}

body .ui-divider .ui-divider-content {
    background-color: #fff
}

body .ui-divider.ui-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem
}

body .ui-divider.ui-divider-horizontal:before {
    border-top: 1px #dee2e6
}

body .ui-divider.ui-divider-horizontal .ui-divider-content {
    padding: 0 .5rem
}

body .ui-divider.ui-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0
}

body .ui-divider.ui-divider-vertical:before {
    border-left: 1px #dee2e6
}

body .ui-divider.ui-divider-vertical .ui-divider-content {
    padding: .5rem 0
}

body .ui-fieldset {
    background: #fff
}

body .ui-fieldset,
body .ui-fieldset .ui-fieldset-legend {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #495057;
    padding: 1rem
}

body .ui-fieldset .ui-fieldset-legend {
    background: #f8f9fa;
    font-weight: 700;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

body .ui-fieldset .ui-fieldset-legend .ui-fieldset-toggler {
    display: inline-block;
    float: none;
    margin-right: .5rem
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-minusthick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-minusthick:before {
    content: "\e90f"
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-plusthick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-plusthick:before {
    content: "\e90d"
}

body .ui-fieldset .ui-fieldset-legend.ui-state-hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057;
    outline: 0 none
}

body .ui-fieldset .ui-fieldset-legend.ui-state-hover .ui-icon {
    color: #495057
}

body .ui-fieldset .ui-fieldset-legend.ui-state-focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-notificationbar {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-panel {
    padding: 0
}

body .ui-panel .ui-panel-title {
    margin: 0
}

body .ui-panel .ui-panel-titlebar {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #6c757d;
    height: 2rem;
    line-height: 2rem;
    margin: -.25rem 0 -.5rem .5rem;
    padding: 0;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    user-select: none;
    width: 2rem
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
    background: #e9ecef;
    border-color: transparent;
    color: #495057
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:focus {
    box-shadow: 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-closethick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-closethick:before {
    content: "\e90b"
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-minusthick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-minusthick:before {
    content: "\e90f"
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-plusthick {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-plusthick:before {
    content: "\e90d"
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-gear {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-gear:before {
    content: "\e94a"
}

body .ui-panel .ui-panel-titlebar+.ui-panel-content {
    border-top: 0
}

body .ui-panel .ui-panel-content,
body .ui-panel .ui-panel-footer {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-panel .ui-panel-footer {
    border-top: 0;
    margin: 0
}

body .ui-panel.ui-panel-collapsed .ui-panel-titlebar,
body .ui-panel>div:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-panel.ui-panel-collapsed-h .ui-panel-titlebar {
    position: relative
}

body .ui-panel.ui-panel-collapsed-h .ui-panel-titlebar .ui-panel-titlebar-icon {
    float: none;
    left: 50%;
    margin-left: -.5rem;
    margin-top: -.5rem;
    position: absolute;
    top: 50%
}

body .ui-panelgrid .ui-panelgrid-cell {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-panelgrid .ui-panelgrid-cell.ui-widget-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
    font-weight: 700;
    padding: 1rem
}

body .ui-scrollpanel {
    background: #fff;
    border: 1px solid #dee2e6;
    color: #495057;
    padding: 1rem
}

body .ui-scrollpanel .ui-scrollpanel-drag {
    background: #dadada
}

body .ui-scrollpanel .ui-scrollpanel-track {
    background: #f8f8f8;
    border: 0
}

body .ui-scrollpanel .ui-scrollpanel-corner {
    background: #f8f8f8
}

body .ui-splitter {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #495057
}

body .ui-splitter .ui-splitter-gutter {
    background: #f8f9fa;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-splitter .ui-splitter-gutter .ui-splitter-gutter-handle,
body .ui-splitter .ui-splitter-gutter-resizing {
    background: #dee2e6
}

body .ui-tabs {
    padding: 0;
    position: static
}

body .ui-tabs .ui-tabs-nav {
    background: #fff;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header {
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    color: #6c757d;
    cursor: pointer;
    display: inline-block;
    display: -ms-flexbox;
    display: flex;
    float: none;
    list-style: none;
    margin: 0;
    padding: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    vertical-align: top;
    white-space: nowrap
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header a {
    color: #6c757d;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header .ui-icon {
    position: static
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header .ui-icon.ui-icon-close {
    background: none;
    display: inline-block;
    float: none;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 0 .5rem
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header .ui-icon.ui-icon-close:before {
    content: "\e90b"
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    background: #fff
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-hover .ui-icon,
body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-hover a {
    color: #6c757d
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-active {
    background: #fff
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-active .ui-icon,
body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-active a {
    color: #e91e63
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-tabs-outline {
    box-shadow: inset 0 0 0 .2rem #f6a5c1;
    outline: 0 none;
    outline-offset: 0
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-actions {
    margin-left: auto;
    order: 1
}

body .ui-tabs .ui-tabs-panels {
    background: #fff;
    border: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #495057;
    margin: 0;
    padding: 0;
    position: static
}

body .ui-tabs .ui-tabs-panels .ui-tabs-panel {
    padding: 1rem
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    position: relative
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav:before {
    border-bottom: 2px solid #dee2e6;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 100%
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav li.ui-tabs-header {
    border-bottom: 2px solid #dee2e6;
    top: 0
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #e91e63
}

body .ui-tabs.ui-tabs-top>.ui-tabs-panels {
    border-width: 1px 0 0
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    position: relative
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav:before {
    border-top: 2px solid #dee2e6;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav li.ui-tabs-header {
    border-top: 2px solid #dee2e6;
    top: 0
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #e91e63
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-panels {
    border-width: 1px 0 0
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav,
body .ui-tabs.ui-tabs-right>.ui-tabs-nav {
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    margin: 0;
    padding: 0;
    width: 25%
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header,
body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header {
    width: 100%
}

body .ui-tabs.ui-tabs-left>.ui-tabs-panels,
body .ui-tabs.ui-tabs-right>.ui-tabs-panels {
    width: 75%
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav {
    border-left: 2px solid #dee2e6;
    margin: 0;
    padding: 0
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header {
    border-left: 2px solid #dee2e6;
    left: -2px;
    top: 0
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #e91e63
}

body .ui-tabs.ui-tabs-right>.ui-tabs-panels {
    border-width: 1px 0 0
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav {
    margin: 0;
    padding: 0;
    right: -2px
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header {
    border-right: 2px solid #dee2e6;
    margin-right: -2px;
    top: 0
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #e91e63
}

body .ui-tabs.ui-tabs-left>.ui-tabs-panels {
    border-width: 1px 0 0
}

body .ui-tabs .ui-tabs-navscroller {
    position: relative
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn {
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border: 0;
    color: #6c757d;
    display: -ms-flexbox;
    display: flex;
    outline: 0 none;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    z-index: 1
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-left {
    height: 100%;
    left: 0
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-left .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-left .ui-icon:before {
    content: "\e900"
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-right {
    height: 100%;
    right: 0
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-right .ui-icon {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400;
    margin: 0
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-right .ui-icon:before {
    content: "\e901"
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-toolbar {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #495057;
    padding: 1rem
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0;
    border-right: 0;
    color: #495057;
    margin: 0;
    padding: 1rem
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title:last-child {
    border-bottom-right-radius: 4px;
    border-right: 1px solid #dee2e6;
    border-top-right-radius: 4px
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title.ui-state-highlight {
    background: #e91e63;
    color: #fff
}

body .ui-wizard .ui-icon-arrowthick-1-w {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-wizard .ui-icon-arrowthick-1-w:before {
    content: "\e91a"
}

body .ui-wizard .ui-icon-arrowthick-1-e {
    background: none;
    display: inline-block;
    font-family: primeicons !important;
    font-style: normal;
    font-weight: 400
}

body .ui-wizard .ui-icon-arrowthick-1-e:before {
    content: "\e91b"
}

:root {
    --surface-a: #5d88d6;
    --surface-b: #5d88d6;
    --surface-c: #4c4c4c;
    --surface-d: #4b4b4b;
    --surface-e: #fff;
    --surface-f: #fff;
    --text-color: #323130;
    --text-color-secondary: #fff;
    --primary-color: #d0021b;
    --primary-color-text: #fff;
    --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --surface-0: #5d88d6;
    --surface-50: #1f1f1f;
    --surface-100: #252525;
    --surface-200: #2a2a2a;
    --surface-300: #303030;
    --surface-400: #363636;
    --surface-500: #3c3c3c;
    --surface-600: #414141;
    --surface-700: #474747;
    --surface-800: #4d4d4d;
    --surface-900: #535353;
    --content-padding: 0.571rem 1rem;
    --inline-spacing: 0.5rem
}

* {
    box-sizing: border-box
}

.p-max-width {
    width: 100%
}

.p-component {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1rem;
    font-weight: 400
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, .4);
    transition-duration: .2s
}

.p-component:disabled,
.p-disabled {
    opacity: .5
}

.p-error {
    color: #e57373
}

.p-text-secondary {
    color: #888
}

.p-link,
.pi {
    font-size: 1rem
}

.p-link {
    border-radius: 3px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
}

.p-link:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

:root {
    --blue-50: tint(#106ebe, 95%);
    --blue-100: tint(#106ebe, 76%);
    --blue-200: tint(#106ebe, 57%);
    --blue-300: tint(#106ebe, 38%);
    --blue-400: tint(#106ebe, 19%);
    --blue-500: tint(#106ebe, 0%);
    --blue-600: shade(#106ebe, 15%);
    --blue-700: shade(#106ebe, 30%);
    --blue-800: shade(#106ebe, 45%);
    --blue-900: shade(#106ebe, 60%);
    --green-50: tint(#56a641, 95%);
    --green-100: tint(#56a641, 76%);
    --green-200: tint(#56a641, 57%);
    --green-300: tint(#56a641, 38%);
    --green-400: tint(#56a641, 19%);
    --green-500: tint(#56a641, 0%);
    --green-600: shade(#56a641, 15%);
    --green-700: shade(#56a641, 30%);
    --green-800: shade(#56a641, 45%);
    --green-900: shade(#56a641, 60%);
    --yellow-50: tint(#f6bd30, 95%);
    --yellow-100: tint(#f6bd30, 76%);
    --yellow-200: tint(#f6bd30, 57%);
    --yellow-300: tint(#f6bd30, 38%);
    --yellow-400: tint(#f6bd30, 19%);
    --yellow-500: tint(#f6bd30, 0%);
    --yellow-600: shade(#f6bd30, 15%);
    --yellow-700: shade(#f6bd30, 30%);
    --yellow-800: shade(#f6bd30, 45%);
    --yellow-900: shade(#f6bd30, 60%);
    --cyan-50: tint(#2cacd5, 95%);
    --cyan-100: tint(#2cacd5, 76%);
    --cyan-200: tint(#2cacd5, 57%);
    --cyan-300: tint(#2cacd5, 38%);
    --cyan-400: tint(#2cacd5, 19%);
    --cyan-500: tint(#2cacd5, 0%);
    --cyan-600: shade(#2cacd5, 15%);
    --cyan-700: shade(#2cacd5, 30%);
    --cyan-800: shade(#2cacd5, 45%);
    --cyan-900: shade(#2cacd5, 60%);
    --pink-50: tint(#d8297d, 95%);
    --pink-100: tint(#d8297d, 76%);
    --pink-200: tint(#d8297d, 57%);
    --pink-300: tint(#d8297d, 38%);
    --pink-400: tint(#d8297d, 19%);
    --pink-500: tint(#d8297d, 0%);
    --pink-600: shade(#d8297d, 15%);
    --pink-700: shade(#d8297d, 30%);
    --pink-800: shade(#d8297d, 45%);
    --pink-900: shade(#d8297d, 60%);
    --indigo-50: tint(#2c47d5, 95%);
    --indigo-100: tint(#2c47d5, 76%);
    --indigo-200: tint(#2c47d5, 57%);
    --indigo-300: tint(#2c47d5, 38%);
    --indigo-400: tint(#2c47d5, 19%);
    --indigo-500: tint(#2c47d5, 0%);
    --indigo-600: shade(#2c47d5, 15%);
    --indigo-700: shade(#2c47d5, 30%);
    --indigo-800: shade(#2c47d5, 45%);
    --indigo-900: shade(#2c47d5, 60%);
    --teal-50: tint(#41a694, 95%);
    --teal-100: tint(#41a694, 76%);
    --teal-200: tint(#41a694, 57%);
    --teal-300: tint(#41a694, 38%);
    --teal-400: tint(#41a694, 19%);
    --teal-500: tint(#41a694, 0%);
    --teal-600: shade(#41a694, 15%);
    --teal-700: shade(#41a694, 30%);
    --teal-800: shade(#41a694, 45%);
    --teal-900: shade(#41a694, 60%);
    --orange-50: tint(#f68f30, 95%);
    --orange-100: tint(#f68f30, 76%);
    --orange-200: tint(#f68f30, 57%);
    --orange-300: tint(#f68f30, 38%);
    --orange-400: tint(#f68f30, 19%);
    --orange-500: tint(#f68f30, 0%);
    --orange-600: shade(#f68f30, 15%);
    --orange-700: shade(#f68f30, 30%);
    --orange-800: shade(#f68f30, 45%);
    --orange-900: shade(#f68f30, 60%);
    --bluegray-50: tint(#657c8a, 95%);
    --bluegray-100: tint(#657c8a, 76%);
    --bluegray-200: tint(#657c8a, 57%);
    --bluegray-300: tint(#657c8a, 38%);
    --bluegray-400: tint(#657c8a, 19%);
    --bluegray-500: tint(#657c8a, 0%);
    --bluegray-600: shade(#657c8a, 15%);
    --bluegray-700: shade(#657c8a, 30%);
    --bluegray-800: shade(#657c8a, 45%);
    --bluegray-900: shade(#657c8a, 60%);
    --purple-50: tint(#8378de, 95%);
    --purple-100: tint(#8378de, 76%);
    --purple-200: tint(#8378de, 57%);
    --purple-300: tint(#8378de, 38%);
    --purple-400: tint(#8378de, 19%);
    --purple-500: tint(#8378de, 0%);
    --purple-600: shade(#8378de, 15%);
    --purple-700: shade(#8378de, 30%);
    --purple-800: shade(#8378de, 45%);
    --purple-900: shade(#8378de, 60%)
}

.p-autocomplete .p-autocomplete-loader {
    right: .429rem
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.786rem
}

.p-autocomplete .p-autocomplete-multiple-container {
    padding: .2145rem .429rem
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #d0021b
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: .2145rem 0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    color: #323130;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1rem;
    margin: 0;
    padding: 0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    background: #d0021b;
    border-radius: 3px;
    color: #fff;
    margin-right: .5rem;
    padding: .2145rem .429rem
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: .5rem
}

.p-autocomplete.p-invalid.p-component>.p-inputtext {
    border-color: #e57373
}

.p-autocomplete-panel {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-autocomplete-panel .p-autocomplete-items {
    padding: 0
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    background: #4c4c4c;
    color: #323130
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    background: #5d88d6;
    color: #323130;
    font-weight: 700;
    margin: 0;
    padding: .857rem
}

.p-calendar.p-invalid.p-component>.p-inputtext {
    border-color: #e57373
}

.p-datepicker {
    background: #fff;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    color: #323130;
    padding: .857rem
}

.p-datepicker:not(.p-datepicker-inline) {
    background: #fff;
    border: 1px solid #5d88d6;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16)
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #252525
}

.p-datepicker .p-datepicker-header {
    background: #fff;
    border-bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    font-weight: 700;
    margin: 0;
    padding: .5rem
}

.p-datepicker .p-datepicker-header .p-datepicker-next,
.p-datepicker .p-datepicker-header .p-datepicker-prev {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-datepicker .p-datepicker-header .p-datepicker-next:focus,
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem
}

.p-datepicker .p-datepicker-header .p-datepicker-title select {
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: .5rem
}

.p-datepicker table {
    font-size: 1rem;
    margin: .857rem 0
}

.p-datepicker table th {
    padding: .5rem
}

.p-datepicker table th>span {
    height: 2.5rem;
    width: 2.5rem
}

.p-datepicker table td {
    padding: .5rem
}

.p-datepicker table td>span {
    border: 1px solid transparent;
    border-radius: 3px;
    height: 2.5rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    width: 2.5rem
}

.p-datepicker table td>span.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-datepicker table td>span:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-datepicker table td.p-datepicker-today>span {
    background: #ec407a;
    border-color: transparent;
    color: #fff
}

.p-datepicker table td.p-datepicker-today>span.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-datepicker .p-datepicker-buttonbar {
    border-top: 1px solid #4b4b4b;
    padding: .857rem 0 .429rem
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto
}

.p-datepicker .p-timepicker {
    border-top: 1px solid #4b4b4b;
    padding: .857rem 0 .429rem
}

.p-datepicker .p-timepicker button {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-datepicker .p-timepicker button:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-datepicker .p-timepicker button:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-datepicker .p-timepicker button:last-child {
    margin-top: .2em
}

.p-datepicker .p-timepicker span {
    font-size: 1.286rem
}

.p-datepicker .p-timepicker>div {
    padding: 0 .429rem
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0
}

.p-datepicker .p-monthpicker {
    margin: .857rem 0
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    border-radius: 3px;
    padding: .5rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid #4b4b4b;
    padding: 0 .857rem
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    border-right: 0;
    padding-right: 0
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #4c4c4c
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: #4c4c4c
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-datepicker-mask.p-component-overlay {
    background: rgba(0, 0, 0, .4)
}

@media screen and (max-width:769px) {

    .p-datepicker table td,
    .p-datepicker table th {
        padding: 0
    }
}

.p-cascadeselect {
    background: #5d88d6;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-cascadeselect:not(.p-disabled):hover {
    border-color: #d0021b
}

.p-cascadeselect:not(.p-disabled).p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0;
    padding: .429rem
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #9b9b9b
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
    box-shadow: none;
    outline: 0 none
}

.p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    color: #888;
    width: 2.357rem
}

.p-cascadeselect.p-invalid.p-component {
    border-color: #e57373
}

.p-cascadeselect-panel {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #323130;
    margin: 0;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: .429rem .857rem
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #323130
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: .875rem
}

.p-input-filled .p-cascadeselect {
    background: #4b4b4b
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus,
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #4b4b4b
}

.p-checkbox {
    height: 20px;
    width: 20px
}

.p-checkbox .p-checkbox-box {
    background: #5d88d6;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    color: #323130;
    height: 20px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 20px
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-size: 14px;
    transition-duration: .2s
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: #d0021b;
    border-color: #d0021b
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #d0021b
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-checkbox.p-invalid>.p-checkbox-box {
    border-color: #e57373
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #4b4b4b
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #d0021b
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #4b4b4b
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #ec407a
}

.p-highlight .p-checkbox .p-checkbox-box {
    border-color: #fff
}

.p-chips .p-chips-multiple-container {
    padding: .2145rem .429rem
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: #d0021b
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-chips .p-chips-multiple-container .p-chips-token {
    background: #d0021b;
    border-radius: 3px;
    color: #fff;
    margin-right: .5rem;
    padding: .2145rem .429rem
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: .5rem
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: .2145rem 0
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    color: #323130;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1rem;
    margin: 0;
    padding: 0
}

.p-chips.p-invalid.p-component>.p-inputtext {
    border-color: #e57373
}

.p-colorpicker-preview {
    height: 2rem;
    width: 2rem
}

.p-colorpicker-panel {
    background: #fff;
    border-color: #5d88d6
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #fff
}

.p-colorpicker-overlay-panel {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16)
}

.p-dropdown {
    background: #5d88d6;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #d0021b
}

.p-dropdown:not(.p-disabled).p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.429rem
}

.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #9b9b9b
}

.p-dropdown .p-dropdown-label:enabled:focus {
    box-shadow: none;
    outline: 0 none
}

.p-dropdown .p-dropdown-trigger {
    background: transparent;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    color: #888;
    width: 2.357rem
}

.p-dropdown .p-dropdown-clear-icon {
    color: #888;
    right: 2.357rem
}

.p-dropdown.p-invalid.p-component {
    border-color: #e57373
}

.p-dropdown-panel {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-dropdown-panel .p-dropdown-header {
    background: #252525;
    border-bottom: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.429rem
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    color: #888;
    right: .429rem
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
    padding-right: 2.858rem
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
    right: 1.858rem
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #323130
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    background: #5d88d6;
    color: #323130;
    font-weight: 700;
    margin: 0;
    padding: .857rem
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    background: transparent;
    color: #323130;
    padding: .429rem .857rem
}

.p-input-filled .p-dropdown {
    background: #4b4b4b
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus,
.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #4b4b4b
}

.p-editor-container .p-editor-toolbar {
    background: #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #5d88d6
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #888
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #888
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0;
    color: #888
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    padding: 0
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    background: #4c4c4c;
    color: #323130
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: .429rem .857rem
}

.p-editor-container .p-editor-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px
}

.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #5d88d6
}

.p-editor-container .p-editor-content .ql-editor {
    background: #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #323130
}

.p-editor-container .ql-snow.ql-toolbar button:focus,
.p-editor-container .ql-snow.ql-toolbar button:hover {
    color: #323130
}

.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke {
    stroke: #323130
}

.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill {
    fill: #323130
}

.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar button.ql-active {
    color: #d0021b
}

.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: #d0021b
}

.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill {
    fill: #d0021b
}

.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label {
    color: #d0021b
}

.p-inputgroup-addon {
    background: #252525;
    border-bottom: 1px solid #4b4b4b;
    border-left: 1px solid #4b4b4b;
    border-top: 1px solid #4b4b4b;
    color: #888;
    min-width: 2.357rem;
    padding: .429rem
}

.p-inputgroup-addon:last-child {
    border-right: 1px solid #4b4b4b
}

.p-inputgroup>.p-component,
.p-inputgroup>.p-float-label>.p-component,
.p-inputgroup>.p-inputwrapper>.p-inputtext {
    border-radius: 0;
    margin: 0
}

.p-inputgroup>.p-component+.p-inputgroup-addon,
.p-inputgroup>.p-float-label>.p-component+.p-inputgroup-addon,
.p-inputgroup>.p-inputwrapper>.p-inputtext+.p-inputgroup-addon {
    border-left: 0
}

.p-inputgroup>.p-component:focus,
.p-inputgroup>.p-component:focus~label,
.p-inputgroup>.p-float-label>.p-component:focus,
.p-inputgroup>.p-float-label>.p-component:focus~label,
.p-inputgroup>.p-inputwrapper>.p-inputtext:focus,
.p-inputgroup>.p-inputwrapper>.p-inputtext:focus~label {
    z-index: 1
}

.p-inputgroup .p-float-label:first-child input,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup-addon:first-child,
.p-inputgroup>.p-inputwrapper:first-child,
.p-inputgroup>.p-inputwrapper:first-child>.p-inputtext {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}

.p-inputgroup .p-float-label:last-child input,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup-addon:last-child,
.p-inputgroup>.p-inputwrapper:last-child,
.p-inputgroup>.p-inputwrapper:last-child>.p-inputtext {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px
}

.p-fluid .p-inputgroup .p-button {
    width: auto
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem
}

.p-inputnumber.p-invalid.p-component>.p-inputtext {
    border-color: #e57373
}

.p-inputswitch {
    height: 1.75rem;
    width: 3rem
}

.p-inputswitch .p-inputswitch-slider {
    background: #4b4b4b;
    border-radius: 30px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #fff;
    border-radius: 50%;
    height: 1.25rem;
    left: .25rem;
    margin-top: -.625rem;
    transition-duration: .2s;
    width: 1.25rem
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem)
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #4b4b4b
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #d0021b
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #f06292
}

.p-inputswitch.p-invalid {
    border-color: #e57373
}

.p-inputtext {
    appearance: none;
    background: #5d88d6;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    color: #323130;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1rem;
    padding: .429rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-inputtext:disabled {
    background-color: #868383;
    color: #f1ecef
}

.p-inputtext:enabled:hover {
    border-color: #d0021b
}

.p-inputtext:enabled:focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-inputtext.p-invalid.p-component {
    border-color: #e57373
}

.p-inputtext.p-inputtext-sm {
    font-size: .875rem;
    padding: .375375rem
}

.p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: .53625rem
}

.p-float-label>label {
    color: #9b9b9b;
    left: .429rem;
    transition-duration: .2s
}

.p-float-label>label.p-error {
    color: #e57373
}

.p-input-icon-left>.p-input-prefix,
.p-input-icon-left>i:first-of-type,
.p-input-icon-left>svg:first-of-type {
    color: #888;
    left: .429rem
}

.p-input-icon-left>.p-inputtext {
    padding-left: 1.858rem
}

.p-input-icon-left.p-float-label>label {
    left: 1.858rem
}

.p-input-icon-right>.p-input-suffix,
.p-input-icon-right>i:last-of-type,
.p-input-icon-right>svg:last-of-type {
    color: #888;
    right: .429rem
}

.p-input-icon-right>.p-inputtext {
    padding-right: 1.858rem
}

::-webkit-input-placeholder {
    color: #9b9b9b
}

:-moz-placeholder,
::-moz-placeholder {
    color: #9b9b9b
}

:-ms-input-placeholder {
    color: #9b9b9b
}

.p-input-filled .p-inputtext,
.p-input-filled .p-inputtext:enabled:focus,
.p-input-filled .p-inputtext:enabled:hover {
    background-color: #4b4b4b
}

.p-inputtext-sm .p-inputtext {
    font-size: .875rem;
    padding: .375375rem
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: .53625rem
}

.p-listbox {
    background: #fff;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    color: #323130
}

.p-listbox .p-listbox-header {
    background: #252525;
    border-bottom: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem
}

.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.429rem
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
    color: #888;
    right: .429rem
}

.p-listbox .p-listbox-list {
    padding: 0
}

.p-listbox .p-listbox-list .p-listbox-item {
    border: 0;
    border-radius: 0;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-listbox .p-listbox-list .p-listbox-item-group {
    background: #5d88d6;
    color: #323130;
    font-weight: 700;
    margin: 0;
    padding: .857rem
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
    background: transparent;
    color: #323130;
    padding: .429rem .857rem
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #323130
}

.p-listbox.p-invalid {
    border-color: #e57373
}

.p-multiselect {
    background: #5d88d6;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-multiselect:not(.p-disabled):hover {
    border-color: #d0021b
}

.p-multiselect:not(.p-disabled).p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.429rem
}

.p-multiselect .p-multiselect-label {
    padding: .429rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #9b9b9b
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: #d0021b;
    border-radius: 3px;
    color: #fff;
    margin-right: .5rem;
    padding: .2145rem .429rem
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: .5rem
}

.p-multiselect .p-multiselect-trigger {
    background: transparent;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    color: #888;
    width: 2.357rem
}

.p-multiselect .p-multiselect-clear-icon {
    color: #888;
    right: 2.357rem
}

.p-multiselect.p-invalid.p-component {
    border-color: #e57373
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: .2145rem .429rem
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
    padding: .429rem
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.429rem
}

.p-multiselect-panel {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-multiselect-panel .p-multiselect-header {
    background: #252525;
    border-bottom: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0 .5rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.429rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    color: #888;
    right: .429rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-multiselect-panel .p-multiselect-items {
    padding: 0
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #323130
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: .5rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    background: #5d88d6;
    color: #323130;
    font-weight: 700;
    margin: 0;
    padding: .857rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    background: transparent;
    color: #323130;
    padding: .429rem .857rem
}

.p-input-filled .p-multiselect {
    background: #4b4b4b
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus,
.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #4b4b4b
}

.p-password.p-invalid.p-component>.p-inputtext {
    border-color: #e57373
}

.p-password-panel {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    color: #323130;
    padding: .571rem 1rem
}

.p-password-panel .p-password-meter {
    background: #eaeaea;
    margin-bottom: .5rem
}

.p-password-panel .p-password-meter .p-password-strength.weak {
    background: #e57373
}

.p-password-panel .p-password-meter .p-password-strength.medium {
    background: #ffb74d
}

.p-password-panel .p-password-meter .p-password-strength.strong {
    background: #aed581
}

.p-radiobutton {
    height: 20px;
    width: 20px
}

.p-radiobutton .p-radiobutton-box {
    background: #5d88d6;
    border: 1px solid #4b4b4b;
    border-radius: 50%;
    color: #323130;
    height: 20px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 20px
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #d0021b
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #fff;
    height: 12px;
    transition-duration: .2s;
    width: 12px
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    background: #d0021b;
    border-color: #d0021b
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-radiobutton.p-invalid>.p-radiobutton-box {
    border-color: #e57373
}

.p-radiobutton:focus {
    outline: 0 none
}

.p-input-filled .p-radiobutton .p-radiobutton-box,
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #4b4b4b
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #d0021b
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #ec407a
}

.p-highlight .p-radiobutton .p-radiobutton-box {
    border-color: #fff
}

.p-rating .p-rating-icon {
    color: #323130;
    font-size: 1.143rem;
    margin-left: .5rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-rating .p-rating-icon.p-rating-cancel {
    color: #e4018d
}

.p-rating .p-rating-icon:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-rating .p-rating-icon:first-child {
    margin-left: 0
}

.p-rating .p-rating-icon.pi-star,
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: #d0021b
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
    color: #b5019f
}

.p-highlight .p-rating .p-rating-icon {
    color: #fff
}

.p-selectbutton .p-button {
    background: #252525;
    border: 1px solid #252525;
    color: #323130;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
    color: #888
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #4c4c4c;
    border-color: #4c4c4c;
    color: #323130
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #323130
}

.p-selectbutton .p-button.p-highlight {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #fff
}

.p-selectbutton .p-button.p-highlight:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #fff
}

.p-selectbutton.p-invalid>.p-button {
    border-color: #e57373
}

.p-slider {
    background: #4b4b4b;
    border: 0;
    border-radius: 3px
}

.p-slider.p-slider-horizontal {
    height: .286rem
}

.p-slider.p-slider-horizontal .p-slider-handle {
    margin-left: -.5715rem;
    margin-top: -.5715rem
}

.p-slider.p-slider-vertical {
    width: .286rem
}

.p-slider.p-slider-vertical .p-slider-handle {
    margin-bottom: -.5715rem;
    margin-left: -.5715rem
}

.p-slider .p-slider-handle {
    background: #4c4c4c;
    border: 2px solid #d0021b;
    border-radius: 100%;
    height: 1.143rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 1.143rem
}

.p-slider .p-slider-handle:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-slider .p-slider-range {
    background: #d0021b
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #d0021b;
    border-color: #d0021b
}

.p-treeselect {
    background: #5d88d6;
    border: 1px solid #4b4b4b;
    border-radius: 3px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-treeselect:not(.p-disabled):hover {
    border-color: #d0021b
}

.p-treeselect:not(.p-disabled).p-focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-treeselect .p-treeselect-label {
    padding: .429rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-treeselect .p-treeselect-label.p-placeholder {
    color: #9b9b9b
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
    background: #4b4b4b;
    border-radius: 16px;
    color: #323130;
    margin-right: .5rem;
    padding: .2145rem .429rem
}

.p-treeselect .p-treeselect-trigger {
    background: transparent;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    color: #888;
    width: 2.357rem
}

.p-treeselect.p-invalid.p-component {
    border-color: #e57373
}

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: .2145rem .429rem
}

.p-treeselect-panel {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-treeselect-panel .p-treeselect-header {
    background: #252525;
    border-bottom: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: .5rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
    padding-right: 1.429rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
    color: #888;
    right: .429rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
    padding-right: 2.858rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
    right: 1.858rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    background: transparent;
    color: #323130;
    padding: .429rem .857rem
}

.p-input-filled .p-treeselect {
    background: #4b4b4b
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus,
.p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #4b4b4b
}

.p-togglebutton.p-button {
    background: #252525;
    border: 1px solid #252525;
    color: #323130;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
    color: #888
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #4c4c4c;
    border-color: #4c4c4c;
    color: #323130
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #323130
}

.p-togglebutton.p-button.p-highlight {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #fff
}

.p-togglebutton.p-button.p-highlight:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #fff
}

.p-togglebutton.p-button.p-invalid>.p-button {
    border-color: #e57373
}

.p-button {
    background: #d0021b;
    border: 1px solid #d0021b;
    border-radius: 3px;
    color: #fff;
    font-size: 1rem;
    padding: .429rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-buttonbutton:enabled:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.p-buttonbutton:enabled:active {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-button:not(button):not(.p-disabled):hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.p-button:not(button):not(.p-disabled):active {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-buttona:enabled:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.p-buttona:enabled:active {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-button:not(a):not(.p-disabled):hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.p-button:not(a):not(.p-disabled):active {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-button.p-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlinedbutton:enabled:hover {
    background: rgba(244, 143, 177, .04);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlinedbutton:enabled:active {
    background: rgba(244, 143, 177, .16);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(244, 143, 177, .04);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(244, 143, 177, .16);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlineda:enabled:hover {
    background: rgba(244, 143, 177, .04);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlineda:enabled:active {
    background: rgba(244, 143, 177, .16);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(244, 143, 177, .04);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(244, 143, 177, .16);
    border: 1px solid;
    color: #d0021b
}

.p-button.p-button-outlined.p-button-plain {
    border-color: #888;
    color: #888
}

.p-button.p-button-outlined.p-button-plainbutton:enabled:hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-outlined.p-button-plainbutton:enabled:active {
    background: #3c3c3c;
    color: #888
}

.p-button.p-button-outlined.p-button-plain:not(button):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-outlined.p-button-plain:not(button):not(.p-disabled):active {
    background: #3c3c3c;
    color: #888
}

.p-button.p-button-outlined.p-button-plaina:enabled:hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-outlined.p-button-plaina:enabled:active {
    background: #3c3c3c;
    color: #888
}

.p-button.p-button-outlined.p-button-plain:not(a):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-outlined.p-button-plain:not(a):not(.p-disabled):active {
    background: #3c3c3c;
    color: #888
}

.p-button.p-button-text {
    background-color: transparent;
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-textbutton:enabled:hover {
    background: rgba(244, 143, 177, .04);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-textbutton:enabled:active {
    background: rgba(244, 143, 177, .16);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(244, 143, 177, .04);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(244, 143, 177, .16);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-texta:enabled:hover {
    background: rgba(244, 143, 177, .04);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-texta:enabled:active {
    background: rgba(244, 143, 177, .16);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(244, 143, 177, .04);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(244, 143, 177, .16);
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-text.p-button-plain {
    color: #888
}

.p-button.p-button-text.p-button-plainbutton:enabled:hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-text.p-button-plainbutton:enabled:active {
    background: #3c3c3c;
    color: #888
}

.p-button.p-button-text.p-button-plain:not(button):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-text.p-button-plain:not(button):not(.p-disabled):active {
    background: #3c3c3c;
    color: #888
}

.p-button.p-button-text.p-button-plaina:enabled:hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-text.p-button-plaina:enabled:active {
    background: #3c3c3c;
    color: #888
}

.p-button.p-button-text.p-button-plain:not(a):not(.p-disabled):hover {
    background: #4c4c4c;
    color: #888
}

.p-button.p-button-text.p-button-plain:not(a):not(.p-disabled):active {
    background: #3c3c3c;
    color: #888
}

.p-button:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-button .p-button-icon-left {
    margin-right: .5rem
}

.p-button .p-button-icon-right {
    margin-left: .5rem
}

.p-button .p-button-icon-bottom {
    margin-top: .5rem
}

.p-button .p-button-icon-top {
    margin-bottom: .5rem
}

.p-button .p-badge {
    background-color: #fff;
    color: #d0021b;
    height: 1rem;
    line-height: 1rem;
    margin-left: .5rem;
    min-width: 1rem
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.p-button.p-button-rounded {
    border-radius: 2rem
}

.p-button.p-button-icon-only {
    padding: .429rem 0;
    width: 2.357rem
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    margin: 0
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem
}

.p-button.p-button-sm {
    font-size: .875rem;
    padding: .375375rem .875rem
}

.p-button.p-button-sm .p-button-icon {
    font-size: .875rem
}

.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: .53625rem 1.25rem
}

.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem
}

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
    margin-left: .5rem
}

.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
    margin-right: .5rem
}

.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
    margin-top: .5rem
}

.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
    margin-bottom: .5rem
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
    margin: 0
}

.p-fluid .p-button {
    width: 100%
}

.p-fluid .p-button-icon-only {
    width: 2.357rem
}

.p-fluid .p-buttonset {
    display: flex
}

.p-fluid .p-buttonset .p-button {
    flex: 1
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary>.p-button,
.p-fileupload-choose.p-button-secondary,
.p-splitbutton.p-button-secondary>.p-button {
    background: #b0bec5;
    border: 1px solid #b0bec5;
    color: #121212
}

.p-button.p-button-secondarybutton:enabled:hover,
.p-buttonset.p-button-secondary>.p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-secondarybutton:enabled:hover,
.p-splitbutton.p-button-secondary>.p-buttonbutton:enabled:hover {
    background: #90a4ae;
    border-color: #90a4ae;
    color: #121212
}

.p-button.p-button-secondarybutton:enabled:focus,
.p-buttonset.p-button-secondary>.p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-secondarybutton:enabled:focus,
.p-splitbutton.p-button-secondary>.p-buttonbutton:enabled:focus {
    box-shadow: 0 0 0 .1rem #cfd8dc
}

.p-button.p-button-secondarybutton:enabled:active,
.p-buttonset.p-button-secondary>.p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-secondarybutton:enabled:active,
.p-splitbutton.p-button-secondary>.p-buttonbutton:enabled:active {
    background: #78909c;
    border-color: #78909c;
    color: #121212
}

.p-button.p-button-secondary:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(.p-disabled):hover {
    background: #90a4ae;
    border-color: #90a4ae;
    color: #121212
}

.p-button.p-button-secondary:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-secondary>.p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #cfd8dc
}

.p-button.p-button-secondary:not(button):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(.p-disabled):active {
    background: #78909c;
    border-color: #78909c;
    color: #121212
}

.p-button.p-button-secondarya:enabled:hover,
.p-buttonset.p-button-secondary>.p-buttona:enabled:hover,
.p-fileupload-choose.p-button-secondarya:enabled:hover,
.p-splitbutton.p-button-secondary>.p-buttona:enabled:hover {
    background: #90a4ae;
    border-color: #90a4ae;
    color: #121212
}

.p-button.p-button-secondarya:enabled:focus,
.p-buttonset.p-button-secondary>.p-buttona:enabled:focus,
.p-fileupload-choose.p-button-secondarya:enabled:focus,
.p-splitbutton.p-button-secondary>.p-buttona:enabled:focus {
    box-shadow: 0 0 0 .1rem #cfd8dc
}

.p-button.p-button-secondarya:enabled:active,
.p-buttonset.p-button-secondary>.p-buttona:enabled:active,
.p-fileupload-choose.p-button-secondarya:enabled:active,
.p-splitbutton.p-button-secondary>.p-buttona:enabled:active {
    background: #78909c;
    border-color: #78909c;
    color: #121212
}

.p-button.p-button-secondary:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button:not(a):not(.p-disabled):hover {
    background: #90a4ae;
    border-color: #90a4ae;
    color: #121212
}

.p-button.p-button-secondary:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary>.p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary>.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #cfd8dc
}

.p-button.p-button-secondary:not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button:not(a):not(.p-disabled):active {
    background: #78909c;
    border-color: #78909c;
    color: #121212
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlinedbutton:enabled:hover {
    background: rgba(176, 190, 197, .04);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlinedbutton:enabled:active {
    background: rgba(176, 190, 197, .16);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(176, 190, 197, .04);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(176, 190, 197, .16);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlineda:enabled:hover {
    background: rgba(176, 190, 197, .04);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlineda:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlineda:enabled:active {
    background: rgba(176, 190, 197, .16);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(176, 190, 197, .04);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(176, 190, 197, .16);
    border: 1px solid;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary>.p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text,
.p-splitbutton.p-button-secondary>.p-button.p-button-text {
    background-color: transparent;
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-textbutton:enabled:hover {
    background: rgba(176, 190, 197, .04);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-textbutton:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-textbutton:enabled:active {
    background: rgba(176, 190, 197, .16);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(176, 190, 197, .04);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(176, 190, 197, .16);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-texta:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-texta:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-texta:enabled:hover {
    background: rgba(176, 190, 197, .04);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-texta:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-texta:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-texta:enabled:active {
    background: rgba(176, 190, 197, .16);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(176, 190, 197, .04);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(176, 190, 197, .16);
    border-color: transparent;
    color: #b0bec5
}

.p-button.p-button-info,
.p-buttonset.p-button-info>.p-button,
.p-fileupload-choose.p-button-info,
.p-splitbutton.p-button-info>.p-button {
    background: #4fc3f7;
    border: 1px solid #4fc3f7;
    color: #fff
}

.p-button.p-button-infobutton:enabled:hover,
.p-buttonset.p-button-info>.p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-infobutton:enabled:hover,
.p-splitbutton.p-button-info>.p-buttonbutton:enabled:hover {
    background: #29b6f6;
    border-color: #29b6f6;
    color: #fff
}

.p-button.p-button-infobutton:enabled:focus,
.p-buttonset.p-button-info>.p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-infobutton:enabled:focus,
.p-splitbutton.p-button-info>.p-buttonbutton:enabled:focus {
    box-shadow: 0 0 0 .1rem #e1f5fe
}

.p-button.p-button-infobutton:enabled:active,
.p-buttonset.p-button-info>.p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-infobutton:enabled:active,
.p-splitbutton.p-button-info>.p-buttonbutton:enabled:active {
    background: #03a9f4;
    border-color: #03a9f4;
    color: #fff
}

.p-button.p-button-info:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button:not(button):not(.p-disabled):hover {
    background: #29b6f6;
    border-color: #29b6f6;
    color: #fff
}

.p-button.p-button-info:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-info>.p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-info>.p-button:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #e1f5fe
}

.p-button.p-button-info:not(button):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button:not(button):not(.p-disabled):active {
    background: #03a9f4;
    border-color: #03a9f4;
    color: #fff
}

.p-button.p-button-infoa:enabled:hover,
.p-buttonset.p-button-info>.p-buttona:enabled:hover,
.p-fileupload-choose.p-button-infoa:enabled:hover,
.p-splitbutton.p-button-info>.p-buttona:enabled:hover {
    background: #29b6f6;
    border-color: #29b6f6;
    color: #fff
}

.p-button.p-button-infoa:enabled:focus,
.p-buttonset.p-button-info>.p-buttona:enabled:focus,
.p-fileupload-choose.p-button-infoa:enabled:focus,
.p-splitbutton.p-button-info>.p-buttona:enabled:focus {
    box-shadow: 0 0 0 .1rem #e1f5fe
}

.p-button.p-button-infoa:enabled:active,
.p-buttonset.p-button-info>.p-buttona:enabled:active,
.p-fileupload-choose.p-button-infoa:enabled:active,
.p-splitbutton.p-button-info>.p-buttona:enabled:active {
    background: #03a9f4;
    border-color: #03a9f4;
    color: #fff
}

.p-button.p-button-info:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button:not(a):not(.p-disabled):hover {
    background: #29b6f6;
    border-color: #29b6f6;
    color: #fff
}

.p-button.p-button-info:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-info>.p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-info>.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #e1f5fe
}

.p-button.p-button-info:not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button:not(a):not(.p-disabled):active {
    background: #03a9f4;
    border-color: #03a9f4;
    color: #fff
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-info.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlinedbutton:enabled:hover {
    background: rgba(79, 195, 247, .04);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlinedbutton:enabled:active {
    background: rgba(79, 195, 247, .16);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(79, 195, 247, .04);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(79, 195, 247, .16);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlineda:enabled:hover {
    background: rgba(79, 195, 247, .04);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlineda:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlineda:enabled:active {
    background: rgba(79, 195, 247, .16);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(79, 195, 247, .04);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(79, 195, 247, .16);
    border: 1px solid;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info>.p-button.p-button-text,
.p-fileupload-choose.p-button-info.p-button-text,
.p-splitbutton.p-button-info>.p-button.p-button-text {
    background-color: transparent;
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-textbutton:enabled:hover {
    background: rgba(79, 195, 247, .04);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-textbutton:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-info.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-textbutton:enabled:active {
    background: rgba(79, 195, 247, .16);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(79, 195, 247, .04);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(79, 195, 247, .16);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-texta:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-texta:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-texta:enabled:hover {
    background: rgba(79, 195, 247, .04);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-texta:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-info.p-button-texta:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-texta:enabled:active {
    background: rgba(79, 195, 247, .16);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(79, 195, 247, .04);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-info.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(79, 195, 247, .16);
    border-color: transparent;
    color: #4fc3f7
}

.p-button.p-button-success,
.p-buttonset.p-button-success>.p-button,
.p-fileupload-choose.p-button-success,
.p-splitbutton.p-button-success>.p-button {
    background: #aed581;
    border: 1px solid #aed581;
    color: #fff
}

.p-button.p-button-successbutton:enabled:hover,
.p-buttonset.p-button-success>.p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-successbutton:enabled:hover,
.p-splitbutton.p-button-success>.p-buttonbutton:enabled:hover {
    background: #9ccc65;
    border-color: #9ccc65;
    color: #fff
}

.p-button.p-button-successbutton:enabled:focus,
.p-buttonset.p-button-success>.p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-successbutton:enabled:focus,
.p-splitbutton.p-button-success>.p-buttonbutton:enabled:focus {
    box-shadow: 0 0 0 .1rem #f1f8e9
}

.p-button.p-button-successbutton:enabled:active,
.p-buttonset.p-button-success>.p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-successbutton:enabled:active,
.p-splitbutton.p-button-success>.p-buttonbutton:enabled:active {
    background: #8bc34a;
    border-color: #8bc34a;
    color: #fff
}

.p-button.p-button-success:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button:not(button):not(.p-disabled):hover {
    background: #9ccc65;
    border-color: #9ccc65;
    color: #fff
}

.p-button.p-button-success:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-success>.p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-success>.p-button:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #f1f8e9
}

.p-button.p-button-success:not(button):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button:not(button):not(.p-disabled):active {
    background: #8bc34a;
    border-color: #8bc34a;
    color: #fff
}

.p-button.p-button-successa:enabled:hover,
.p-buttonset.p-button-success>.p-buttona:enabled:hover,
.p-fileupload-choose.p-button-successa:enabled:hover,
.p-splitbutton.p-button-success>.p-buttona:enabled:hover {
    background: #9ccc65;
    border-color: #9ccc65;
    color: #fff
}

.p-button.p-button-successa:enabled:focus,
.p-buttonset.p-button-success>.p-buttona:enabled:focus,
.p-fileupload-choose.p-button-successa:enabled:focus,
.p-splitbutton.p-button-success>.p-buttona:enabled:focus {
    box-shadow: 0 0 0 .1rem #f1f8e9
}

.p-button.p-button-successa:enabled:active,
.p-buttonset.p-button-success>.p-buttona:enabled:active,
.p-fileupload-choose.p-button-successa:enabled:active,
.p-splitbutton.p-button-success>.p-buttona:enabled:active {
    background: #8bc34a;
    border-color: #8bc34a;
    color: #fff
}

.p-button.p-button-success:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button:not(a):not(.p-disabled):hover {
    background: #9ccc65;
    border-color: #9ccc65;
    color: #fff
}

.p-button.p-button-success:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success>.p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success>.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #f1f8e9
}

.p-button.p-button-success:not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button:not(a):not(.p-disabled):active {
    background: #8bc34a;
    border-color: #8bc34a;
    color: #fff
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined,
.p-splitbutton.p-button-success>.p-button.p-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlinedbutton:enabled:hover {
    background: hsla(88, 50%, 67%, .04);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-outlinedbutton:enabled:active {
    background: hsla(88, 50%, 67%, .16);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: hsla(88, 50%, 67%, .04);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: hsla(88, 50%, 67%, .16);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlineda:enabled:hover {
    background: hsla(88, 50%, 67%, .04);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlineda:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-outlineda:enabled:active {
    background: hsla(88, 50%, 67%, .16);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: hsla(88, 50%, 67%, .04);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: hsla(88, 50%, 67%, .16);
    border: 1px solid;
    color: #aed581
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success>.p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text,
.p-splitbutton.p-button-success>.p-button.p-button-text {
    background-color: transparent;
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-textbutton:enabled:hover {
    background: hsla(88, 50%, 67%, .04);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-textbutton:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-success.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-textbutton:enabled:active {
    background: hsla(88, 50%, 67%, .16);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: hsla(88, 50%, 67%, .04);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: hsla(88, 50%, 67%, .16);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-texta:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-texta:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-texta:enabled:hover {
    background: hsla(88, 50%, 67%, .04);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-texta:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-success.p-button-texta:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-texta:enabled:active {
    background: hsla(88, 50%, 67%, .16);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: hsla(88, 50%, 67%, .04);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-success.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: hsla(88, 50%, 67%, .16);
    border-color: transparent;
    color: #aed581
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning>.p-button,
.p-fileupload-choose.p-button-warning,
.p-splitbutton.p-button-warning>.p-button {
    background: #ffb74d;
    border: 1px solid #ffb74d;
    color: #fff
}

.p-button.p-button-warningbutton:enabled:hover,
.p-buttonset.p-button-warning>.p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-warningbutton:enabled:hover,
.p-splitbutton.p-button-warning>.p-buttonbutton:enabled:hover {
    background: #ffa726;
    border-color: #ffa726;
    color: #fff
}

.p-button.p-button-warningbutton:enabled:focus,
.p-buttonset.p-button-warning>.p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-warningbutton:enabled:focus,
.p-splitbutton.p-button-warning>.p-buttonbutton:enabled:focus {
    box-shadow: 0 0 0 .1rem #fffde7
}

.p-button.p-button-warningbutton:enabled:active,
.p-buttonset.p-button-warning>.p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-warningbutton:enabled:active,
.p-splitbutton.p-button-warning>.p-buttonbutton:enabled:active {
    background: #ff9800;
    border-color: #ff9800;
    color: #fff
}

.p-button.p-button-warning:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button:not(button):not(.p-disabled):hover {
    background: #ffa726;
    border-color: #ffa726;
    color: #fff
}

.p-button.p-button-warning:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-warning>.p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-warning>.p-button:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #fffde7
}

.p-button.p-button-warning:not(button):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button:not(button):not(.p-disabled):active {
    background: #ff9800;
    border-color: #ff9800;
    color: #fff
}

.p-button.p-button-warninga:enabled:hover,
.p-buttonset.p-button-warning>.p-buttona:enabled:hover,
.p-fileupload-choose.p-button-warninga:enabled:hover,
.p-splitbutton.p-button-warning>.p-buttona:enabled:hover {
    background: #ffa726;
    border-color: #ffa726;
    color: #fff
}

.p-button.p-button-warninga:enabled:focus,
.p-buttonset.p-button-warning>.p-buttona:enabled:focus,
.p-fileupload-choose.p-button-warninga:enabled:focus,
.p-splitbutton.p-button-warning>.p-buttona:enabled:focus {
    box-shadow: 0 0 0 .1rem #fffde7
}

.p-button.p-button-warninga:enabled:active,
.p-buttonset.p-button-warning>.p-buttona:enabled:active,
.p-fileupload-choose.p-button-warninga:enabled:active,
.p-splitbutton.p-button-warning>.p-buttona:enabled:active {
    background: #ff9800;
    border-color: #ff9800;
    color: #fff
}

.p-button.p-button-warning:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button:not(a):not(.p-disabled):hover {
    background: #ffa726;
    border-color: #ffa726;
    color: #fff
}

.p-button.p-button-warning:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-warning>.p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-warning>.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #fffde7
}

.p-button.p-button-warning:not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button:not(a):not(.p-disabled):active {
    background: #ff9800;
    border-color: #ff9800;
    color: #fff
}

.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-warning.p-button-outlined,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlinedbutton:enabled:hover {
    background: rgba(255, 183, 77, .04);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlinedbutton:enabled:active {
    background: rgba(255, 183, 77, .16);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(255, 183, 77, .04);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(255, 183, 77, .16);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlineda:enabled:hover {
    background: rgba(255, 183, 77, .04);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlineda:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlineda:enabled:active {
    background: rgba(255, 183, 77, .16);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(255, 183, 77, .04);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(255, 183, 77, .16);
    border: 1px solid;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning>.p-button.p-button-text,
.p-fileupload-choose.p-button-warning.p-button-text,
.p-splitbutton.p-button-warning>.p-button.p-button-text {
    background-color: transparent;
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-textbutton:enabled:hover {
    background: rgba(255, 183, 77, .04);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-textbutton:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-textbutton:enabled:active {
    background: rgba(255, 183, 77, .16);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(255, 183, 77, .04);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(255, 183, 77, .16);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-texta:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-texta:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-texta:enabled:hover {
    background: rgba(255, 183, 77, .04);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-texta:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-texta:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-texta:enabled:active {
    background: rgba(255, 183, 77, .16);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(255, 183, 77, .04);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-warning.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(255, 183, 77, .16);
    border-color: transparent;
    color: #ffb74d
}

.p-button.p-button-help,
.p-buttonset.p-button-help>.p-button,
.p-fileupload-choose.p-button-help,
.p-splitbutton.p-button-help>.p-button {
    background: #ce93d8;
    border: 1px solid #ce93d8;
    color: #121212
}

.p-button.p-button-helpbutton:enabled:hover,
.p-buttonset.p-button-help>.p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-helpbutton:enabled:hover,
.p-splitbutton.p-button-help>.p-buttonbutton:enabled:hover {
    background: #ba68c8;
    border-color: #ba68c8;
    color: #121212
}

.p-button.p-button-helpbutton:enabled:focus,
.p-buttonset.p-button-help>.p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-helpbutton:enabled:focus,
.p-splitbutton.p-button-help>.p-buttonbutton:enabled:focus {
    box-shadow: 0 0 0 1px #ce93d8
}

.p-button.p-button-helpbutton:enabled:active,
.p-buttonset.p-button-help>.p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-helpbutton:enabled:active,
.p-splitbutton.p-button-help>.p-buttonbutton:enabled:active {
    background: #ab47bc;
    border-color: #ab47bc;
    color: #121212
}

.p-button.p-button-help:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button:not(button):not(.p-disabled):hover {
    background: #ba68c8;
    border-color: #ba68c8;
    color: #121212
}

.p-button.p-button-help:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-help>.p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-help>.p-button:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 1px #ce93d8
}

.p-button.p-button-help:not(button):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button:not(button):not(.p-disabled):active {
    background: #ab47bc;
    border-color: #ab47bc;
    color: #121212
}

.p-button.p-button-helpa:enabled:hover,
.p-buttonset.p-button-help>.p-buttona:enabled:hover,
.p-fileupload-choose.p-button-helpa:enabled:hover,
.p-splitbutton.p-button-help>.p-buttona:enabled:hover {
    background: #ba68c8;
    border-color: #ba68c8;
    color: #121212
}

.p-button.p-button-helpa:enabled:focus,
.p-buttonset.p-button-help>.p-buttona:enabled:focus,
.p-fileupload-choose.p-button-helpa:enabled:focus,
.p-splitbutton.p-button-help>.p-buttona:enabled:focus {
    box-shadow: 0 0 0 1px #ce93d8
}

.p-button.p-button-helpa:enabled:active,
.p-buttonset.p-button-help>.p-buttona:enabled:active,
.p-fileupload-choose.p-button-helpa:enabled:active,
.p-splitbutton.p-button-help>.p-buttona:enabled:active {
    background: #ab47bc;
    border-color: #ab47bc;
    color: #121212
}

.p-button.p-button-help:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button:not(a):not(.p-disabled):hover {
    background: #ba68c8;
    border-color: #ba68c8;
    color: #121212
}

.p-button.p-button-help:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-help>.p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-help>.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 1px #ce93d8
}

.p-button.p-button-help:not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button:not(a):not(.p-disabled):active {
    background: #ab47bc;
    border-color: #ab47bc;
    color: #121212
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-help.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlinedbutton:enabled:hover {
    background: rgba(206, 147, 216, .04);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-outlinedbutton:enabled:active {
    background: rgba(206, 147, 216, .16);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(206, 147, 216, .04);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(206, 147, 216, .16);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlineda:enabled:hover {
    background: rgba(206, 147, 216, .04);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlineda:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-outlineda:enabled:active {
    background: rgba(206, 147, 216, .16);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(206, 147, 216, .04);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(206, 147, 216, .16);
    border: 1px solid;
    color: #ce93d8
}

.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help>.p-button.p-button-text,
.p-fileupload-choose.p-button-help.p-button-text,
.p-splitbutton.p-button-help>.p-button.p-button-text {
    background-color: transparent;
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-textbutton:enabled:hover {
    background: rgba(206, 147, 216, .04);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-textbutton:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-help.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-textbutton:enabled:active {
    background: rgba(206, 147, 216, .16);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(206, 147, 216, .04);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(206, 147, 216, .16);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-texta:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-texta:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-texta:enabled:hover {
    background: rgba(206, 147, 216, .04);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-texta:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-help.p-button-texta:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-texta:enabled:active {
    background: rgba(206, 147, 216, .16);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(206, 147, 216, .04);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-help.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(206, 147, 216, .16);
    border-color: transparent;
    color: #ce93d8
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger>.p-button,
.p-fileupload-choose.p-button-danger,
.p-splitbutton.p-button-danger>.p-button {
    background: #e57373;
    border: 1px solid #e57373;
    color: #fff
}

.p-button.p-button-dangerbutton:enabled:hover,
.p-buttonset.p-button-danger>.p-buttonbutton:enabled:hover,
.p-fileupload-choose.p-button-dangerbutton:enabled:hover,
.p-splitbutton.p-button-danger>.p-buttonbutton:enabled:hover {
    background: #ef5350;
    border-color: #ef5350;
    color: #fff
}

.p-button.p-button-dangerbutton:enabled:focus,
.p-buttonset.p-button-danger>.p-buttonbutton:enabled:focus,
.p-fileupload-choose.p-button-dangerbutton:enabled:focus,
.p-splitbutton.p-button-danger>.p-buttonbutton:enabled:focus {
    box-shadow: 0 0 0 .1rem #ffebee
}

.p-button.p-button-dangerbutton:enabled:active,
.p-buttonset.p-button-danger>.p-buttonbutton:enabled:active,
.p-fileupload-choose.p-button-dangerbutton:enabled:active,
.p-splitbutton.p-button-danger>.p-buttonbutton:enabled:active {
    background: #f44336;
    border-color: #f44336;
    color: #fff
}

.p-button.p-button-danger:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button:not(button):not(.p-disabled):hover {
    background: #ef5350;
    border-color: #ef5350;
    color: #fff
}

.p-button.p-button-danger:not(button):not(.p-disabled):focus,
.p-buttonset.p-button-danger>.p-button:not(button):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):focus,
.p-splitbutton.p-button-danger>.p-button:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #ffebee
}

.p-button.p-button-danger:not(button):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button:not(button):not(.p-disabled):active {
    background: #f44336;
    border-color: #f44336;
    color: #fff
}

.p-button.p-button-dangera:enabled:hover,
.p-buttonset.p-button-danger>.p-buttona:enabled:hover,
.p-fileupload-choose.p-button-dangera:enabled:hover,
.p-splitbutton.p-button-danger>.p-buttona:enabled:hover {
    background: #ef5350;
    border-color: #ef5350;
    color: #fff
}

.p-button.p-button-dangera:enabled:focus,
.p-buttonset.p-button-danger>.p-buttona:enabled:focus,
.p-fileupload-choose.p-button-dangera:enabled:focus,
.p-splitbutton.p-button-danger>.p-buttona:enabled:focus {
    box-shadow: 0 0 0 .1rem #ffebee
}

.p-button.p-button-dangera:enabled:active,
.p-buttonset.p-button-danger>.p-buttona:enabled:active,
.p-fileupload-choose.p-button-dangera:enabled:active,
.p-splitbutton.p-button-danger>.p-buttona:enabled:active {
    background: #f44336;
    border-color: #f44336;
    color: #fff
}

.p-button.p-button-danger:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button:not(a):not(.p-disabled):hover {
    background: #ef5350;
    border-color: #ef5350;
    color: #fff
}

.p-button.p-button-danger:not(a):not(.p-disabled):focus,
.p-buttonset.p-button-danger>.p-button:not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-danger>.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #ffebee
}

.p-button.p-button-danger:not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button:not(a):not(.p-disabled):active {
    background: #f44336;
    border-color: #f44336;
    color: #fff
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined {
    background-color: transparent;
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlinedbutton:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlinedbutton:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlinedbutton:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlinedbutton:enabled:hover {
    background: hsla(0, 69%, 67%, .04);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlinedbutton:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-outlinedbutton:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlinedbutton:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlinedbutton:enabled:active {
    background: hsla(0, 69%, 67%, .16);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlined:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: hsla(0, 69%, 67%, .04);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlined:not(button):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: hsla(0, 69%, 67%, .16);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlineda:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlineda:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlineda:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlineda:enabled:hover {
    background: hsla(0, 69%, 67%, .04);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlineda:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-outlineda:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlineda:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlineda:enabled:active {
    background: hsla(0, 69%, 67%, .16);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: hsla(0, 69%, 67%, .04);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-outlined:not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: hsla(0, 69%, 67%, .16);
    border: 1px solid;
    color: #e57373
}

.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger>.p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text,
.p-splitbutton.p-button-danger>.p-button.p-button-text {
    background-color: transparent;
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-textbutton:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-textbutton:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-textbutton:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-textbutton:enabled:hover {
    background: hsla(0, 69%, 67%, .04);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-textbutton:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-textbutton:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-textbutton:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-textbutton:enabled:active {
    background: hsla(0, 69%, 67%, .16);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-text:not(button):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(button):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: hsla(0, 69%, 67%, .04);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-text:not(button):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(button):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: hsla(0, 69%, 67%, .16);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-texta:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-texta:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-texta:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-texta:enabled:hover {
    background: hsla(0, 69%, 67%, .04);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-texta:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-texta:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-texta:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-texta:enabled:active {
    background: hsla(0, 69%, 67%, .16);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-text:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: hsla(0, 69%, 67%, .04);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-danger.p-button-text:not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: hsla(0, 69%, 67%, .16);
    border-color: transparent;
    color: #e57373
}

.p-button.p-button-link {
    background: transparent;
    border: transparent;
    color: #d0021b
}

.p-button.p-button-linkbutton:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-linkbutton:enabled:hover .p-button-label {
    text-decoration: underline
}

.p-button.p-button-linkbutton:enabled:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 .2rem #fff
}

.p-button.p-button-link:not(button):not(.p-disabled):hover,
.p-button.p-button-linkbutton:enabled:active {
    background: transparent;
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-link:not(button):not(.p-disabled):hover .p-button-label {
    text-decoration: underline
}

.p-button.p-button-link:not(button):not(.p-disabled):focus {
    background: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 .2rem #fff
}

.p-button.p-button-link:not(button):not(.p-disabled):active,
.p-button.p-button-linka:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-linka:enabled:hover .p-button-label {
    text-decoration: underline
}

.p-button.p-button-linka:enabled:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 .2rem #fff
}

.p-button.p-button-link:not(a):not(.p-disabled):hover,
.p-button.p-button-linka:enabled:active {
    background: transparent;
    border-color: transparent;
    color: #d0021b
}

.p-button.p-button-link:not(a):not(.p-disabled):hover .p-button-label {
    text-decoration: underline
}

.p-button.p-button-link:not(a):not(.p-disabled):focus {
    background: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 .2rem #fff
}

.p-button.p-button-link:not(a):not(.p-disabled):active {
    background: transparent;
    border-color: transparent;
    color: #d0021b
}

.p-carousel .p-carousel-content .p-carousel-next,
.p-carousel .p-carousel-content .p-carousel-prev {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-carousel .p-carousel-content .p-carousel-next:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-carousel .p-carousel-content .p-carousel-next:focus,
.p-carousel .p-carousel-content .p-carousel-prev:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-carousel .p-carousel-indicators {
    padding: 1rem
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-bottom: .5rem;
    margin-right: .5rem
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #4b4b4b;
    border-radius: 0;
    height: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #4c4c4c
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #d0021b;
    color: #fff
}

.p-datatable .p-paginator-top {
    border-radius: 0;
    border-width: 1px 1px 0
}

.p-datatable .p-paginator-bottom {
    border-radius: 0;
    border-width: 0 1px 1px
}

.p-datatable .p-datatable-header {
    background: #5d88d6;
    border: solid #5d88d6;
    border-width: 1px 1px 0;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem
}

.p-datatable .p-datatable-footer {
    background: #fff;
    border: 1px solid #5d88d6;
    border-top: 0 solid #5d88d6;
    color: #323130;
    font-weight: 400;
    padding: .571rem 1rem
}

.p-datatable .p-datatable-thead>tr>th {
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-datatable .p-datatable-tfoot>tr>td,
.p-datatable .p-datatable-thead>tr>th {
    background: #252525;
    border: 1px solid #5d88d6;
    color: #323130;
    font-weight: 700;
    padding: .571rem .857rem;
    text-align: left
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #888;
    margin-left: .5rem
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    background: #d0021b;
    border-radius: 50%;
    color: #fff;
    height: 1.143rem;
    line-height: 1.143rem;
    margin-left: .5rem;
    min-width: 1.143rem
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #4c4c4c;
    color: #323130
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #323130
}

.p-datatable .p-sortable-column.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #fff
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #d0021b;
    color: #fff
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #fff
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none
}

.p-datatable .p-datatable-tbody>tr {
    background: #fff;
    color: #323130;
    outline-color: #fff;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #5d88d6;
    outline-color: #fff;
    padding: .571rem .857rem;
    text-align: left
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-toggler {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
    margin-right: .5rem
}

.p-datatable .p-datatable-tbody>tr.p-highlight,
.p-datatable .p-datatable-tbody>tr>td.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
    box-shadow: inset 0 2px 0 0 #d0021b
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
    box-shadow: inset 0 -2px 0 0 #d0021b
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #323130
}

.p-datatable .p-column-resizer-helper {
    background: #d0021b
}

.p-datatable .p-datatable-scrollable-footer,
.p-datatable .p-datatable-scrollable-header {
    background: #5d88d6
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2rem
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td,
.p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td,
.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 1px
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
    background: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler,
.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd+.p-row-expanded {
    background: #fff
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: .72845rem .85rem
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td,
.p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: .48535rem .72845rem
}

.p-datatable.p-datatable-sm .p-datatable-footer,
.p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td {
    padding: .48535rem .85rem
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.07125rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td,
.p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
    padding: .71375rem 1.07125rem
}

.p-datatable.p-datatable-lg .p-datatable-footer,
.p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
    padding: .71375rem 1.25rem
}

.p-datatable-drag-selection-helper {
    background: rgba(244, 143, 177, .16)
}

.p-dataview .p-paginator-top {
    border-radius: 0;
    border-width: 1px 1px 0
}

.p-dataview .p-paginator-bottom {
    border-radius: 0;
    border-width: 0 1px 1px
}

.p-dataview .p-dataview-header {
    background: #5d88d6;
    border: solid #5d88d6;
    border-width: 1px 1px 0;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem
}

.p-dataview .p-dataview-content {
    background: #fff;
    border: 0;
    color: #323130;
    padding: 0
}

.p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
    border: 1px solid #4b4b4b
}

.p-dataview .p-dataview-footer {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 0 solid #5d88d6;
    color: #323130;
    font-weight: 400;
    padding: .571rem 1rem
}

.p-dataview .p-dataview-loading-icon {
    font-size: 2rem
}

.p-datascroller .p-paginator-top {
    border-radius: 0;
    border-width: 1px 1px 0
}

.p-datascroller .p-paginator-bottom {
    border-radius: 0;
    border-width: 0 1px 1px
}

.p-datascroller .p-datascroller-header {
    background: #5d88d6;
    border: solid #5d88d6;
    border-width: 1px 1px 0;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem
}

.p-datascroller .p-datascroller-content {
    background: #fff;
    border: 0;
    color: #323130;
    padding: 0
}

.p-datascroller.p-datascroller-inline .p-datascroller-list>li {
    border: 1px solid #4b4b4b
}

.p-datascroller .p-datascroller-footer {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 0 solid #5d88d6;
    color: #323130;
    font-weight: 400;
    padding: .571rem 1rem
}

.fc.fc-unthemed .fc-view-container th {
    background: #5d88d6
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content,
.fc.fc-unthemed .fc-view-container th {
    border: 1px solid #5d88d6;
    color: #323130
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
    border: 1px solid #5d88d6
}

.fc.fc-unthemed .fc-view-container .fc-view {
    background: #fff
}

.fc.fc-unthemed .fc-view-container .fc-row {
    border-right: 1px solid #5d88d6
}

.fc.fc-unthemed .fc-view-container .fc-event {
    background: #f06292;
    border: 1px solid #f06292;
    color: #fff
}

.fc.fc-unthemed .fc-view-container .fc-divider {
    background: #5d88d6;
    border: 1px solid #5d88d6
}

.fc.fc-unthemed .fc-toolbar .fc-button {
    align-items: center;
    background: #d0021b;
    border: 1px solid #d0021b;
    border-radius: 3px;
    color: #fff;
    display: flex;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: PrimeIcons !important;
    font-size: 1rem;
    text-indent: 0
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: ""
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: PrimeIcons !important;
    font-size: 1rem;
    text-indent: 0
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: ""
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button {
    background: #252525;
    border: 1px solid #252525;
    color: #323130;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover {
    background: #4c4c4c;
    border-color: #4c4c4c;
    color: #323130
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0;
    z-index: 1
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: #5d88d6
}

.fc.fc-theme-standard .fc-view-harness th {
    background: #5d88d6;
    border-color: #5d88d6;
    color: #323130
}

.fc.fc-theme-standard .fc-view-harness td {
    border-color: #5d88d6;
    color: #323130
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
    background: #fff
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
    background: none;
    border: 0
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    color: #323130;
    padding: .857rem 1rem
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
    align-items: center;
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    display: flex;
    font-family: PrimeIcons !important;
    font-size: 1rem;
    height: 2rem;
    justify-content: center;
    opacity: 1;
    overflow: hidden;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
    content: ""
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
    background: #fff;
    border: 1px solid #5d88d6;
    border-top: 0;
    color: #323130;
    padding: .571rem 1rem
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
    color: #fff
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
    background: #f06292;
    border-color: #f06292
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
    background: #4c4c4c;
    color: #323130
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
    background: #5d88d6
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
    background: #d0021b;
    border: 1px solid #d0021b;
    border-radius: 3px;
    color: #fff;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    background: #d0021b;
    border: 1px solid #d0021b;
    color: #fff;
    opacity: .5
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: PrimeIcons !important;
    font-size: 1rem;
    text-indent: 0
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: ""
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: PrimeIcons !important;
    font-size: 1rem;
    text-indent: 0
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: ""
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button {
    background: #252525;
    border: 1px solid #252525;
    color: #323130;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover {
    background: #4c4c4c;
    border-color: #4c4c4c;
    color: #323130
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover {
    background: #f06292;
    border-color: #f06292;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0;
    z-index: 1
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px
}

.p-orderlist .p-orderlist-controls {
    padding: .571rem 1rem
}

.p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: .5rem
}

.p-orderlist .p-orderlist-header {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem
}

.p-orderlist .p-orderlist-list {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #323130;
    padding: 0
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
    background: transparent;
    border: 0;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem;
    transition: transform .2s, background-color .2s, border-color .2s, box-shadow .2s
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #323130
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    background: #d0021b;
    color: #fff
}

@media screen and (max-width:960px) {
    .p-orderlist {
        flex-direction: column
    }

    .p-orderlist .p-orderlist-controls {
        flex-direction: row;
        padding: .571rem 1rem
    }

    .p-orderlist .p-orderlist-controls .p-button {
        margin-bottom: 0;
        margin-right: .5rem
    }

    .p-orderlist .p-orderlist-controls .p-button:last-child {
        margin-right: 0
    }
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #323130
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #e91b60
}

.p-organizationchart .p-organizationchart-line-down {
    background: #5d88d6
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid;
    border-color: #5d88d6
}

.p-organizationchart .p-organizationchart-line-top {
    border-color: #5d88d6;
    border-top: 1px solid #5d88d6
}

.p-organizationchart .p-organizationchart-node-content {
    background: #fff;
    border: 1px solid #5d88d6;
    color: #323130;
    padding: .571rem 1rem
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    border-radius: 50%;
    color: inherit
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-paginator {
    background: #252525;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    padding: 0
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-prev {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #323130;
    height: 2.357rem;
    margin: 0;
    min-width: 2.357rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover {
    background: #e0e0e0;
    border-color: transparent;
    color: #4c4c4c
}

.p-paginator .p-paginator-first {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}

.p-paginator .p-paginator-last {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px
}

.p-paginator .p-dropdown {
    height: 2.357rem;
    margin-left: .5rem
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0
}

.p-paginator .p-paginator-current {
    padding: 0 .5rem
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0;
    color: #323130;
    height: 2.357rem;
    margin: 0;
    min-width: 2.357rem
}

.p-paginator .p-paginator-pages .p-paginator-page {
    border-radius: 0;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #e0e0e0;
    border-color: transparent;
    color: #4c4c4c
}

.p-picklist .p-picklist-buttons {
    padding: .571rem 1rem
}

.p-picklist .p-picklist-buttons .p-button {
    margin-bottom: .5rem
}

.p-picklist .p-picklist-header {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem
}

.p-picklist .p-picklist-list {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #323130;
    padding: 0
}

.p-picklist .p-picklist-list .p-picklist-item {
    background: transparent;
    border: 0;
    color: #323130;
    margin: 0;
    padding: .429rem .857rem;
    transition: transform .2s, background-color .2s, border-color .2s, box-shadow .2s
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #323130
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    background: #d0021b;
    color: #fff
}

@media screen and (max-width:960px) {
    .p-picklist {
        flex-direction: column
    }

    .p-picklist .p-picklist-buttons {
        flex-direction: row;
        padding: .571rem 1rem
    }

    .p-picklist .p-picklist-buttons .p-button {
        margin-bottom: 0;
        margin-right: .5rem
    }

    .p-picklist .p-picklist-buttons .p-button:last-child {
        margin-right: 0
    }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
        content: ""
    }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
        content: ""
    }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
        content: ""
    }

    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
        content: ""
    }
}

.p-tree {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    padding: .571rem 1rem
}

.p-tree .p-tree-container .p-treenode {
    padding: .143rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 3px;
    padding: 0;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    margin-right: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    color: #888;
    margin-right: .5rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #323130
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #323130
}

.p-tree .p-tree-filter-container {
    margin-bottom: .5rem
}

.p-tree .p-tree-filter-container .p-tree-filter {
    padding-right: 1.429rem;
    width: 100%
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    color: #888;
    right: .429rem
}

.p-tree .p-treenode-children {
    padding: 0 0 0 1rem
}

.p-tree .p-tree-loading-icon {
    font-size: 2rem
}

.p-treetable .p-paginator-top {
    border-radius: 0;
    border-width: 1px 1px 0
}

.p-treetable .p-paginator-bottom {
    border-radius: 0;
    border-width: 0 1px 1px
}

.p-treetable .p-treetable-header {
    background: #5d88d6;
    border: solid #5d88d6;
    border-width: 1px 1px 0;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem
}

.p-treetable .p-treetable-footer {
    background: #fff;
    border: 1px solid #5d88d6;
    border-top: 0 solid #5d88d6;
    color: #323130;
    font-weight: 400;
    padding: .571rem 1rem
}

.p-treetable .p-treetable-thead>tr>th {
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-treetable .p-treetable-tfoot>tr>td,
.p-treetable .p-treetable-thead>tr>th {
    background: #252525;
    border: 1px solid #5d88d6;
    color: #323130;
    font-weight: 700;
    padding: .571rem .857rem;
    text-align: left
}

.p-treetable .p-sortable-column {
    outline-color: #fff
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #888;
    margin-left: .5rem
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
    background: #d0021b;
    border-radius: 50%;
    color: #fff;
    height: 1.143rem;
    line-height: 1.143rem;
    margin-left: .5rem;
    min-width: 1.143rem
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #323130
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #323130
}

.p-treetable .p-sortable-column.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #fff
}

.p-treetable .p-treetable-tbody>tr {
    background: #fff;
    color: #323130;
    outline-color: #fff;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-treetable .p-treetable-tbody>tr>td {
    border: 1px solid #5d88d6;
    padding: .571rem .857rem;
    text-align: left
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    margin-right: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox {
    margin-right: .5rem
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #323130
}

.p-treetable .p-treetable-tbody>tr.p-highlight {
    background: #d0021b;
    color: #fff
}

.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler,
.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover {
    color: #fff
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #323130
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #323130
}

.p-treetable .p-column-resizer-helper {
    background: #d0021b
}

.p-treetable .p-treetable-scrollable-footer,
.p-treetable .p-treetable-scrollable-header {
    background: #5d88d6
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2rem
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td,
.p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td,
.p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th {
    border-width: 1px
}

.p-treetable.p-treetable-sm .p-treetable-header {
    padding: .749875rem .875rem
}

.p-treetable.p-treetable-sm .p-treetable-tbody>tr>td,
.p-treetable.p-treetable-sm .p-treetable-thead>tr>th {
    padding: .48535rem .72845rem
}

.p-treetable.p-treetable-sm .p-treetable-footer,
.p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td {
    padding: .48535rem .85rem
}

.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.07125rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-tbody>tr>td,
.p-treetable.p-treetable-lg .p-treetable-thead>tr>th {
    padding: .71375rem 1.07125rem
}

.p-treetable.p-treetable-lg .p-treetable-footer,
.p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td {
    padding: .71375rem 1.25rem
}

.p-timeline .p-timeline-event-marker {
    background-color: #d0021b;
    border: 0;
    border-radius: 50%;
    height: 1rem;
    width: 1rem
}

.p-timeline .p-timeline-event-connector {
    background-color: #5d88d6
}

.p-timeline.p-timeline-vertical .p-timeline-event-content,
.p-timeline.p-timeline-vertical .p-timeline-event-opposite {
    padding: 0 1rem
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px
}

.p-timeline.p-timeline-horizontal .p-timeline-event-content,
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite {
    padding: 1rem 0
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: .5rem
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #5d88d6;
    border-color: #5d88d6;
    color: #d0021b
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #d0021b;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #d0021b;
    color: #fff
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-accordion .p-accordion-content {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #323130;
    padding: .571rem 1rem
}

.p-accordion .p-accordion-tab {
    margin-bottom: 2px
}

.p-card {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    color: #323130
}

.p-card .p-card-body {
    padding: 1rem
}

.p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .5rem
}

.p-card .p-card-subtitle {
    color: #888;
    font-weight: 700;
    margin-bottom: .5rem
}

.p-card .p-card-content {
    padding: 1rem 0
}

.p-card .p-card-footer {
    padding: 1rem 0 0
}

.p-fieldset {
    background: #fff
}

.p-fieldset,
.p-fieldset .p-fieldset-legend {
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130
}

.p-fieldset .p-fieldset-legend {
    background: #5d88d6;
    font-weight: 700;
    padding: .857rem 1rem
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    border-radius: 3px;
    color: #323130;
    padding: .857rem 1rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: .5rem
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #5d88d6;
    border-color: #5d88d6;
    color: #d0021b
}

.p-fieldset .p-fieldset-content {
    padding: .571rem 1rem
}

.p-divider .p-divider-content {
    background-color: #000
}

.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px #4b4b4b
}

.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 .5rem
}

.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0
}

.p-divider.p-divider-vertical:before {
    border-left: 1px #4b4b4b
}

.p-divider.p-divider-vertical .p-divider-content {
    padding: .5rem 0
}

.p-panel .p-panel-header {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    padding: .857rem 1rem
}

.p-panel .p-panel-header .p-panel-title {
    font-weight: 700
}

.p-panel .p-panel-header .p-panel-header-icon {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: .857rem 1rem
}

.p-panel .p-panel-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px
}

.p-panel .p-panel-content,
.p-panel .p-panel-footer {
    background: #fff;
    border: 1px solid #5d88d6;
    border-top: 0;
    color: #323130;
    padding: .571rem 1rem
}

.p-splitter {
    background: #fff;
    border-radius: 3px;
    color: #323130
}

.p-splitter .p-splitter-gutter {
    background: #5d88d6;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle,
.p-splitter .p-splitter-gutter-resizing {
    background: #4b4b4b
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #3f3f3f;
    border: 0
}

.p-tabview .p-tabview-nav {
    background: transparent;
    border: 1px
}

.p-tabview .p-tabview-nav li {
    margin-right: 2px
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    font-weight: 700;
    margin: 0 0 1px;
    padding: .857rem 1rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #5d88d6;
    border-color: #5d88d6;
    color: #fff
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff
}

.p-tabview .p-tabview-panels {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #323130;
    justify-content: space-evenly;
    margin: 3px;
    padding: 1rem;
    width: 100%
}

.p-toolbar {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    padding: .857rem 1rem
}

.p-toolbar .p-toolbar-separator {
    margin: 0 .5rem
}

.p-confirm-popup {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-confirm-popup .p-confirm-popup-content {
    padding: .571rem 1rem
}

.p-confirm-popup .p-confirm-popup-footer {
    padding: 0 1rem .571rem;
    text-align: right
}

.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0
}

.p-confirm-popup:after {
    border: solid rgba(50, 50, 50, 0);
    border-bottom: solid #fff
}

.p-confirm-popup:before {
    border: solid rgba(25, 25, 25, 0);
    border-bottom: solid #5d88d6
}

.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #fff
}

.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #5d88d6
}

.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem
}

.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem
}

.p-dialog {
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16)
}

.p-dialog .p-dialog-header {
    background: #5d88d6;
    border-bottom: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    padding: 1rem
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 1rem;
    font-weight: 700
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    margin-right: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0
}

.p-dialog .p-dialog-content {
    background: #fff;
    color: #323130;
    padding: 1rem
}

.p-dialog .p-dialog-footer {
    background: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 1px solid #5d88d6;
    color: #323130;
    padding: .571rem 1rem;
    text-align: right
}

.p-dialog .p-dialog-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem
}

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, .4)
}

.p-overlaypanel {
    background: #fff;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-overlaypanel .p-overlaypanel-content {
    padding: .571rem 1rem
}

.p-overlaypanel .p-overlaypanel-close {
    background: #d0021b;
    border-radius: 50%;
    color: #fff;
    height: 2rem;
    position: absolute;
    right: -1rem;
    top: -1rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #f06292;
    color: #fff
}

.p-overlaypanel:after {
    border: solid rgba(50, 50, 50, 0);
    border-bottom: solid #fff
}

.p-overlaypanel:before {
    border: solid rgba(25, 25, 25, 0);
    border-bottom: solid #5d88d6
}

.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #fff
}

.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #5d88d6
}

.p-sidebar {
    background: #fff;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-sidebar .p-sidebar-header {
    padding: .857rem 1rem
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
    background: transparent;
    border: 0;
    border-radius: 50%;
    color: #8888;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    background: transparent;
    border-color: transparent;
    color: #323130
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-top: 0
}

.p-sidebar .p-sidebar-content {
    padding: .571rem 1rem
}

.p-sidebar-mask.p-component-overlay {
    background: rgba(0, 0, 0, .4)
}

.tooltip.p-tooltip .p-tooltip-text {
    background: #4c4c4c;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    color: #323130;
    padding: .429rem;
    width: 250px;
    background-color: white !important;
    border-radius: 6px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #4c4c4c
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #4c4c4c
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #4c4c4c
}

.tooltip.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #4c4c4c;
    border-bottom-color: white;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    padding: .857rem 1rem
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: .5rem
}

.p-fileupload .p-fileupload-content {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #323130;
    padding: .571rem 1rem
}

.p-fileupload .p-progressbar {
    height: .25rem
}

.p-fileupload .p-fileupload-row>div {
    padding: .571rem .857rem
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0
}

.p-breadcrumb {
    background: #252525;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    padding: .571rem 1rem
}

.p-breadcrumb ul li .p-menuitem-link {
    border-radius: 3px;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-breadcrumb ul li .p-menuitem-link:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon,
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    color: #323130;
    margin: 0 .5rem
}

.p-breadcrumb ul li:last-child .p-menuitem-icon,
.p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #323130
}

.p-contextmenu {
    background: #252525;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    color: #323130;
    padding: 0;
    width: 12.5rem
}

.p-contextmenu .p-menuitem-link {
    border-radius: 0;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #323130
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-contextmenu .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-contextmenu .p-submenu-list {
    background: #252525;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    padding: 0
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #d0021b
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #fff
}

.p-contextmenu .p-menu-separator {
    border-top: 1px solid #4b4b4b;
    margin: 0
}

.p-contextmenu .p-submenu-icon {
    font-size: .875rem
}

.p-megamenu {
    background: #252525;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    padding: .5rem
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link {
    border-radius: 3px;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
    color: #323130;
    margin-left: .5rem
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover {
    background: #d0021b
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #fff
}

.p-megamenu .p-menuitem-link {
    border-radius: 0;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #323130
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-megamenu .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-megamenu .p-megamenu-panel {
    background: #252525;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    color: #323130
}

.p-megamenu .p-megamenu-submenu-header {
    background: #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    font-weight: 700;
    margin: 0;
    padding: .857rem
}

.p-megamenu .p-megamenu-submenu {
    padding: 0;
    width: 12.5rem
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid #4b4b4b;
    margin: 0
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #d0021b
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #fff
}

.p-megamenu.p-megamenu-vertical,
.p-menu {
    padding: 0;
    width: 12.5rem
}

.p-menu {
    background: #252525;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130
}

.p-menu .p-menuitem-link {
    border-radius: 0;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-menu .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-menu .p-menuitem-link .p-submenu-icon {
    color: #323130
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-menu .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-menu.p-menu-overlay {
    background: #252525;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16)
}

.p-menu .p-submenu-header {
    background: #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    font-weight: 700;
    margin: 0;
    padding: .857rem
}

.p-menu .p-menu-separator {
    border-top: 1px solid #4b4b4b;
    margin: 0
}

.p-menubar {
    background: #252525;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    padding: .5rem
}

.p-menubar .p-menuitem-link {
    border-radius: 0;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-menubar .p-menuitem-link .p-submenu-icon {
    color: #323130
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-menubar .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
    border-radius: 3px;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
    color: #323130;
    margin-left: .5rem
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover {
    background: #d0021b
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #fff
}

.p-menubar .p-submenu-list {
    background: #252525;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    padding: 0;
    width: 12.5rem
}

.p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #4b4b4b;
    margin: 0
}

.p-menubar .p-submenu-list .p-submenu-icon {
    font-size: .875rem
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #d0021b
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #fff
}

@media screen and (max-width:960px) {
    .p-menubar {
        position: relative
    }

    .p-menubar .p-menubar-button {
        border-radius: 50%;
        color: #323130;
        display: flex;
        height: 2rem;
        transition: background-color .2s, color .2s, box-shadow .2s;
        width: 2rem
    }

    .p-menubar .p-menubar-button:hover {
        background: #4c4c4c;
        color: #323130
    }

    .p-menubar .p-menubar-button:focus {
        box-shadow: 0 0 0 .1rem #fff;
        outline: 0 none;
        outline-offset: 0
    }

    .p-menubar .p-menubar-root-list {
        background: #252525;
        border: 1px solid #5d88d6;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
        display: none;
        padding: 0;
        position: absolute;
        width: 100%
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid #4b4b4b;
        margin: 0
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: .875rem
    }

    .p-menubar .p-menubar-root-list>.p-menuitem {
        position: static;
        width: 100%
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
        border-radius: 0;
        color: #323130;
        padding: .857rem;
        transition: background-color .2s, border-color .2s, box-shadow .2s;
        user-select: none
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
        color: #323130
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
        color: #323130;
        margin-right: .5rem
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
        color: #323130
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
        background: #4c4c4c
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: #323130
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
        box-shadow: inset 0 0 0 .2rem #fff;
        outline: 0 none;
        outline-offset: 0
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
        margin-left: auto;
        transition: transform .2s
    }

    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-180deg)
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        border: 0;
        box-shadow: none;
        position: static;
        width: 100%
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transform: rotate(90deg);
        transition: transform .2s
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-90deg)
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        position: static;
        width: 100%
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.571rem
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 4.285rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.999rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 7.713rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 9.427rem
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        left: 0;
        top: 100%;
        z-index: 1
    }
}

.p-panelmenu .p-panelmenu-header>a {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    font-weight: 700;
    padding: .857rem 1rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon,
.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-header>a:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
    background: #5d88d6;
    border-color: #5d88d6;
    color: #d0021b
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
    background: #d0021b;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #d0021b;
    color: #fff
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
    background: #ec407a;
    border-color: #ec407a;
    color: #fff
}

.p-panelmenu .p-panelmenu-content {
    background: #fff;
    border: 1px solid #5d88d6;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #323130;
    margin-bottom: 2px;
    padding: 0
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    border-radius: 0;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: #323130
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem
}

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 2px
}

.p-slidemenu {
    background: #252525;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    padding: 0;
    width: 12.5rem
}

.p-slidemenu .p-menuitem-link {
    border-radius: 0;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #323130
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-slidemenu .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-slidemenu .p-slidemenu-list,
.p-slidemenu.p-slidemenu-overlay {
    background: #252525;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16)
}

.p-slidemenu .p-slidemenu-list {
    padding: 0
}

.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link {
    background: #d0021b
}

.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-text {
    color: #fff
}

.p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #4b4b4b;
    margin: 0
}

.p-slidemenu .p-slidemenu-icon {
    font-size: .875rem
}

.p-slidemenu .p-slidemenu-backward {
    color: #323130;
    padding: .857rem
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    background: #5d88d6;
    border-radius: 3px;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-radius: 50%;
    color: #323130;
    font-size: 1.143rem;
    height: 2rem;
    line-height: 2rem;
    min-width: 2rem;
    z-index: 1
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    color: #888;
    margin-top: .5rem
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #d0021b;
    color: #fff
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
    color: #323130;
    font-weight: 700
}

.p-steps .p-steps-item:before {
    border-top: 1px solid #4b4b4b;
    content: " ";
    display: block;
    left: 0;
    margin-top: -1rem;
    position: absolute;
    top: 50%;
    width: 100%
}

.p-tabmenu .p-tabmenu-nav {
    background: transparent;
    border: 1px
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 2px
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background: #5d88d6;
    border: 1px solid #5d88d6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #323130;
    font-weight: 700;
    margin: 0 0 -1px;
    padding: .857rem 1rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: .5rem
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #5d88d6;
    border-color: #5d88d6;
    color: #d0021b
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff
}

.p-tieredmenu {
    background: #252525;
    border: 1px solid #5d88d6;
    border-radius: 3px;
    color: #323130;
    padding: 0;
    width: 12.5rem
}

.p-tieredmenu .p-menuitem-link {
    border-radius: 0;
    color: #323130;
    padding: .857rem;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    user-select: none
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #323130
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #323130;
    margin-right: .5rem
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #323130
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #4c4c4c
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #323130
}

.p-tieredmenu .p-menuitem-link:focus {
    box-shadow: inset 0 0 0 .2rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-tieredmenu .p-submenu-list,
.p-tieredmenu.p-tieredmenu-overlay {
    background: #252525;
    border: 1px solid #5d88d6;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16)
}

.p-tieredmenu .p-submenu-list {
    padding: 0
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: #d0021b
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: #fff
}

.p-tieredmenu .p-menu-separator {
    border-top: 1px solid #4b4b4b;
    margin: 0
}

.p-tieredmenu .p-submenu-icon {
    font-size: .875rem
}

.p-inline-message {
    border-radius: 3px;
    margin: 0;
    padding: .5rem
}

.p-inline-message.p-inline-message-info {
    background: #7fbcec;
    border: 0;
    color: #212121
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #212121
}

.p-inline-message.p-inline-message-success {
    background: #b7d8b7;
    border: 0;
    color: #212121
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #212121
}

.p-inline-message.p-inline-message-warn {
    background: #ffe399;
    border: 0;
    color: #212121
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #212121
}

.p-inline-message.p-inline-message-error {
    background: #f8b7bd;
    border: 0;
    color: #212121
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #212121
}

.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: .5rem
}

.p-inline-message .p-inline-message-text {
    font-size: 1rem
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0
}

.p-message {
    border-radius: 3px;
    margin: 1rem 0
}

.p-message .p-message-wrapper {
    padding: 1rem
}

.p-message .p-message-close {
    background: transparent;
    border-radius: 50%;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-message .p-message-close:hover {
    background: hsla(0, 0%, 100%, .3)
}

.p-message .p-message-close:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-message.p-message-info {
    background: #7fbcec;
    border: 0;
    color: #212121
}

.p-message.p-message-info .p-message-close,
.p-message.p-message-info .p-message-icon {
    color: #212121
}

.p-message.p-message-success {
    background: #b7d8b7;
    border: 0;
    color: #212121
}

.p-message.p-message-success .p-message-close,
.p-message.p-message-success .p-message-icon {
    color: #212121
}

.p-message.p-message-warn {
    background: #ffe399;
    border: 0;
    color: #212121
}

.p-message.p-message-warn .p-message-close,
.p-message.p-message-warn .p-message-icon {
    color: #212121
}

.p-message.p-message-error {
    background: #f8b7bd;
    border: 0;
    color: #212121
}

.p-message.p-message-error .p-message-close,
.p-message.p-message-error .p-message-icon {
    color: #212121
}

.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 400
}

.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: .5rem
}

.p-toast {
    opacity: .9
}

.p-toast .p-toast-message {
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    margin: 0 0 1rem
}

.p-toast .p-toast-message .p-toast-message-content {
    border-width: 0;
    padding: 1rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 .5rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: .5rem 0 0
}

.p-toast .p-toast-message .p-toast-icon-close {
    background: transparent;
    border-radius: 50%;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
    background: hsla(0, 0%, 100%, .3)
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-toast .p-toast-message.p-toast-message-info {
    background: #7fbcec;
    border: 0;
    color: #212121
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon {
    color: #212121
}

.p-toast .p-toast-message.p-toast-message-success {
    background: #b7d8b7;
    border: 0;
    color: #212121
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
    color: #fff
}

.p-toast .p-toast-message.p-toast-message-warn {
    background: #ffe399;
    border: 0;
    color: #212121
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon {
    color: #212121
}

.p-toast .p-toast-message.p-toast-message-error {
    background: #f8b7bd;
    border: 0;
    color: #212121
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
    color: #fff
}

.p-galleria .p-galleria-close {
    background: transparent;
    border-radius: 50%;
    color: #ebedef;
    height: 4rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 4rem
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem
}

.p-galleria .p-galleria-close:hover {
    background: hsla(0, 0%, 100%, .1);
    color: #ebedef
}

.p-galleria .p-galleria-item-nav {
    background: rgba(0, 0, 0, .2);
    border-radius: 3px;
    color: #aeb6bf;
    height: 4rem;
    margin: 0 .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 4rem
}

.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon {
    font-size: 2rem
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(0, 0, 0, .3);
    color: #ebedef
}

.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, .5);
    color: #ebedef;
    padding: 1rem
}

.p-galleria .p-galleria-indicators {
    padding: 1rem
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #4b4b4b;
    border-radius: 50%;
    height: 1rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 1rem
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #4c4c4c
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #d0021b;
    color: #fff
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: .5rem
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: .5rem
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, .5)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: hsla(0, 0%, 100%, .4)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: hsla(0, 0%, 100%, .6)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #d0021b;
    color: #fff
}

.p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, .9);
    padding: 1rem .25rem
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev {
    background-color: transparent;
    border-radius: 50%;
    color: #aeb6bf;
    height: 2rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 2rem
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover {
    background: hsla(0, 0%, 100%, .1);
    color: #aeb6bf
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    transition: background-color .2s, border-color .2s, box-shadow .2s
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-galleria-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, .9)
}

.p-avatar {
    background-color: #4b4b4b;
    border-radius: 3px
}

.p-avatar.p-avatar-lg {
    font-size: 1.5rem;
    height: 3rem;
    width: 3rem
}

.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem
}

.p-avatar.p-avatar-xl {
    font-size: 2rem;
    height: 4rem;
    width: 4rem
}

.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem
}

.p-avatar-group .p-avatar {
    border: 2px solid #fff
}

.p-chip {
    background-color: #4b4b4b;
    border-radius: 16px;
    color: #323130;
    padding: 0 .429rem
}

.p-chip .p-chip-text {
    line-height: 1.5;
    margin-bottom: .2145rem;
    margin-top: .2145rem
}

.p-chip .p-chip-icon,
.p-chip img {
    margin-right: .5rem
}

.p-chip img {
    height: 1.929rem;
    margin-left: -.429rem;
    width: 1.929rem
}

.p-chip .p-chip-remove-icon {
    border-radius: 3px;
    margin-left: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-chip .p-chip-remove-icon:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-scrolltop {
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    height: 3rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    width: 3rem
}

.p-scrolltop.p-link {
    background: #d0021b
}

.p-scrolltop.p-link:hover {
    background: #f06292
}

.p-scrolltop .p-scrolltop-icon {
    color: #fff;
    font-size: 1.5rem
}

.p-skeleton {
    background-color: hsla(0, 0%, 100%, .06);
    border-radius: 3px
}

.p-skeleton:after {
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .04), hsla(0, 0%, 100%, 0))
}

.p-tag .p-tag-icon {
    font-size: .75rem;
    margin-right: .25rem
}

.p-inplace .p-inplace-display {
    border-radius: 3px;
    padding: .429rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #4c4c4c;
    color: #323130
}

.p-inplace .p-inplace-display:focus {
    box-shadow: 0 0 0 .1rem #fff;
    outline: 0 none;
    outline-offset: 0
}

.p-progressbar {
    background: #eaeaea;
    border: 0;
    border-radius: 3px;
    height: 24px
}

.p-progressbar .p-progressbar-value {
    background: #d0021b;
    border: 0;
    margin: 0
}

.p-progressbar .p-progressbar-label {
    color: #323130;
    line-height: 24px
}

.p-terminal {
    background: #fff;
    border: 1px solid #5d88d6;
    color: #323130;
    padding: .571rem 1rem
}

.p-terminal .p-terminal-input {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1rem
}

.p-blockui.p-component-overlay {
    background: rgba(0, 0, 0, .4)
}

.p-badge {
    background: #d0021b;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    height: 1.5rem;
    line-height: 1.5rem;
    min-width: 1.5rem
}

.p-badge.p-badge-secondary {
    background-color: #b0bec5;
    color: #121212
}

.p-badge.p-badge-success {
    background-color: #aed581;
    color: #fff
}

.p-badge.p-badge-info {
    background-color: #4fc3f7;
    color: #fff
}

.p-badge.p-badge-warning {
    background-color: #ffb74d;
    color: #fff
}

.p-badge.p-badge-danger {
    background-color: #e57373;
    color: #fff
}

.p-badge.p-badge-lg {
    font-size: 1.125rem;
    height: 2.25rem;
    line-height: 2.25rem;
    min-width: 2.25rem
}

.p-badge.p-badge-xl {
    font-size: 1.5rem;
    height: 3rem;
    line-height: 3rem;
    min-width: 3rem
}

.p-tag {
    background: #d0021b;
    border-radius: 3px;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    padding: .25rem .4rem
}

.p-tag.p-tag-success {
    background-color: #aed581;
    color: #fff
}

.p-tag.p-tag-info {
    background-color: #4fc3f7;
    color: #fff
}

.p-tag.p-tag-warning {
    background-color: #ffb74d;
    color: #fff
}

.p-tag.p-tag-danger {
    background-color: #e57373;
    color: #fff
}

.p-virtualscroller-loading-icon {
    font-size: 2rem
}

.p-d-flex {
    display: flex
}

.tooltip.p-tooltip .p-tooltip-text {
    width: 250px;
    background-color: white !important;
    border-radius: 6px;
}

.tooltip.p-tooltip .p-tooltip-arrow {
    border-bottom-color: white;
}