@import "../__variables";

#data_table {
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%;
    margin-left: 0px;
    .datatable-cell-default {
        border: 0px;
        border-bottom: 1px solid #e0e0e0;
        padding: auto;
        @include fontStyle("Roboto", 14px, #000);
        background: inherit;
        border: 1px solid #e0e0e0;
        padding: 0.5em 1.2em;
        border-left: 0px;
        border-right: 0px;
        .p-checkbox-box {
            margin-left: 0;
        }
    }
    .datatable-header-default .p-column-title {
        @include fontStyle("Roboto", 12px, #666678);
        text-transform: capitalize;
        font-weight: 700;
    }
    a,
    .link {
        margin: 0;
        color: black;
        cursor: pointer;

        &:hover {
            color: #2196f3;
        }
    }
}
.active-tabs{
    background-color: #008dd5 !important;
    color: #ffffff !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}
.not-active-tabs:hover{
    background-color: #ececec !important;
    color: #7e7e7e !important;
    border-radius: 10px !important;
}
