.container {
    display: flex;
    flex-direction: row-reverse;
    height: 100vh;
}
.content {
    margin: auto;
    text-align: center;
}
.console {
    position: fixed;
    z-index: 100;
    font-size: large;
    margin: 2rem;
}
.text-tight {
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-family: 'Courier New', Courier, monospace;
}
.text-tighter {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}
.bottom-space {
    margin-bottom: 1.3rem;
}
.text-main {
    font-family: cursive;
}
.subtext {
font-family: Arial, Helvetica, sans-serif;
}