@import "../_variables";

.filterBox,
.filterStatusBox,
.filterSubsBox,
.filterAmtBox {
    min-width: 14rem;
    background: #fff;
    box-shadow: 0px 0px 7px 1px #00000050;
    display: flex;
    flex-direction: column;
    align-items: center;

    .p-button.p-button-sm {
        font-size: 0.875rem;
        padding: 0.75625rem 1.19375rem;
    }

    body .p-button.p-button-secondary {
        padding: 10px 20px 11px 20px;
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        color: #666678;
        background-color: #fff;
    }
}

.filterBox {

    .p-column-filter-constraints,
    .p-column-filter-buttonbar {
        margin: 1rem;
        width: 15rem;
    }

    .activity-apply-btn {
        margin: 0 0 -3.95rem 10rem !important;
    }

    .p-column-filter-buttonbar {
        display: flex;
        justify-content: space-between;
    }

    .p-calendar {
        margin-bottom: 4rem !important;
    }

    .p-dropdown {
        margin-bottom: 1.5rem;
    }

    .p-dropdown .p-dropdown-trigger {
        width: 1.5rem;
        background-color: #008dd5 !important;
        color: #ffffff;
        border-radius: 0;
    }

    .p-dropdown-item .p-highlight {
        background-color: #008dd5 !important;
        color: #ffffff;
    }

    .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
        width: 1.6rem !important;
    }

    input,
    .p-calendar,
    .p-dropdown {
        height: 2rem;
        width: 15rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
        padding: 0.375rem 0;
        height: 2.6rem;
    }

    .p-autocomplete {
        width: 15rem;
    }

    .p-column-filter-operator {
        margin-top: 1rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container {
        padding: 0;
        display: flex;
    }

    .p-multiselect-panel .p-multiselect-header button.p-multiselect-close {
        margin-top: 0px;
    }

    .close-btn {
        margin-left: auto;
    }

    .field {
        margin: 1.5rem 0px;
    }

    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
        margin: .5rem 0px;
    }

    .p-button.p-button-sm {
        font-size: 0.875rem;
        padding: 0.75625rem 1.19375rem;
    }

    body .p-button.p-button-secondary {
        padding: 10px 20px 11px 20px;
    }

}

.filterStatusBox {

    .p-column-filter-constraints,
    .p-column-filter-buttonbar {
        margin: 2rem 1rem 1rem 1rem;
        width: 17rem;
    }

    .p-multiselect-label-container.p-multiselect-label-container {
        min-height: 2.7rem;
        background-color: #fff;
    }

    .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
        padding: 0.575rem 0.75rem;
        display: flex;
        flex-wrap: wrap;
        width: 16rem;
        gap: 0.5rem;
    }

    .p-multiselect .p-multiselect-label.p-placeholder {
        margin-top: 4px;
    }

}

.filterSubsBox {
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        padding: 0 !important;
    }

    .p-column-filter-constraints {
        margin: 1rem;
        width: 26rem;
    }

    .p-multiselect {
        display: flex;
        width: 26rem !important;
        height: auto;
    }

    .p-column-filter-buttonbar {
        display: flex;
        justify-content: space-between;
        margin: 1rem;
        width: 26rem;
    }

    .p-treeselect:hover {
        border-color: #969ba0 !important;
    }

    .p-multiselect .p-multiselect-label.p-placeholder {
        color: #6c757d;
        margin-top: 0.35rem;
    }

    .p-multiselect .p-multiselect-clear-icon {
        color: #6c757d;
        right: 2rem !important;
    }

    .p-treeselect-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-left: -3.7rem;
    }

    .p-treeselect .p-treeselect-trigger {
        background: #008dd5;
        color: #f1f8ff;
        width: 1.5rem;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .p-treenode-selectable {
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        background-color: black !important;
        color: white !important;
    }

    .p-treeselect .p-treeselect-label {
        white-space: normal !important;
    }

    .custom-chip {
        display: inline-flex;
        align-items: center;
        padding: 0.35rem;
        margin: 0.3rem;
        background-color: #008dd5;
        ;
        color: #fff;
        border-radius: 16px;
    }

    .p-treeselect-label-container .custom-chip .custom-chip-label {
        margin-right: 0.25rem;
        font-size: 14px;
        font-family: RobotoCondensed;
    }

    .p-treeselect-label-container .custom-chip .custom-chip-close-icon {
        cursor: pointer;
        font-size: 1rem;
    }

    .p-treeselect.p-treeselect-chip .p-treeselect-token {
        margin-right: 0.5rem;
        margin-bottom: 0.3rem;
    }

    .p-treeselect-value-container {
        margin-right: 1.5rem;
        width: 25rem;
    }

    .p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
        padding-right: 1.75rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .p-multiselect-label-container {
        background-color: #fff;
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token {
        padding: 0.275rem 0.5rem !important;
        margin-right: 0.5rem;
        background: #008dd5 !important;
        color: #ffffff !important;
    }
}

.filterAmtBox {

    .p-column-filter-constraints,
    .p-column-filter-buttonbar {
        margin: 1rem;
        width: 15rem;
    }

    .p-column-filter-buttonbar {
        display: flex;
        justify-content: space-between;
    }

    .p-dropdown {
        height: 2.6rem !important;
        width: 15rem;
    }

    .p-fluid .p-inputnumber {
        width: 100%;
        margin-top: 2rem;
    }

    .p-dropdown .p-dropdown-trigger {
        background-color: #008dd5 !important;
        color: #ffffff !important;
        border-radius: 0 !important;
        width: 1.5rem !important;
    }

    .p-fluid .p-inputnumber .p-inputnumber-input {
        height: 2.6rem;
    }
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2em rgba(0, 141, 213, 0.3) !important;
    border-color: rgba(0, 141, 213, 0.3) !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin: 0 1rem 0 0 !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover {
    color: #000000 !important;
}

.norm {
    font-size: 1rem;
    font-family: "Roboto";
    border-radius: 6px;
    transition: box-shadow 0.2s;
    padding: 0.5rem;
}

.p-treeselect .p-treeselect-node-content:hover {
    color: #fff;
    background-color: #B3E5FC !important;
}

.disable-plan-mode {
    cursor: not-allowed;
    opacity: 0.5;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    padding: 0.5rem;
}

.disable-product-mode {
    font-size: 1rem;
    cursor: not-allowed;
    opacity: 0.5;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    padding: 0.45rem;
}

.clear-btn {
    position: fixed;
    top: 23rem;
    right: 17rem;
}

.subscriptionReport {
    .billingPeriod {
        color: $rev_sec;
        font-size: 0.8rem;
    }

    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
        background: #fff;
    }

    .subscriptionBodyTemplate {
        .name-body {
            cursor: pointer;
        }
    }

    .title-tile {
        font-size: 1rem;
    }
}

.card-titles {
    font-family: RobotoCondensed-Bold;
    font-size: 12px;
    margin-left: 5px;
}

.export-text {
    color: $rev_blue;
    font-family: Roboto-Bold;
    font-size: 11px;
    margin: auto;
}

.action-bar {
    margin: 0 1rem;
}

.report_calender>span>input {
    padding: 0.8rem;
}

.report_calender>span>button {
    height: 2.95rem;
}

.sub-info {
    margin-bottom: 1px;
}

.sub-info-email {
    margin-top: -5px;
}

.sub-customerRef {
    font-family: "Roboto-Bold";
    font-size: 12px !important;
    margin-bottom: 2px;
    cursor: pointer;
}

.stat-label {
    margin-left: 3px;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    -moz-box-shadow: 0 0 0 0.2em #f492b4;
    box-shadow: 0 0 0 0.2em #008dd5 !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:active {
    background-color: #008dd5 !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
    background-color: #D8F1FD !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background-color: #008dd5 !important;
    color: #ffffff;
    cursor: pointer;
}

.statusFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.planFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.contactFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.amountFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.activityFilter>.p-column-header-content>.p-column-filter-menu>.p-column-filter-menu-button {
    background-color: #008dd5 !important;
    color: white !important;
    border-radius: 50% !important;
}

.subs_clear {
    font-family: Roboto;
    padding: 0.3rem;
}

.filterBox .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    margin: 0 !important;
}

::placeholder {
    font-family: Roboto;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only)[aria-label="Apply"] {
    background-color: #d0021b;
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background-color: #d0021b;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight:active {
    background-color: rgba(0, 141, 213, 0.9)
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: rgba(0, 141, 213, 0.9);
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 2px;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    background-color: rgba(0, 141, 213, 0.9);
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover {
    background-color: #D8F1FD !important;
}

.customerBodyTemplate,
.amountbodytemplate,
.subInfobodytemplate,
.statusbodytemplate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.status-cancelled,
.status-complete,
.status-completed,
.status-active,
.status-paused {
    padding: 4px 14px !important;
    width: 6.5rem;
}

body .p-multiselect .p-multiselect-label :is(.status-cancelled, .status-complete,
    .status-completed, .status-active,
    .status-paused) {
    border-radius: 5px !important;

}

.p-multiselect-item :is(.status-cancelled, .status-complete,
    .status-completed, .status-active,
    .status-paused) {
    padding: 4.5px 12px !important;
    border-radius: 5px !important;
    font-family: "Roboto";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
    margin: 2rem 0rem 0rem 0rem;
}

body .p-checkbox .p-checkbox-box {
    width: 24px;
    height: 21px;
    margin-top: -0.01rem;
    margin-left: 0.1rem;
    box-shadow: 0 0 0 0.08em white;
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: #D8F1FD;
    background-color: #008dd5;
    color: #ffffff;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 1rem 1rem;
}

body .p-multiselect-panel .p-multiselect-header {
    padding: 0.429em 0.857em 0.629em 0.97em;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-top: 0.1rem;
}

body .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.05em #000000;
    -moz-box-shadow: 0 0 0 0.05em #000000;
    box-shadow: 0 0 0 0.05em #000000;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 6px;
    transition: box-shadow 0.2s;
    padding: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.01rem #f9f9f9 !important;
}

body .p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.01em #008dd5 !important;
    border-color: #008dd5;
}

body .p-multiselect .p-multiselect-trigger {
    background-color: #008dd5;
    width: 1.5rem;
    line-height: 2em;
    text-align: center;
    padding: 0;
    color: #ffffff;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 1px;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #ffffff;
    background-color: #008dd5;
}

.cust_report_tree-select {
    border: 0.5px solid #dbd7d7 !important;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only)[aria-label=Apply] {
    width: fit-content;
}

.reports_clear_btn {
    width: fit-content !important;
}