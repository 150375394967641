@import "../__variables";

.t-form {
    width: 100%;
}

.card-background {
    background-color: #fff;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #d0021b;
    color: #fff;
    display: none;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #4b4b4b;
    border-radius: 0;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    width: 2rem;
    display: none;
}

.fav-card-content {
    margin-bottom: 1.5rem;
}

body .payout_channel {
    &-title {
        @include fontStyle("RobotoCondensed-Bold", 18px, #000);
        margin-top: 1rem;
    }

    &-dropdown {
        border: 1px solid #ced4da;
        background-color: #fff;
        height: 3rem;
        width: 20rem;
        align-items: center;
        @include fontStyle("Roboto", 14px, #000);
    }

    &-saved {
        &-new-button.p-button.p-button-secondary {
            background-color: #fff;
            border: 1px solid #ced4da;
            margin-left: 1rem;
            color: #495057;
            height: 3rem;

            & .p-button-label {
                @include fontStyle("Roboto-SemiBold", 12px, #495057);
            }
        }

        &-info {
            display: flex;
            flex-direction: column;

            &-item {
                width: 100%;
                margin-top: 1rem;

                &-title {
                    margin-left: 0.5rem;
                    @include fontStyle("Roboto-SemiBold", 14px, #000);
                    text-transform: capitalize;
                }

                &-value {
                    @include fontStyle("Roboto", 14px, #000);
                }
            }
        }
    }

    &-new {
        &-new-button.p-button.p-button-secondary {
            background-color: #fff;
            border: 1px solid #ced4da;
            margin-left: 1rem;
            color: #495057;
            height: 3rem;

            & .p-button-label {
                @include fontStyle("Roboto-SemiBold", 12px, #495057);
            }
        }

        &-input {
            display: flex;
            width: 100%;
            margin-top: 1rem;
            align-items: center;

            &-container {
                display: flex;
                flex-direction: column;
            }

            &-label {
                margin-left: 0.5rem;
                width: 10rem;
                @include fontStyle("Roboto-SemiBold", 14px, #000);
                text-transform: capitalize;
            }

            &-field {
                width: 302px;
                border: 1px solid #ced4da;
                height: 3rem;
                @include fontStyle("Roboto", 14px, #000);

                .PhoneInputCountry {
                    display: none;
                }

                .PhoneInputInput {
                    border: 0;

                    &:focus-visible {
                        border: 0;
                        outline: none;
                    }
                }
            }
        }
    }

    &-buttonsBar {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: flex-end;

        &-label {
            margin-right: 0.5rem;
            @include fontStyle("Roboto-SemiBold", 14px, #000);
        }

        &-continue-button.p-button.p-button-info,
        &-cancel-button.p-button,
        &-reset.p-button {
            margin-left: 1rem;
            margin-right: 0;
            margin-top: 1rem;
            height: 3rem;
            padding: 10px;

            & .p-button-label {
                @include fontStyle("Roboto-SemiBold", 14px, #fff);
            }
        }

        &-continue-button.p-button.p-button-info {
            background-color: $rev_blue;
        }

        &-cancel-button.p-button {
            background-color: $rev_pri;
        }

        &-reset.p-button {
            margin-left: 0;
            margin-right: auto;
            background-color: transparent;
            color: $rev_pri;
            border: 1px solid $rev_pri;
        }
    }
}

.accounts-channel-title {
    margin-left: 1.2rem;
}

.addWallet {
    width: 350px;

    &-dropdown {
        width: 25rem;
    }

    .payout_channel-title {
        @include fontStyle("Roboto", 16px, $rev_gray);
    }

    .payout_channel-buttonsBar-cancel-button.p-button .p-button-label {
        color: $rev_blue;
    }

    .p-dialog-header-icons {

        svg,
        button {
            color: #fff;
        }
    }

    .p-dialog-header .p-dialog-header-icon:enabled:hover {
        background-color: $rev_sec;
    }
}

.total_transfers_main_div {
    margin-top: -2rem;
    display: flex !important;
    justify-content: space-between !important;
}

.totalAmountCardsMargins {
    margin: 0.8rem 1rem 0rem 0rem;
}

.totalAmountText {
    display: flex;
    flex-direction: row;
    margin-top: 0.9rem;
    margin-bottom: 3rem;
}

.icon_exceed_text {
    display: flex;
    flex-direction: row;
}

body .totalTransfers {
    display: flex;
    width: 100%;
    flex-direction: row;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }

    &-inputs {
        &-wallet {
            display: flex;
            align-items: center;

            &-chose {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;

                & .form-label {
                    margin-bottom: 0.5rem;
                    @include fontStyle("Roboto-Bold", 10px, #000);
                }
            }

            &-max {
                display: flex;
                flex-direction: column;
                margin-left: 1rem;

                & .form-label {
                    margin-bottom: 0.5rem;
                    @include fontStyle("RobotoCondensed-Bold", 10px, #495057);
                }

                span {
                    @include fontStyle("RobotoCondensed-Bold", 18px, #000);
                }
            }
        }

        &-amount {
            display: flex;
            flex-direction: column;

            & .form-label {
                margin-bottom: 0.5rem;
                @include fontStyle("Roboto-Bold", 10px, #000);
            }

            .payout_channel-new-input-field {
                width: 15rem;
                margin-top: 0.3rem;
            }
        }

        &-button.p-button.p-button-text {
            padding: 0;
            margin: 0.8rem 0px 0.3rem 0px;
            cursor: pointer;
            @include fontStyle("RobotoCondensed-Bold", 14px, $success);
        }

        @media screen and (min-width: 1000px) {
            width: 50%;
        }
    }

    &-totalAmount {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;

        &-card {
            width: 15rem;
            height: 5rem;
            border: 2px solid $rev_blue;
            display: flex;
            border-radius: 5px;
            padding: 0.5rem;

            &-i {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1rem;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background-color: $rev_blue;
                @include fontStyle("RobotoCondensed-Bold", 24px, #fff);
            }

            &-info {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &-name {
                    @include fontStyle("RobotoCondensed", 12px, #666678);
                }

                &-amount {
                    @include fontStyle("Roboto-SemiBold", 14px, #000);
                }
            }

            &-cancel.p-button.p-button-outlined.p-button-info {
                height: 1.5rem;
                width: 1.5rem;
                margin-left: auto;
                margin-right: 0.3rem;

                .p-button-label {
                    font-size: 0.5rem;
                }
            }
        }

        @media screen and (min-width: 1000px) {
            width: 50%;
        }
    }
}

.confirmation {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .recipient {
        height: auto;
        width: 25rem;
        border: 1px solid #ccccd2;
        border-radius: 6px;
        padding: 1rem;

        &-title {
            @include fontStyle("RobotoCondensed-Bold", 16px, #495057);
        }

        &-brand {
            display: flex;
            align-items: center;

            .icon {
                height: 10rem;
                width: auto;
            }

            .channel {
                margin-left: 1rem;
                @include fontStyle("Roboto", 14px, #495057);
            }
        }

        &-info {
            .num {
                @include fontStyle("Roboto-SemiBold", 18px, #000);
                margin: 0;
            }

            .name {
                @include fontStyle("Roboto", 16px, #000);
                margin-bottom: 1rem;
            }
        }
    }

    .transferAmount {
        margin-left: 1rem;

        &-amount {
            padding: 0.3rem;
            width: 15rem;
            @include fontStyle("Roboto-SemiBold", 32px, #000);
            background-color: rgba($color: $rev_blue, $alpha: 0.2);
            border-radius: 6px;
        }

        &-fee {
            height: 3rem;
            width: 15rem;
            @include fontStyle("Roboto", 14px, #495057);
            background-color: rgba($color: #f2f2f2, $alpha: 1);
        }
    }
}

.star-buttons {
    display: flex;
    height: 78px;
}


.nameBodyTemplate {
    width: 300px;
}

.nameTemplate {
    display: flex;
    text-align: left;
}

.balance_detail {
    display: flex;
}

.name_template {
    display: flex;
    flex-direction: column;
    text-align: start;
    height: 78px;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
}

.p-datatable .p-datatable-thead>tr>th.p-align-center>.p-column-header-content {
    display: flex;
    justify-content: center;
}

.wallet_headers>div {
    display: flex;
    justify-content: flex-start !important;
}

.balance_detail_template {
    display: flex;
    flex-direction: column;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    height: 78px;
}

.balance_detail {
    display: flex;
}

body .fav {
    height: 105px;
    width: 250px;
    border: 2px solid $rev_pri;
    background-color: white;
    border-radius: 10px;
    margin: 0.3rem;
    padding: 0;

    &-display {
        gap: 30px;
    }

    &-box {
        display: flex;
        flex-wrap: wrap;
    }

    &-button-cancel.p-button.p-button-outlined.p-button-danger {
        margin-left: 0rem;
        height: 1.2rem;
        width: 1.2rem;
        border-color: $rev_pri;


        & .p-button-label {
            font-size: 0.5rem;
        }

        & .p-button-icon {
            font-size: 0.8rem;
        }
    }

    &-name {
        @include fontStyle("RobotoCondensed-Bold", 13px, #000);
        cursor: pointer;
        line-height: 1;
        margin-bottom: 0.3rem;
    }

    &-info {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;

        &-type {
            @include fontStyle("RobotoCondensed", 11px, #008DD5, bold);
            margin-bottom: 0 !important;
        }

        &-icon {
            height: 40px;
            width: 50px;
            border: 0.3px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }

        &-text {
            &-num {
                @include fontStyle("RobotoCondensed-SemiBold", 12px, #495057);
                cursor: pointer;
            }

            &-name {
                @include fontStyle("Roboto", 11px, #000);
                cursor: pointer;

            }

            &-channel {
                @include fontStyle("RobotoCondensed", 9.5px, #495057, bold);
                cursor: pointer;
            }
        }

        p {
            margin: 0;
        }
    }

    &-buttons {
        display: flex;
        justify-content: flex-start;
    }

    &-add {
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

body .fav-card-amount {
    display: flex;
    margin-left: 1rem;

    &.balance {
        @include fontStyle("Roboto", 14px, #000);
    }

    &.available div {
        @include fontStyle("Roboto-Bold", 12px, #000);
    }

    &.pending div {
        @include fontStyle("Roboto", 12px, #000);
        padding: 0;
    }

    & .col-6 {
        padding: 0;
    }
}

body .fav-card-amount {
    display: flex;
    margin-left: 1rem;

    &.balance {
        @include fontStyle("Roboto", 14px, #000);
    }

    &.available div {
        @include fontStyle("Roboto-Bold", 12px, #000);
    }

    &.pending div {
        @include fontStyle("Roboto", 12px, #000);
        padding: 0;
    }

    & .col-6 {
        padding: 0;
    }
}

.fav-card {
    height: 12.5rem;
    background-color: white;
    border: 2px solid $rev_pri;
    border-radius: 10px;
    margin: 0.3rem;
    padding: 0;
    overflow: hidden;
}

#add_wallet_card {
    height: 12.5rem;
    background-color: white;
    border-radius: 10px;
    margin: 0.3rem;
    padding: 0;
}

.spinner-content {
    display: flex;
    justify-content: center;
    width: 60px;
    height: 90px;
}

.spinner-content {
    display: flex;
    justify-content: center;
    width: 60px;
    height: 90px;
}

body .balance_template.withdraw {
    justify-content: start;
    text-align: start;
    height: 78px;
    margin-top: 0.1rem;
    margin-bottom: 1rem;

    .withdraws {
        @include fontStyle("Roboto-Bold", 14px, #000);
        margin-bottom: 0.2rem;

        span {
            @include fontStyle("Roboto", 10px, #666678);
        }
    }

    .latest {
        span {
            @include fontStyle("Roboto-Bold", 12px, #666678);
            margin-bottom: 0.2rem;
        }

        @include fontStyle("Roboto", 12px, #666678);
        margin-bottom: 0.2rem;
    }

    .withdraws-amount {
        @include fontStyle("Roboto-Bold", 12px, #000);
        margin-bottom: 0.1rem;
    }
}

.bal_main_div {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
}

.balance_label {
    @include fontStyle("Roboto-Bold", 14px, #000);
    margin: 0rem;
}

.balance_amt {
    @include fontStyle("Roboto", 14px, #000);
    margin: 0rem 0rem 0rem 0.3rem
}

.avail_main_div {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.2rem;
}

.available_label {
    @include fontStyle("Roboto-Bold", 12px, #689F38);
    text-transform: uppercase;
    margin: 0rem 0rem 0rem -1rem;
}

.available_amt {
    @include fontStyle("Roboto-Bold", 12px, #000);
    margin: 0rem 0rem 0rem 0.3rem;
}

.avail_span {
    margin: -0.15rem 0rem 0rem 0rem;
}

.pending_label {
    margin-right: 0rem;
    @include fontStyle("Roboto-Bold", 12px, #FBC02D);
    text-transform: uppercase;
}

.pending_span {
    margin: -0.15rem 0rem 0rem 0rem
}

.pending_amt {
    @include fontStyle("Roboto", 12px, #000);
    margin: 0rem 0rem 0rem 0.3rem
}

.identifiers {
    @include fontStyle("Roboto-bold", 12px, #495057);
    padding-right: 0.5rem;
}

.identifiers_hr {
    width: 0.12rem;
    background-color: #495057;
    margin: 0.1rem 0.5rem 0rem 0rem;
}

#wallet_name_search {
    width: 250px;
    height: 45px;
    margin: .5rem 0px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
}

body .payout_channel-dropdown {
    width: 21.8rem;
}

body .p-dialog .p-dialog-footer {
    padding: 1rem;
}

body .step {
    &-box {
        z-index: 1;
        border-radius: 6px;
        border-width: 1px;
        border-style: solid;
        padding: 1rem;
        align-items: center;
        flex-direction: column;
        display: flex;
        background-color: #fff;

        @media screen and (min-width: 768px) {
            flex-direction: row;
        }

        &-icon {
            margin-bottom: 0.5rem;
            margin-right: 0;
            font-size: 1.5rem;

            @media screen and (min-width: 768px) {
                margin-bottom: 0;
                margin-right: 1rem;
                font-size: 2rem;
            }
        }

        &-title {
            margin-bottom: 0.25rem;
            color: #212121;
            font-family: "Roboto-SemiBold";
            font-size: 13px;
        }

        &-des {
            font-size: 12px;
            color: #757575;
            font-family: "Roboto";
        }

        &-bar {
            transform: translateY(-50%);
            height: 2px;
            background-color: #ced4da;
            top: 50%;
            position: absolute;
            width: 100%;
            left: 100%;
        }

        &-post {
            border-color: #ced4da;
            background-color: #f2f2f2;

            &-icon {
                color: #666678;
            }

            &-title {
                color: #666678;
            }

            &-des {
                color: #666678;
            }
        }

        &-active {
            border-color: #008dd5;

            &-icon {
                color: #008dd5;
            }

            &-title {
                color: #008dd5;
            }

            &-des {
                color: #000;
                font-family: "Roboto-Bold";
            }
        }

        &-pre {
            border-color: #ced4da;

            &-icon {
                color: #66bb6a;
            }

        }
    }
}

.wallets_requested_by {
    @include fontStyle("Roboto-Bold", 12px, #666678);
    margin-bottom: 0.2rem;
}

.requester_name {
    @include fontStyle("Roboto", 12px, #666678);
}

.choose_accounts {
    @include fontStyle("Roboto", 16px, $rev_gray);
}

#transferTotalAmount {
    width: 18rem !important;
    height: 3rem !important;
    margin-bottom: 0rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 0.3rem;
    @include fontStyle("Roboto-SemiBold", 32px, #000);
    background-color: rgba($color: $rev_blue, $alpha: 0.2);
}

.fee_span {
    @include fontStyle("Roboto", 14px, #495057);
    padding: 0.8rem 0rem 0.5rem 0.4rem;
    display: flex;
    text-align: center;
    background-color: #F2F2F2;
    height: 3rem;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.bank-minimum {
    @include fontStyle("Roboto", 14px, #d0021b);
    margin-bottom: 0.5rem;
}

.between_text {
    @include fontStyle("RobotoCondensed-Bold", 10px, #495057);
}

.totalAmount_div {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: -1rem;
    margin-bottom: -6rem;
}

.totalAmount {
    @include fontStyle("RobotoCondensed-Bold", 18px, #000);
}

.limits_main_div {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #ffcdd2;
    border: solid #e60017;
    border-radius: 3px;
    border-width: 0px;
    color: #73000c;
    padding: 0.3rem;
    margin: 0.5rem 0rem 0rem 0.5rem;
}

.i_times_circle {
    font-weight: bold;
    font-size: 10px !important;
    margin-right: 0.3rem;
    margin-left: 0.1rem;
}

.exceed_text {
    @include fontStyle("RobotoCondensed-Bold", 10px, !important);
}

.exceed_text2 {
    @include fontStyle("RobotoCondensed-Bold", 14px, #73000c);
    display: flex;
    text-align: left;
}

.bank_minimum_withdrawal_div {
    background-color: #ffcdd2;
    color: #73000c;
    border-radius: 3px;
    padding: 0.4rem;
    margin: 1.2rem 0rem -4rem 3rem;
}

.bank_minimum_withdrawal_text1 {
    @include fontStyle("RobotoCondensed-Bold", 10px, #73000c);
}

.bank_minimum_withdrawal_text {
    padding: 0.2rem;
    @include fontStyle("RobotoCondensed-Bold", 14px, #73000c);
}

.payout_type_mobile {
    margin-bottom: 4rem;
}

.alternate-main {
    display: flex !important;
    flex-wrap: wrap !important;
    max-width: 43rem !important;
}

.alternate {
    width: 270px !important;
    height: 4.6rem !important;
}

.alternate_i {
    width: 50px !important;
    height: 48px !important;
}

.totalTransfers-totalAmount-card-cancel {
    margin-right: 0px !important;
}

.p-carousel .p-carousel-item {
    flex: none !important;
}

.error_balance {
    margin-top: 0.4rem;
    @include fontStyle("Roboto", 13px, #d0021b);
}

.p-inline-message.p-inline-message-error {
    height: 3rem;
}

