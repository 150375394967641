@import "../__variables";

.p-datatable-header {
    background-color: white !important;
    border: 1px solid white !important;
}

#submit-button {
    background-color: white !important;
    border: 1px solid lightgray;
    color: rgb(68, 68, 240);
}

#data_table .p-datatable-tbody>tr>td {
    text-align: center !important;
}

#close_search_button {
    background-color: red !important;
}

.tab_view .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #e91e63 !important;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
    color: #e91e63;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    margin-bottom: -1px;
    border: none;
    background-color: white;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: #e91e63;
    border: 1px solid #e91e63;
}

body .p-tabmenu .p-tabmenu-nav {
    padding: 0;
    background: none;
    border-bottom: none;
}

.tab-success:hover {
    background-color: rgb(49, 189, 95) !important;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    margin-bottom: -1px;
    border: none;
    background-color: #e91e63;
    color: white;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
    color: white;
}

.p-menuitem-link {
    margin-bottom: -1px;
    border: none;
    background-color: #FF1919 !important;
    color: white;
}

.p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: #42A5F5 !important;
    border: none !important;
}

.tab-failure {
    background-color: #FF1919 !important;
    background: #FF1919 !important;
    color: #000;
}

.layout-wrapper .layout-main .layout-content {
    padding: 16px;
    // margin-top: 30px;
}

.customer-id {
    margin-left: 29px;
}

.tier-amount {
    margin-left: 300px;
}

.billing-content {
    margin-left: 300px;
    max-width: 80px;
}

.ref-content {
    margin-top: 1px;
    margin-right: 8px;
    width: auto;
}

@media only screen and (max-width: 1170px) {
    body {
        .tier-amount {
            margin-left: 0px !important;
        }

        .billing-content {
            margin-left: 0px;
            max-width: 80px;
        }

        .ref-content {
            margin-right: 8px;
            width: auto;
        }
    }

    #side-menu {
        margin-left: 0px !important;
    }
}

.tab1 {
    box-shadow: 0 3px 2px 0 rgb(0 0 0 / 20%);
}

#success-tab.active,
#failed-tab.active {
    box-shadow: 0 3px 2px 0 rgb(0 0 0 / 20%);
}

#success-tab.not-active,
#failed-tab.not-active {
    color: #000;
    background-color: transparent;
}

.transactions_header {
    color: #666678;
    font-family: "Roboto-bold";
    font-weight: 14px
}

.transactions_data {
    font-weight: 12px;
    font-family: Roboto;
    color: #000000
}

.data-table-card {
    border-left: solid 0.1rem #808080;
    border-bottom: solid 0.1rem #808080;
    border-right: solid 0.1rem #808080;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0 0;
}

#data_table {
    overflow: hidden
}

.title-div {
    margin-left: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem
}

.transactions-title {
    color: #008DD5
}

.transactions_card {
    width: 95%;
}

.buttons-div {
    border-left: solid 0.1rem #808080;
    border-right: solid 0.1rem #808080;
    border-top: solid 0.1rem #808080;
    margin-left: 2rem;
    width: 70%;
    height: 3rem;
    background-color: #F7F7F7;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
}

#success-tab {
    color: #00AE5A;
    border-radius: 0.3rem;
    background-color: white;
    border: none;
    height: 2rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem
}

#failed-tab {
    color: #D32F2F;
    border-radius: 0.3rem;
    background-color: white;
    height: 2rem;
    margin-top: 0.5rem;
    border: none;
    margin-left: 1rem;
    margin-bottom: 0.5rem
}

.activity-actions {
    &.p-card {
        width: auto;
        margin: 1rem;
        margin-top: 0;
    }

    .search_input {
        height: 3rem;
    }
}

#data_table {
    margin-bottom: 20px;
    cursor: pointer;

    body .datatable-header-default {
        font-family: Roboto;
        font-size: 12px;
        color: #666678;
        text-transform: capitalize;
        border: 0;
        border-bottom: 1px solid #e0e0e0;
    }

    .datatable-cell-default {
        border: 0px;
        border-bottom: 1px solid #e0e0e0;
        padding: auto;
        color: #000;

        .p-checkbox-box {
            margin-left: 0;
        }
    }
}

.table-link {
    cursor: pointer;
    color: #2572a8;
    text-decoration: underline;
}

.reschedule-date {
    width: 21rem;
}

.subs-actions-footer {
    display: flex;
    justify-content: flex-end;
}

.marking-dialog {
    width: 30vw;
}

.activity-select div {
    width: 1px !important;
    height: 100px !important;
}

.select-header div {
    margin-left: 10px;
}

.subsBodyTemplate-header {
    display: flex !important;
    color: #666678 !important;
    height: 50px !important;
}

@media screen and (max-width: 1344px) {
    .subsBodyTemplate-header {
        height: 70px !important;
    }
}

.reference-text {
    @include fontStyle("Roboto-Bold", 14px, #42A5F5);
}

.plan-name-text {
    @include fontStyle("Roboto-Bold", 13px, #0000);
}

.ref {
    font-size: 11px;
    color: #666678;
}

.text-color {
    color: #666678;
}

.billing-title {
    @include fontStyle("Roboto-Bold", 12px, #666678);
    margin-bottom: 2px;
}

.amount-text {
    font-weight: 700;
    margin-right: 2px;
}

.action_bar {
    display: flex;
    flex-direction: row;
}

.status-date-text {
    color: #666678;
    font-size: 11px;
    font-weight: bold;
}

.status-date {
    height: 75px;
}

.active-stats {
    height: 92px;
}