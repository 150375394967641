@import "../__variables";

#tier_dialog .p-dialog-header {
    background-color: #d0021b !important;
    padding: 1rem;
}

.tier_dialog .p-dialog-header .p-dialog-title {
    @include fontStyle("RobotoCondensed-Bold", 18px, white);
}

.tier_dialog .p-dialog-header .p-dialog-header-icon {
    color: white !important;
}

#tier_dialog {
    width: 350px;
}

.tier_dialog .p-dialog-header .p-dialog-header-icon:hover {
    background-color: #ffffff !important;
}

.tier_dialog .p-dialog-header,
.p-dialog .p-dialog-footer {
    border-top-right-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
}

.tier_dialog .p-dialog-content {
    border-bottom-right-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;

}

.main-div {
    overflow: hidden;
}

body .p-checkbox .p-checkbox-box {
    width: 17px;
    height: 15px;
    margin-top: 0.2rem;
    margin-left: 0.5rem;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #666678;
}

body .p-dialog {
    padding: 0;
    border: none;
}

.wizard-forms .p-dropdown .p-dropdown-trigger,
.wizard-forms .p-dropdown .p-inputtext {
    margin-top: 0.3rem;
}

#name,
#description,
#policy,
#billingPeriod,
#deductions,
#graceDays,
#reminderDays,
#groupName,
#plan_cost {
    @include fontStyle("Roboto", 14px, #666678 !important);
}

#name {
    width: 36vw;
    height: 3rem;
    margin-bottom: 0.6rem;
}

#description {
    height: 3rem;
    width: 36vw;
}

#policy {
    background-color: transparent;
    max-width: 22rem;
    width: 36vw;
    height: 3rem;
    padding-top: 0.4rem;
}

#billingPeriod {
    background-color: transparent;
    max-width: 22rem;
    width: 36vw;
    height: 3rem;
    padding-top: 0.4rem;
}

#deductions {
    max-width: 22rem;
    width: 36vw;
    height: 3rem;
}

.deduction_error_message {
    display: flex;
    align-items: center;
    padding-left: 0.3rem;
    padding-top: 0.5rem;
}

#graceDays {
    background-color: transparent;
    height: 3rem;
    max-width: 35rem;
}

#reminderDays {
    background-color: transparent;
    height: 3rem;
    max-width: 35rem;
}

#setup {
    width: 22rem;
    max-width: 35rem;
    height: 3rem;
}

#tier_save {
    height: 2rem;
    background-color: #d0021b !important;
    margin-left: 10rem;

    @media screen and (max-width: 1170px) {
        margin-left: 8rem;
    }

    @media screen and (max-width: 1150px) {
        margin-left: 10rem;
    }

    @media screen and (max-width: 1120px) {
        margin-left: 9rem;
    }

    @media screen and (max-width: 940px) {
        margin-left: 3rem;
    }

    @media screen and (max-width: 1200px) {
        margin-left: 1rem;
    }
}

#groupName {
    background-color: transparent;
    width: 50%;
    height: 3rem;
}

.bolder_hints {
    @include fontStyle("RobotoCondensed", 13px, #495057);
    font-weight: bolder;
}

.labels {
    @include fontStyle("Roboto", 11px, #000000);
}

.hints {
    @include fontStyle("RobotoCondensed", 13px, #495057);
    margin-top: 1rem;
}

.policy_hint {
    @include fontStyle("RobotoCondensed", 13px, #495057);
    padding-left: 0.1rem;
    padding-top: 0.7rem;
}

.tier_name_des {
    margin-left: 1rem;
    @include fontStyle("RobotoCondensed", 14px, #000000);
}

.planName {
    @include fontStyle("RobotoCondensed-SemiBold", 14px, #000000);
    margin-left: -0.5rem;
}

.first_hr {
    height: 1px;
    margin-top: 1.6rem;
    margin-bottom: 1rem;
    background-color: #C1C1C9;
}

.second_hr {
    height: 1px;
    margin-bottom: 1rem;
    width: 100%;
    background-color: #C1C1C9;
    color: #C1C1C9;
}

.footer {
    display: flex;
    flex-direction: row;
    height: 3rem;
    justify-content: space-between;
}

.groupName_label {
    margin-top: 1rem;
}

.checkbox_label {
    @include fontStyle("RobotoCondensed", 14px, #666678);
    margin-left: 0.5rem;
    margin-top: 0.1rem;
}

.plan_cost_div {
    display: flex;
    flex-direction: row;
}

#plan_cost {
    width: 22rem;
    max-width: 35rem;
    height: 3rem;
}

body .p-checkbox.p-highlight .p-checkbox-box {
    border-color: #008DD5;
    background-color: #008DD5;
    color: #ffffff;
    font-weight: bolder;
}

.tiers_created {
    @include fontStyle("RobotoCondensed", 14px, #666678);
}

.created_icon {
    color: white;
    font-weight: bolder;
    justify-content: space-around;
    margin-top: 0.1rem;
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
    margin-left: 1rem;
    background-color: #256029;
    margin-right: 0.3rem;
}

.check-icon {
    color: white;
    font-size: 11px;
    font-weight: bolder;
    justify-content: space-around;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 0.6rem;
    margin-right: 0.3rem;
    background-color: #256029;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon {
    visibility: hidden;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    visibility: hidden;
}

.p-icon {
    visibility: visible;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    visibility: visible;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    visibility: visible;
    width: 1rem;
    height: 1rem;
}

.p-treeselect {
    background-color: white !important;
}

.planHeader {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #d0021b;
}

.headerText {
    @include fontStyle("RobotoCondensed-Bold", 18px, #f2f2f2);
    padding: 1rem;
}

.setup_checkbox {
    display: flex;
    flex-direction: row;
    margin: 0.2rem 0rem 0rem -0.4rem;
}

.setup_fee_checkbox_label {
    @include fontStyle("RobotoCondensed-Bold", 13px, #d0021b);
    padding-left: 0.7rem;
    margin-top: 0.2rem;
}

.setup_fee {
    display: flex;
    align-items: center;
    margin: 0rem 0rem 0rem 0.3rem;
}

.plan-title {
    @include fontStyle("RobotoCondensed-Bold", 14px, #000);
}

.field_hint {
    display: flex;
    flex-direction: row;
}

.setup-main-div {
    display: flex;
    flex-direction: row;
    margin: 0rem 0rem 0rem 5rem;
    flex-wrap: wrap;
}

.setupField {
    margin: 0.6rem 0rem 0rem 0.5rem;
}

.setup_fee_hints {
    @include fontStyle("RobotoCondensed", 13px, #495057);
    margin-top: 1rem;
}

.style_error_messages {
    display: flex;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0.3rem;
}

.setup_style_error_messages {
    display: flex;
    align-items: center;
    padding: 0rem 0rem 0rem 0.3rem;
}

.form_div {
    padding: 0rem 1rem 1rem 1rem;
    height: 70%;
}

.billingPeriod_div {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0rem 1rem 0.7rem;
}

.labels_error_message {
    margin-bottom: 0.5rem;
}

.policy_main_div {
    display: flex;
    flex-direction: row;
    padding: 1rem 0rem 1rem 0.7rem;
}

.policy_div {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.name_error_message {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.policy_error_message {
    display: flex;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0.3rem;
}

.cost_error_message {
    display: flex;
    align-items: center;
    margin: 1.5rem 0rem 0rem 0.3rem;
}

.graceDays_reminderDays_div {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.inputFields {
    margin-top: 0.4rem;
}

.policyHelperText_div {
    display: flex;
    align-items: center;
}

.fixed_hint,
.flexible_first_hint,
.subscription_first_hint {
    @include fontStyle("RobotoCondensed", 13px, #9747FF);
    margin-left: 1rem;
    margin-top: 1.5rem
}

.flexible_second_hint,
.subscription_second_hint {
    @include fontStyle("RobotoCondensed", 13px, #495057);
    margin-left: 1rem;
}

.hints_long_texts {
    display: flex;
    text-align: left;
    max-width: 50rem;
    margin-top: 0rem;
}

.plancreate_error_messages {
    @include fontStyle("Roboto", 14px, #73000c);
    font-weight: normal;
    background-color: #ffcdd2;
    border-radius: 3px;
    border-width: 0px;
    padding: 0.9rem;
    margin: 0rem 0rem 0rem 1rem;
}

.plancreate_error_messages,
.plancreate_name_error_messages {
    @include fontStyle("Roboto", 14px, #73000c);
    font-weight: normal;
    background-color: #ffcdd2;
    border-radius: 3px;
    border-width: 0px;
    padding: 0.9rem;
    width: -moz-fit-content;
    width: fit-content;
}

.plancreate_name_error_messages {
    margin: 1rem 0rem 0rem 1rem;
}

.circle_times_icon {
    padding-right: 0.4rem;
}