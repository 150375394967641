@import "../__variables";

.businessHeader {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background-color: #d0021b;
}

.main_card_style {
      width: 100%;
      margin-left: 0rem;
}

.card_div_style {
      padding: 0rem 1rem 0rem 1rem;
}

.main_header {
      @include fontStyle("RobotoCondensed-Bold", 18px, #f2f2f2);
      padding: 1rem;
}

.copy_business_text {
      @include fontStyle("Roboto-Bold", 16px, #000000);
}

.business_label {
      @include fontStyle("Roboto", 11px, #000000);
}

.url {
      @include fontStyle("Roboto-Bold", 14px, #666678);
      margin: 0.9rem 0rem 0rem 0rem;
}

.copy_business_btn.p-button {
      background-color: #008DD5;
      border: #008DD5;
      color: white;
      width: 8rem;
      height: 34px;
      border-radius: 2rem;
      margin: 1.3rem 0rem 0rem 4rem;
}

#download_QR {
      background-color: #008DD5;
      border: #4d8eae;
      color: white;
      width: 220px;
      height: 34px;
      border-radius: 4px;
}

#download_QR:hover {
      background-color: #5fb9e7;
      border: #5fb9e7;
}

.download_QR_heading {
      @include fontStyle("Roboto-Bold", 16px, #000000);
}

.qrCode {
      width: 220px;
      border: 1px solid #000;
      border-radius: 2px;
      margin: 0rem 0rem 1.5rem 1rem;
}

.download_QR_heading_hr {
      margin-top: 1rem;
      height: 1px;
      width: 18rem;
      background-color: #F2F2F2;
}

.preview_txt {
      @include fontStyle("Roboto", 16px, #666678);
      margin: 0rem 0rem 1.5rem 0rem;
      cursor: pointer;
}

.preview_txt:hover {
      text-decoration: underline;
}

.copy_business_text_hr {
      margin: 0.7rem 0rem 1.5rem 0rem;
      height: 1px;
      background-color: #F2F2F2;
}

.generate_pdf {
      @include fontStyle("Roboto-Bold", 16px, #008DD5);
      cursor: pointer;
}

.generate_pdf:hover {
      text-decoration: underline;
}

.business_card {
      border: 2px solid #008DD5;
      border-radius: 3px;
      width: 325px;
      height: 60px;
      margin: 0rem 0rem 0.7rem 0.7rem !important;
      padding: 0.5rem 0rem 4rem 1.2rem;
      display: flex;
      flex-direction: row;
}

.portrait_type {
      @include fontStyle("RobotoCondensed", 12px, #666678);
}

.portrait_type_size {
      @include fontStyle("Roboto Semibold", 14px, #000000);
      margin-top: 0.5rem;
}

.index_for_card {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 1rem 0rem 0rem;
      height: 37px;
      width: 37px;
      border-radius: 50%;
      background-color: #008DD5;
      font-family: "RobotoCondensed-Bold";
      font-size: 24px;
      color: #fff;
}

.div_right_corner {
      margin: 0.5rem 0rem 0rem 0.6rem;
      display: flex;
      flex-direction: row;
}

.card_inner_texts {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
}

.export_poster_hr {
      margin: 0.8rem 0rem 1.6rem 0rem;
      height: 1px;
      width: 100%;
      background-color: #F2F2F2;
}

.pdf_img {
      width: 25rem;
      height: 32rem;
}

.main_div_linkTxts {
      display: flex;
      flex-direction: column;
      margin: 0.3rem 0rem 0rem 2rem;
}