.lang {
	background-color: red;
	display: flex !important;

	@media screen and (max-width: 896px) {
		& .icon {
			margin: 0;
			margin-right: 0.5em;
			width: 1em !important;
			align-items: center;
		}
	}
}

.business-info {
	background-color: #fff;
	font-size: 11px;
	color: #D0021B;
	padding: 2px;
	border: 2px solid #D0021B;
	border-radius: 5px;
	margin: 5px;
	font-weight: bold;
}

.profile {
	width: 1rem;
	height: 6rem;
}

.user-profile-items {
	list-style-type: none;
	padding: 0;
}

// .layout-wrapper .layout-topbar .topbar-menu>li>.user-profile-content::before,
// .layout-wrapper .layout-topbar .topbar-menu>li>.user-profile-content::after {
// 	left: 255px;
// }

@media screen and (max-width: 896px) {

	.layout-wrapper .layout-topbar .topbar-menu:before,
	.layout-wrapper .layout-topbar .topbar-menu:after {
		left: 286px;
	}
}

@media screen and (min-width: 897px) {

	.layout-wrapper .layout-topbar .topbar-menu>li>.user-profile-content::before,
	.layout-wrapper .layout-topbar .topbar-menu>li>.user-profile-content::after {
		left: 270px;
	}
}

@media screen and (min-width: 897px) {
	.layout-wrapper .layout-topbar .topbar-menu>li>ul.user-profile-content {
		min-width: 310px;
	}
}

@media screen and (max-width: 896px) {
	.layout-wrapper .layout-topbar .topbar-menu {
		width: 310px;
	}
}