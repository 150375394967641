.colleague-tab {
    margin-bottom: 20px;
}

.colleague-list {
    border: none;

    &.p-dataview .p-dataview-content>.p-grid>div {
        border-bottom: none;
    }

    &.p-dataview .p-dataview-content,
    & .p-paginator {
        border: none;
        padding: 0;
    }

    &-item {
        border-bottom: none;

        img {
            width: 70px;
            height: 70px;
        }

        .role {
            border-radius: 6px;
            padding-top: .25rem;
            padding-bottom: .25rem;
            padding-left: .5rem;
            padding-right: .5rem;
            font-size: .875rem;
            display: inline-flex;
        }

        .owner {
            color: #008DD5;
            background-color: #d0e1fd;
        }

        .manager {
            color: #a855f7;
            background-color: #ead6fd;
        }

        .viewer {
            color: #14b8a6;
            background-color: #c7eeea;
        }
        .admin {
            color: #e15c5c;
            background-color: #fab3b3;
        }

        .name {
            margin-bottom: .25rem;
            font-size: 1.25rem;
            font-weight: 500;
            color: #000000;
        }

        .title {
            margin-bottom: .9rem;
            font-weight: 500;
            color: #757575;
        }

        .p-button.p-button-secondary.p-button-outlined {
            color: #64748B;
            border: 1px solid #64748B;
            width: 50%;
            margin-right: .5rem;
            margin: 0;
            padding: 0.75rem 1.25rem;
            font-size: 1rem;
            border-radius: 6px;
            cursor: pointer;
        }
    }
}

.table_header {
    color: #666678;
    font-family: "Roboto-bold";
    font-weight: 14px;
}

.table_data {
    font-weight: 12px;
    color: #000000;
}

.colleagues-action {
    display: flex;
    flex-direction: column;
}

#colleague-cancel-button {
    border: 1px solid #E0E0E0;
    color: #008DD5;
    border: none;
    width: 8rem;
    justify-content: center;
    height: 2.7rem;
    background: none;

    .p-button-label {
        text-decoration: underline;
    }
}

#colleague-resend-button {
    border: 1px solid #E0E0E0;
    color: #008DD5;
    border: none;
    margin-bottom: 0.5rem;
    width: 8rem;
    height: 2.7rem;
    justify-content: center;
    background: none;

    .p-button-label {
        text-decoration: underline;
    }
}

#colleague-cancel-button:hover {
    background-color: #C0C0C0;
}

#colleague-resend-button:hover {
    background-color: #C0C0C0;
}

.colleagues_header {
    color: #008DD5;
    font-family: Roboto;
    font-weight: 14px;
    margin-left: 1.2rem;
}
.send_button {
    color: white !important;
    font-family: Roboto !important;
    font-weight:bold !important;
    font-size: 14px !important;
   
    
}

.table-border {
    border: 1px solid #C0C0C0;
    margin-left: 1rem;
}

.colleague_invite{
    background-color: #D0021B !important;
}

.inviteRole >.p-dropdown .p-dropdown-label.p-placeholder{
    padding: 12px !important;
    background-color: white !important;
}

.inviteTable > div >.p-datatable-wrapper {
    padding-top: 0 !important;
}