@mixin font-face($family, $path, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src: url($path + ".ttf") format("truetype");
        font-weight: $weight;
        font-style: $style;
    }
}

// roboto
@include font-face("Roboto", Roboto/Roboto-Regular);
@include font-face("Roboto-Italic", Roboto/Roboto-Italic);
@include font-face("Roboto-Bold", Roboto/Roboto-Bold);
@include font-face("Roboto-BoldItalic", Roboto/Roboto-BoldItalic);
@include font-face("Roboto-Medium", Roboto/Roboto-Medium);
@include font-face("Roboto-MediumItalic", Roboto/Roboto-MediumItalic);
@include font-face("Roboto-Black", Roboto/Roboto-Black);
@include font-face("Roboto-BlackItalic", Roboto/Roboto-BlackItalic);
@include font-face("Roboto-Light", Roboto/Roboto-Light);
@include font-face("Roboto-LightItalic", Roboto/Roboto-LightItalic);
@include font-face("Roboto-Thin", Roboto/Roboto-Thin);
@include font-face("Roboto-ThinItalic", Roboto/Roboto-ThinItalic);
@include font-face("Roboto-SemiBold", Roboto/Roboto-Medium, 600);
@include font-face("Roboto-ExtraBold", Roboto/Roboto-Bold, 800);

//RobotoCondensed
@include font-face("RobotoCondensed", Roboto_Condensed/RobotoCondensed-Regular);
@include font-face("RobotoCondensed-Italic", Roboto_Condensed/RobotoCondensed-Italic);
@include font-face("RobotoCondensed-Bold", Roboto_Condensed/RobotoCondensed-Bold);
@include font-face("RobotoCondensed-BoldItalic", Roboto_Condensed/RobotoCondensed-BoldItalic);
@include font-face("RobotoCondensed-SemiBold", Roboto_Condensed/RobotoCondensed-Bold, 600);
@include font-face("RobotoCondensed-Light", Roboto_Condensed/RobotoCondensed-Light);
@include font-face("RobotoCondensed-LightItalic", Roboto_Condensed/RobotoCondensed-LightItalic);

//RobotoFlex
@include font-face("RobotoFlex", Roboto_Flex/RobotoFlex-Regular);

// RobotoMono
@include font-face("RobotoMono", Roboto_Mono/RobotoMono-Regular);
@include font-face("RobotoMono-Italic", Roboto_Mono/RobotoMono-Italic);
@include font-face("RobotoMono-Bold", Roboto_Mono/RobotoMono-Bold);
@include font-face("RobotoMono-BoldItalic", Roboto_Mono/RobotoMono-BoldItalic);
@include font-face("RobotoMono-SemiBold", Roboto_Mono/RobotoMono-SemiBold);
@include font-face("RobotoMono-SemiBoldItalic", Roboto_Mono/RobotoMono-SemiBoldItalic);
@include font-face("RobotoMono-ExtraLight", Roboto_Mono/RobotoMono-ExtraLight);
@include font-face("RobotoMono-ExtraLightItalic", Roboto_Mono/RobotoMono-ExtraLightItalic);
@include font-face("RobotoMono-Medium", Roboto_Mono/RobotoMono-Medium);
@include font-face("RobotoMono-MediumItalic", Roboto_Mono/RobotoMono-MediumItalic);
@include font-face("RobotoMono-Light", Roboto_Mono/RobotoMono-Light);
@include font-face("RobotoMono-LightItalic", Roboto_Mono/RobotoMono-LightItalic);
@include font-face("RobotoMono-Thin", Roboto_Mono/RobotoMono-Thin);
@include font-face("RobotoMono-ThinItalic", Roboto_Mono/RobotoMono-ThinItalic);
