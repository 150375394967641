@import '../_variables';

input .wizard-forms input {
  @include fontStyle("Roboto", 14px, #666678);
  background-color: transparent;
  width: 100%;
}

.wizard-forms .p-dropdown .p-dropdown-trigger,
.wizard-forms .p-dropdown .p-inputtext {
  @include fontStyle("Roboto", 14px, #666678);
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.wizard-forms {
  display: flex;
  flex-direction: column;

  .PhoneInputCountry {
    display: flex;
  }

  .PhoneInput,
  .p-dropdown {
    width: 100%;
    background-color: white;
  }

  .form-label {
    margin-top: .5rem;
  }

  .setting-edit-tag {
    @include fontStyle("RobotoCondensed-Bold", 11px, #9747FF);
    background-color: transparent;

    span {
      font-size: .8rem;
    }
  }
}

.name-text {
  align-items: start;
}

.bar {
  margin-top: 0.5rem;
  height: 0.1rem;
  background-color: #666678;
}

.business-name {
  margin-right: 1rem;
  margin-bottom: 0;
  font-size: 1.75rem;
  color: #535353;
}

#not_verified_tag {
  background-color: #008DD5;
}

.verification-link {
  text-decoration: underline;
  color: #008DD5;
}

.business-type-div {
  display: flex;
  flex-direction: row;
}

.chip {
  background-color: #008DD5;
  height: 2rem;
  margin-right: 1.5rem;
  color: white;
}

.chip-div {
  display: flex;
  flex-direction: row;
}

.h3-text {
  margin-top: 0.2rem;
  font-size: 18px;
}

.chip-div {
  display: flex;
  flex-direction: row;
  margin-top: 0.6rem;
}

.account_header {
  @include fontStyle("RobotoCondensed-Bold", 18px, #000000);
  margin-bottom: 0;
  padding-top: 0.6rem;
  padding-left: 0;
  padding-bottom: 0.6rem;
}

.label {
  @include fontStyle("Roboto", 11px, #000000);
  color: #000000;
  margin-bottom: 0.5rem;
  margin-top: 0.9rem;
  font-weight: 600;
}

.address-line2 {
  margin-top: 0.3rem;
}

.city {
  margin-bottom: 1rem;
}

.purpose {
  @include fontStyle("Roboto-Bold", 14px, #000000);
  font-weight: bold;
}

.settings-edit-form {

  .p-inputtext:disabled,
  .p-disabled,
  input:disabled {
    @include fontStyle("Roboto", 14px, #666678);
    background-color: lightgray;
    opacity: 0.7;

  }


}

body .p-inputtext {
  background-color: white;
  width: 100%;
}

#edit_button {
  margin-left: auto;
  padding: 0.6rem;
}

.cancel-button {
  background-color: #d0021b !important;
  padding: 0.6rem !important;
}

#phoneNo {
  border: 1px solid lightgray;
  height: 2.22rem;
  border-left: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.body .p-button.p-button-info.edit_button {
  display: flex;
  margin-left: auto;
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #008DD5;
  background-color: #008DD5;
}

.phoneCode {
  display: flex;
  background-color: rgb(199, 199, 199);
  height: 2.2rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: none;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  align-items: center;
  padding: 0.5rem;
  font-weight: bold;
}

.phone-group {
  display: flex;
}

body .p-inputtext {
  @include fontStyle("Roboto", 14px, #666678);
  background-color: white;
  width: 100%;
}

.first-label {
  font-family: "RobotoCondensed-Bold";
  font-size: 18px;
  color: rgb(36, 35, 35);
  margin-bottom: 0.5rem;
}

.wizard-forms {
  display: flex;
  flex-direction: column;

  .wizard-forms {
    display: flex;
    flex-direction: column;

    .PhoneInputCountry {
      display: flex;
    }

    .PhoneInput,
    .p-dropdown {
      width: 50%;
      background-color: white;
    }

    .form-label {
      margin-top: .5rem;
    }

    .setting-edit-tag {
      @include fontStyle("RobotoCondensed-Bold", 11px, #9747FF);
      background-color: transparent;

      span {
        font-size: .8rem;
      }
    }
  }

  .name-text {
    align-items: start;
  }

  .bar {
    margin-top: 0.5rem;
    height: 0.1rem;
    background-color: #666678;
  }

  .business-name {
    margin-right: 1rem;
    margin-bottom: 0;
    font-size: 1.75rem;
    color: #535353;
  }

  #not_verified_tag {
    background-color: #008DD5;
  }

  .verification-link {
    text-decoration: underline;
    color: #008DD5;
  }

  .business-type-div {
    display: flex;
    flex-direction: row;
  }

  .chip {
    background-color: #008DD5;
    height: 2rem;
    margin-right: 1.5rem;
    color: white;
  }

  .chip-div {
    display: flex;
    flex-direction: row;
    margin-top: 0.6rem;
  }

  .h3-text {
    margin-top: 0.2rem;
    font-size: 18px;
  }

  margin-top: 0.6rem;
}

.chip-div {
  display: flex;
  flex-direction: row;
  margin-top: 0.6rem;
}

.account_header {
  @include fontStyle("RobotoCondensed-Bold", 18px, #000000);
  margin-bottom: 0;
  padding-top: 0.6rem;
  padding-left: 0;
  padding-bottom: 0.6rem;
}

.first-label {
  @include fontStyle("Roboto", 11px, #000000);
  color: #000000;
  margin-bottom: 0.5rem;
  margin-top: 0.9rem;
}

.first-label {
  font-family: "RobotoCondensed-Bold";
  font-size: 18px;
  color: rgb(36, 35, 35);
  margin-bottom: 0.5rem;
}

.instruction1-header {
  @include fontStyle("RobotoCondensed-Bold", 13px, #9747ff);
}

.instruction1 {
  @include fontStyle("Roboto", 13px, #000);
}

.instruction1-inner {
  @include fontStyle("Roboto", 13px, #9747ff);
}

.instruction2-header {
  @include fontStyle("RobotoCondensed-Bold", 13px, #9747ff);
}

.instruction2 {
  @include fontStyle("Roboto", 13px, #000);
}

.instruction2-inner {
  @include fontStyle("Roboto", 13px, #9747ff);
}

.payment_heading1 {
  @include fontStyle("Roboto", 14px, #000);
  font-weight: bold;
  margin-bottom: 0.6rem
}

.merchant {
  @include fontStyle("Roboto", 14px, #000);
  font-weight: bold;
}

.payment_heading {
  @include fontStyle("Roboto", 12px, #495057);
  font-weight: bold;
}

.inputs-width {
  padding-right: 6.5rem !important;
}

#merchantEdit-btn {
  padding-top: 3.1rem;
}

#merchantEdit-btn:active,
#merchantEdit-btn:hover,
#merchantEdit-btn:focus {
  padding-top: 3.1rem;
  background-color: #fff !important;
  border: 1px solid #fff !important;
  box-shadow: none !important;
}

#innerEdit-btn:active,
#innerEdit-btn:focus {
  background-color: #fff !important;
  border: none !important;
  box-shadow: none !important;
}

#innerEdit-btn:hover {
  background-color: #fff;
}

#innerEdit-btn {
  padding-top: 2rem;
  margin-right: -7rem !important;
}

.merchant-edit,
.inner-edit {
  display: flex;
  justify-content: flex-end;
  color: #008DD5;
  padding: 0 !important;
}

@media only screen and (max-width: 1024px) {
  .inputs-width {
    max-width: 759px;
  }
}

@media only screen and (min-width: 1670px) {
  .inputs-width {
    max-width: 704px !important;
  }
}

.payment-added-alert {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  @include fontStyle("RobotoCondensed-Bold", 11px, #000);
  padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
  max-width: 853px !important;
  background-color: rgba(179, 229, 252, 0.5);
  border-left: 3px solid #23547B;
  font-weight: 600;
}

.payment_alert {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  @include fontStyle("RobotoCondensed-Bold", 11px, #000);
  padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
  max-width: 853px !important;
  background-color: #ffcdd2;
  border-left: 3px solid #d32f2f;
  font-weight: 600;
}

.payment-added-alert1 {
  @include fontStyle("RobotoCondensed-Bold", 14px, #d0021b);
}

.payment-added-alert2 {
  @include fontStyle("RobotoCondensed-Bold", 14px, #805B36);
  line-height: 1.3rem;
}

.payment-alert2 {
  @include fontStyle("RobotoCondensed-Bold", 14px, #d0021b);
  color: #d0021b !important;

}

.payment-alert1 {
  @include fontStyle("RobotoCondensed-Bold", 14px, #000);
  color: #000 !important;
  line-height: 1.2rem;

}

.payment_teller {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 18px;
}

.payment_token {
  @include fontStyle("RobotoCondensed-Bold", 12px, #9747ff);
}

.payment_card {
  margin-top: 2rem;

}

.payment_input {
  height: 2.6rem;

}

.payment_overlay_list {
  margin: 0.4rem 0;
}

.info-icon {
  cursor: pointer;
  @include fontStyle("Roboto", 12px, #9747ff);
}

.payments_submit_buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.settings_error_message {
  margin-bottom: 0.7rem;
  padding: 0.6rem;
  background-color: #ffcdd2;
  color: #73000c;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
}


.settings-card .p-card {
  width: 90%;
}

.settings-tab .p-card {
  height: 2.4rem !important;
  margin-left: 1rem;
  padding: 1rem;
}

.settings-tabs>.p-tabmenu {
  margin-top: 0 !important;
}

.settings-tabs>div>ul {
  height: 2.1rem !important;
  margin-left: 2rem !important;
  margin-bottom: 1rem;
  padding: 1rem;
}

.payment-main>div>div>.p-card-content {
  padding: 1rem;
  @include fontStyle("Roboto", 13px, #000000);
}

#payment_merchantId {
  @include fontStyle("Roboto", 13px, #000000);
}

.payment_card {
  border-radius: 0.6rem !important;
}

.payment-inputs {
  width: -webkit-fill-available;
}

.payment_input {
  border-radius: 4px !important;
}

.payment-credential {
  @include fontStyle("RobotoCondensed-Bold", 13px, #000);
}

.input-label {
  margin-right: 1.5rem;
  @include fontStyle("Roboto", 14px, #000);
}

.img-shadow {
  width: 8rem;
  height: 8rem;
  background-color: #C1C1C9;
  border-radius: 50%;
}

.upload-container {
  display: flex;
  gap: 25px;
}

.upload-button {
  margin-top: 4.15rem;
  color: #fff;
}

.png-cont {
  margin: -4rem 0rem 0rem 10.9rem;
}

.label-styles {
  @include fontStyle("Roboto-Bold", 11px, #000)
}

.label-bold {
  font-weight: bold;
  margin-right: 2px;
}

.level {
  margin-top: 4.15rem;
  color: #fff;
  background-color: #ffcdd2;
  color: #73000c;
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  margin-left: 1.2rem;
  border-radius: 5px;
  align-items: center;
  padding: 4.5px 4.5px 3px 4.5px;
}

.level-1 {
  color: #fff;
  background-color: #ffcdd2;
  color: #73000c;
  width: max-content;
  height: fit-content;
  margin: 3px 0 0 -3.3rem;
  padding-block: 7.5px;
  padding-inline: 4px;
  border-radius: 2px;

}

@media (min-width:769px) and (max-width: 1024px) {
  .level-1 {
    width: 12rem;
    margin: 0.2rem 0rem 0rem -3rem;
  }

  #phoneNo {
    width: 15rem !important;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .level {
    margin-top: 4.15rem;
    color: #fff;
    background-color: #ffcdd2;
    color: #73000c;
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    margin-left: 1.25rem;
    border-radius: 5px;
    align-items: center;
    padding: 4.5px 4.5px 3px 4.5px;
  }

}

@media (max-width: 425px) {
  .level {
    margin: 7.15rem 0rem 0rem -5.8rem;
  }

  .png-cont {
    margin: 0.3rem 0rem 0rem 0.5rem;
  }

  .level-1 {
    width: 12rem !important;
    margin: 2.4rem 0 0 -21.2rem !important;
    padding-inline: 6px !important;
  }
}

.level-2 {
  color: #fff;
  background-color: #ffcdd2;
  color: #73000c;
  width: 12rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 12px;
  padding-block: 0.5rem;
  padding-left: 8px;
  margin-top: 0.2rem;
  border-radius: 2px;
  align-items: center;
}

.p-button.p-fileupload-choose {
  width: 10rem;
  border-color: #008DD5;
  background-color: #008DD5;
}

.p-fileupload-choose:hover {
  background-color: #2196F3 !important;
  border-color: #2196F3 !important;
}

.png-text {
  margin-top: 2px;
  margin-left: 1px;
  font-size: 11px;
}

.png {
  @include fontStyle("Roboto-Bold", 12px, #000);
}

.transparent {
  margin-top: 7.2rem;
  margin-left: -9.8rem;
}

.png-text-4 {
  margin-top: 3.5px;
  font-weight: bold;
  margin-left: 2px;
}

.png-text-1 {
  margin-top: 3.5px;
  font-weight: bold;
}

.info-div {
  display: flex;
  flex-direction: column;
}

.info-name {
  @include fontStyle("Roboto-Bold", 18px, #000000);
  margin-bottom: 0.7rem;
}

.info-span {
  @include fontStyle("Roboto-Bold", 12px, #495057);
  margin-bottom: 1.5rem;
}

.info-text {
  @include fontStyle("Roboto-Bold", 14px, #000000);
  margin-bottom: 0.7rem;
}

.info-type {
  @include fontStyle("RobotoCondensed-Bold", 11px, #9747FF);
  background-color: rgba(151, 71, 255, 0.25);
  width: fit-content;
  height: fit-content;
  margin-left: 0.8rem;
  margin-block-end: 1px;
  padding: 3px 9px;
  border-radius: 4px;
}

.logo-container {
  width: 100px;
  height: 100px;
  object-fit: "cover";
  border-radius: 50%;
}

.second-row {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 3rem;

}

.business_info_text {
  @include fontStyle("Roboto", 14px, #666678)
}

.remove-link {
  color: #000;
  font-weight: bold;
}

.remove-link:hover {
  font-weight: bold;
  color: #73000c;
  text-decoration: underline;
  cursor: pointer;
}

#merchant_type,
#business_type,
#country_business,
#regionState {
  width: 60%;
  height: 2.5rem;
}

#address2,
#address1,
#email,
#industry,
#business_postcode1,
#city {
  width: 85%;
  height: 2.5rem;
}

.disabled-dropdown .p-dropdown-trigger {
  display: none;
}

.error_credentials_div {
  border-left: 5px solid #ff5757;
  background-color: #FFE7E6B3;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
  min-width: 47rem;
  width: 47rem;
}

.payment_error_info {
  @include fontStyle("Roboto-bold", 16px, #ff5757);
  padding: 1.7rem 10rem 1.7rem 1rem;
  line-height: 0rem;
}

.success_credentials_div {
  background-color: #E4F8F0B3;
  border-radius: 5px;
  border-left: 5px solid #1ea97c;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
  min-width: 47rem;
  width: 47rem;
}

.payment_success_info {
  @include fontStyle("Roboto-bold", 16px, #1ea97c);
  padding: 1.7rem 10rem 1.7rem 1rem;
  line-height: 0rem;
}

.progress_credentials_div {
  background-color: #FFF2E2B3;
  border-radius: 5px;
  border-left: 5px solid #cc8925;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
  min-width: 47rem;
  width: 47rem;
}

.progress_info {
  @include fontStyle("Roboto-bold", 16px, #cc8925);
  padding: 1.7rem 10rem 1.7rem 1rem;
  line-height: 0rem;
}

.info-icon-div {
  margin-top: 0.3rem;
}

.info-icon-i {
  @include fontStyle("Roboto", 12px, #9747ff);
}

#merchantId-label,
#passCode-label,
#apiKey-label,
#apiUser-label {
  @include fontStyle("Roboto-bold", 13px, #000000);
}

.apiUser-div-width {
  max-width: 48.5rem;
  width: 48.5rem;
}

.icon-main-div {
  margin-top: 0.3rem;
}

#bottom_buttons_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#default-width {
  max-width: 759px;
}

#error-width {
  max-width: 660px;
}

.i-text {
  padding: 0.15rem 0.5rem 0rem 0rem;
}

.inputs-mb {
  margin-bottom: 2rem;
}