.p-datatable-header {
    background-color: white !important;
    border: 1px solid white !important;
}

#submit-button {
    background-color: white !important;
    border: 1px solid lightgray;
    color: rgb(68, 68, 240);
}

#data_table .p-datatable-tbody>tr>td {
    text-align: center !important;
}

#close_search_button {
    background-color: red !important;
}

.tab_view .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #e91e63 !important;

}

.activity-badge {
    border-radius: 2px;
    margin-top: .55rem !important;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: .3px;
    background-color: #FFCDD2;
    contain: content;
}

.activity-badge.status-active {
    background: #C8E6C9;
    color: #256029;
}

.activity-badge.status-subscribed {
    background: #C8E6C9;
    color: #256029;
}

.activity-badge.status-missed {
    background: #C8E6C9;
    color: #256029;
}

.activity-badge.status-renewed {
    background: #C8E6C9;
    color: #256029;
}

.activity-badge.status-paused {
    background: #C8E6C9;
    color: #256029;
}

.activity-badge.status-completed {
    background-color: #D8D8DE;
    color: #000;
}

.datepicker>input {
    padding: 0.8rem;
}

.datepicker>button {
    padding: 0.8rem !important;
    background-color: #008DD5;
    border-color: #008DD5;
}

.datepicker>button :hover {
    background-color: #008DD5 !important;
    border-color: #008DD5 !important;
}

.datepicker>.p-button:not(a):not(.p-disabled):hover {
    background-color: #2fb0f0 !important;
    border-color: #2fb0f0 !important;
}

.datepicker>.p-button:enabled:focus {
    box-shadow: 0 0 0 0.2em #2fb0f0 !important;
}

.activity-badge.status-expired {
    background: #FFCDD2;
    color: #ec2525;
    width: -moz-fit-content;
    width: fit-content;
}

.activity-badge.status-cancelled {
    color: #ec2525;

}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
    color: #e91e63;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    margin-bottom: -1px;
    border: none;
    background-color: rgb(218, 49, 49);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: #99848b;
    border: 1px solid #e91e63;
}

body .p-tabmenu .p-tabmenu-nav {
    padding: 0;
    background: none;
    border-bottom: none;
}

.tab-success .p-highlight:hover {
    background-color: rgb(30, 133, 64) !important;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    margin-bottom: -1px;
    border: none;
    background-color: #e91e63;
    color: white;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
    color: white;
}

body .p-fluid .p-inputgroup .p-button.search_button {
    background-color: transparent;
    color: #495057 !important;
    border-color: transparent;
    border: 1px solid lightgray;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: 0;
    width: 2rem;
}

.dropdown .p-placeholder {
    font-family: roboto !important;
    font-size: 14px !important;
    background-color: transparent;
    color: #495057 !important;
    padding: 0.8rem;
    text-align: center;
}

body .p-fluid .p-inputgroup .p-button.submit-button {
    background-color: #008DD5 !important;
    font-family: roboto !important;
    border: 1px solid lightgray;
    border-radius: 0.3rem;
    width: 7rem;
    margin: 0.5rem;
    font-size: 14px !important;
    margin-bottom: 1rem;
    padding: 0.8rem;
}

.p-inputgroup>.p-inputwrapper:last-child>.p-inputtext {
    display: flex;
    margin: auto;
}

.p-card .p-card-body {
    padding: 0rem 0.5rem !important;
}

.p-card .p-card-content {
    padding: 0rem;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #495057;
}

.activity-actions {
    margin: 1.5rem 5px;


    &-container {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        display: flex;
        flex-wrap: wrap;
    }

    &-count {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
        text-align: justify;
        padding: 0;
        justify-content: space-around;

        &-number {
            font-family: Roboto;
            font-size: 14px;
            margin-right: 0.4rem;
            color: #000000;
        }

        &-text {
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            margin-right: 1rem;
            color: #495057;
        }
    }

    &-btns {
        height: 3rem;
        width: max-content;
        display: flex;
    }

    &-btn {
        height: 3rem;
        width: max-content;
    }

    .cancel_selection_btn {
        width: 100%;

        span {
            white-space: nowrap;
        }
    }
}

.table-checkbox-header {
    height: 100%;

    .p-checkbox-box {
        margin-left: 0.4rem;
    }
}

#planName {
    font-family: Roboto;
    font-weight: 700;
    color: #000000;
    font-size: 14px;
    margin-bottom: 0.5rem
}

#planPeriod {
    margin-bottom: 5px;
    color: #000000;
    font-weight: bold
}

#groupName {
    font-family: Roboto;
    font-size: 11px
}

#entCustomerName {
    font-family: Roboto;
    font-size: 14px;
    color: #000000;
    margin-bottom: 0.5rem
}

#entCustomerNumber {
    color: #000000;
    font-weight: bold
}

#entCustomerEmail {
    font-family: Roboto;
    font-size: 11px;
    color: #495057
}

.subscriptionBodyTemplate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.referenceBodyTemplate {
    display: flex;
    flex-direction: column
}

.statusBodyTemplate {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.customerBodyTemplate {
    display: flex;
    flex-direction: column
}

.p-fluid .p-inputgroup .search_input {
    font-family: roboto !important;
    font-size: 14px !important;
    height: 3rem;
    min-width: 17rem;
    color: #495057;
    background-color: transparent;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 0;
}

body.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
    color: #495057;
    background-color: transparent;
    border-right: 0;
    height: 3rem
}

body .p-fluid .p-inputgroup .p-button.search_button {
    border-color: transparent;
    border: 1px solid lightgray;
    border-left: 0;
    width: 2rem;
    margin-right: 1rem
}

.activity-dropdown {
    height: 3rem;
    text-align: center;
    background-color: transparent !important;
    color: #495057;
    border-radius: 4px !important;
    width: 20rem;
}

.p-dropdown-trigger {
    background-color: transparent !important;
    color: #495057;
}

#time {
    margin-right: 1rem;
}

#filters-card {
    margin-bottom: 0.8rem;
    margin-left: 1rem;
    min-width: 98.4%;
}

#dropdown-filters {
    margin-bottom: 0.8rem;
    margin-left: 0rem;
    min-width: 99%;
}

.main-div {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.count-no-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    text-align: justify;
    padding: 0;
    justify-content: space-around
}

#count-no {
    font-family: Roboto 800;
    font-size: 14px;
    margin-right: 0.4rem;
    color: #000000
}

#subs-title {
    font-family: Roboto bold;
    font-size: 14px;
    margin-right: 1rem;
    color: #495057
}

.input-div {
    margin: 0.5rem;
    margin-left: 0
}

.filters {
    margin: 0.5rem;
}

.export-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.activity-actions-count-text {
    margin-right: 0.5rem;
}

.display {
    border-radius: 4px;
}

.status-outline {
    border-radius: 0px;
    padding: 5px 10px 5px 10px;
    font-family: "Roboto";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
    border-radius: 4px;
    display: flex;
    width: 6rem;
    margin-left: 10px;
    margin-right: 10px;
}

.plan-group-text {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    color: #D0021B;
}

.amount-template {
    color: #000
}

.ref-text {
    color: #495057;
    font-family: Roboto;
    font-size: 11px;
}

.hov {
    cursor: pointer;

    &:hover {
        color: #2196F3;
    }
}

.status-content {
    height: 30px;
    text-align: center;
    align-items: center;
    border-radius: 30px !important;
    font-size: 12px;
    width: 100px;
}

.payout_channel-buttonsC {
    margin-right: 0.1rem;
}