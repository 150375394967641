.body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 82vh;
    margin: 0;
    background-color: #f4f4f4;
  }

  .verification-container {
    text-align: center;
    padding: 50px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "RobotoCondensed";
  }

  h2 {
    color: #333333;
  }

  p {
    color: #666666;
  }

  .verification-link {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
  }
  .verification-title {
    font-size: 38px;
    margin-bottom: 2.2rem;
    font-family: "RobotoCondensed";
  }
  .verification-desc {
    font-size: 18px;
    font-family: "RobotoCondensed";
  }

  .verif-button{
    font-family: "RobotoCondensed";
  }