@import "../__variables";

.dropdown {
	.p-placeholder {
		background-color: white !important;
	}

	padding: 0 !important;
}

#country {
	width: 80% !important;

	.p-dropdown-trigger {
		height: 100%;
		background-color: white !important;
		color: #666678;
	}
}

.wizard-button {
	margin-top: 2rem !important;
}

.wizard-body .wizard-content {
	height: auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 1024px) and (max-height: 1024px) {
	.wizard-logo>img {
		width: 7.5em;
		height: 5.2em;
		text-align: left !important;
		margin-bottom: 4.7em !important;
	}

	.wizard-logo {
		margin-left: 0em !important;
		text-align: left !important;
	}

	.wizard-header {
		text-align: justify !important;
		align-items: flex-start !important;
	}

	.wizard-body {
		text-align: justify !important;
		align-items: flex-start !important;
	}

	.wizard-content>wizard-card {
		margin-top: 20em !important;
	}
}

@media only screen and (max-width: 600px) {
	#logo {
		margin-left: 0em;
	}

	.wizard-card {
		width: 100% !important;
	}

	.wizard-card-content {
		max-height: 100% !important;
	}

	.wizard-card,
	.wizard-wrapper {
		height: 100%;
	}

	.wizard-header {
		height: 2rem;
	}
}

@media (max-width: 990px) {
	.wizard-body .wizard-content .wizard-card .wizard-card-content {
		max-height: 100%;
	}
}

.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner {
	display: flex;
	height: 3.5rem;
	background-color: #D0021B;
	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
}

.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms input {
	background-color: white;
	border: 1px solid #527382;
	color: #212121;
	width: 100%;
}

.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .p-dropdown .p-dropdown-trigger,
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .p-dropdown .p-inputtext {
	background-color: #f8f9fa;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	color: #212121;
}

.p-dropdown-trigger {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.wizard-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 100vh;
}

.wizard-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.p-dropdown .p-dropdown-trigger {
	background-color: white !important;
	color: #495057;
}

.drop_down {
	background-color: white;
}

.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .p-dropdown .p-inputtext {
	background-color: white;
	border-radius: 2px;
	@include fontStyle("Roboto", 14px, #666678);
}

.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .p-dropdown .p-dropdown-trigger {
	background-color: white;
	@include fontStyle("Roboto", 14px, #666678);
}

#business_profile_email {
	@include fontStyle("Roboto", 14px, #666678);
	width: 90%;
}

#business_city {
	@include fontStyle("Roboto", 14px, #666678);
	width: 90%;
}

#name {
	@include fontStyle("Roboto", 14px, #666678);
}

#business_name {
	width: 90%;
}

#business_phoneNo {
	@include fontStyle("Roboto", 14px, #666678);
	border-radius: 2px;
}

.business_inputs {
	@include fontStyle("Roboto", 14px, #666678);
}

.doc_tag,
.doc_tag_bold {
	@include fontStyle("RobotoCondensed", 11px, #000);
	background-color: transparent;
	margin-top: 0.5rem;
}

.doc_tag_container {
	row-gap: 2px;
}

.doc_tag_bold {
	font-weight: bold;
	color: #000000;
	margin-left: 0.12rem;
	margin-right: 0.13rem;
}

.continue-btn,
.continue-button {
	display: flex;
	justify-content: center;
}

#header {
	margin: auto;
	justify-content: center;
	width: auto;
	font-weight: bold;
	@include fontStyle("RobotoCondensed", 18px, #000000);
}

.business_header_text {
	@include fontStyle("Roboto-SemiBold", 14px, #000);
	padding: 2rem 0rem;
}

.business_profile_info {
	background: #fff;
	border: 1px solid #eee;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	margin-bottom: 16px;
	padding: 1.5rem;
	border-radius: 0.3rem;
	margin-bottom: 1rem;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
	border-color: #008DD5;
	background-color: #008DD5;
}

body .step-box-post {
	border-color: #ced4da;
	height: 3rem;
	background-color: #f2f2f2;
}

body .step-box {
	z-index: 1;
	height: 3rem;
	border-radius: 6px;
	border-width: 1px;
	border-style: solid;
	padding: 1rem;
	align-items: center;
	display: flex;
	background-color: #fff;
}

#pi-book {
	color: #9747FF;
	font-size: 12px;
}

.first_hr_business {
	background-color: #C1C1C9;
	height: 1px;
	margin-top: 2.5rem;
	margin-bottom: 0rem;
}

.business_last_hr {
	background-color: #C1C1C9;
	height: 1px;
	width: 98%;
	margin-left: 0.5rem;
	margin-top: 3rem;
}

.country_code {
	background-color: lightgray;
	color: #495057;
	font-weight: bold;
	display: flex;
	align-items: center;
	padding: 5px;
}

.create_business_btn {
	width: 10rem;
	width: -moz-fit-content;
	width: fit-content;
	height: 2.5rem;
	background-color: #008DD5;
	margin-left: auto;
}

#business_address1-required {
	@include fontStyle("Roboto", 14px, #666678);
	width: 90%;
}

#business_address2-required {
	@include fontStyle("Roboto", 14px, #666678);
	width: 90%;
}

#type {
	@include fontStyle("Roboto", 14px, #666678);
	padding: 0;
}

.create_business_account {
	@include fontStyle("Roboto-bold", 16px, #fff);
}

.business-profile-content {
	padding: 1.2rem 0rem 0rem 1.6rem;
}

.main-business-card {
	padding: 2rem;
	background-color: #fff;
}

#merchant_type,
#business_type,
#country_business,
#regionState {
	width: 60%;
}

#business_type {
	height: 2.5rem;
}

.phoneNumber_div {
	height: 2.5rem;
	width: 60%;
	display: flex;
	flex-Direction: row;
}

#business_postcode {
	width: 90%;
}

.business_png {
	@include fontStyle("RobotoCondensed-bold", 11px, #000);
}

.error_phone_required {
	@include fontStyle("Roboto", 13px, red);
}

.phone_required {
	@include fontStyle("Roboto", 13px, #000);
}

#file-upload:hover {
	background-color: white;
}

.configure_buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}