$success:#4CAF50;
$error:#d32f2f;
$warning:#FFC107;

$rev_pri:#D0021B;
$rev_sec:#FE3031;
$rev_yellow:#FCBA04;
$rev_blue:#008DD5;
$rev_black:#131200;
$rev_gray:#495057;
$rev_green:#66bb6a;


@mixin fontStyle($family, $size, $color, $weight: normal) {
    font-family: $family;
    font-size: $size;
    color: $color;
    font-weight: $weight;
}