@import "../__variables";

.p-message.p-message-success {
    background: #e4f8f0;
    border: solid #1ea97c;
    border-width: 0 0 0 6px;
    color: #1ea97c;
}

.p-message .p-message-wrapper {
    padding: 1.25rem 1.75rem;
}

.not_verified_notice {
    border: solid #2196f3;
    border-width: 0 0 0 6px;
    height: 65px;
    padding: 1.6rem;
    background: #b3e5fc;
}

.p-message-summary {
    color: #23547b;
    text-decoration: underline;
    cursor: pointer;
}

.verified_notice {
    height: 65px;
    padding: 1.6rem;
    border: solid #256029;
    border-width: 0 0 0 6px;
    background: #c8e6c9;
}

.p-message-summary-verified {
    @include fontStyle("Roboto", 14px, #1e4620);
}

.close-icon {
    color: #1e4620;
    padding: 0.5rem;
}

.in_review {
    height: 65px;
    padding: 1.6rem;
    border: solid #d5a326;
    border-width: 0 0 0 6px;
    background: #feedaf;
}

.p-message-summary-in-review {
    color: #8a5340;
}

.payment_credentials_txt {
    @include fontStyle("Roboto", 14px, #d0021b);
    cursor: pointer;
    text-decoration: underline;
    margin-left: 0.5rem;
}

.merchant-onboarding-div {
    padding: 1rem 2rem 3rem 2rem;
}

.merchant-onboarding-footer {
    display: flex;
    flex-direction: row;
}

.hello_name_div {
    padding: 0rem 0rem 1rem 0rem;
}

.hello_name {
    @include fontStyle("Roboto-Bold", 20px, #23547b);
}

.welcome_div {
    padding: 0rem 0rem 1rem 0rem;
}

.blue_texts {
    @include fontStyle("Roboto-Bold", 14px, #008DD5);
}

.dark_texts {
    @include fontStyle("Roboto-Bold", 14px, #495057);
}

.apply_txt_header {
    padding-bottom: 1rem;
}

.blue_big_texts {
    @include fontStyle("Roboto-Bold", 16px, #008DD5);
}

.notification_red_txt {
    @include fontStyle("Roboto-Bold", 14px, #d0021b);
}

.payswitch_txt {
    @include fontStyle("Roboto-Bold", 11px, #d0021b);
}

.notification_hr {
    margin: 2.5rem 0rem 2rem 0rem;
    height: 2px;
    width: 100%;
    background-color: #F2F2F2;
}

.tiny_hr {
    background-color: #906b46;
}

.brown_text_style {
    @include fontStyle("Roboto-Bold", 11px, #906b46);
}

.highlight {
    background-color: #ffd8b2;
    height: 4rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}

.hightlight_txts {
    padding: 1rem 1rem 0rem 1rem;
}

.purple_styles {
    @include fontStyle("Roboto-Bold", 14px, #9747FF);
}

.the_teller {
    @include fontStyle("Roboto-Bold", 11px, #23547b);
}

#notification_copy_btn {
    @include fontStyle("RobotoCondensed-Bold", 11px, #9747FF);
    background-color: none !important;
    cursor: pointer;
}

#notification_copy_btn:hover {
    @include fontStyle("RobotoCondensed-Bold", 11px, #9747FF);
    background-color: none !important;
    cursor: pointer;
    text-decoration: underline;
}

.side_thin_hr {
    height: 4rem;
    width: 5px;
    background-color: #8a5340;
}

.businessId_div {
    background-color: rgba(204, 232, 247);
    border-radius: 3px;
    height: 2.5rem;
}

.businessId {
    @include fontStyle("Roboto-Bold", 28px, #d0021b);
    letter-spacing: 0.2rem;
    margin-right: -0.2rem;
}

#start_application_btn {
    @include fontStyle("Roboto-Bold", 14px, #ffffff);
    background-color: #D0021B;
    height: 2.5rem;
    margin-left: auto;
}

.startApplication_href {
    @include fontStyle("Roboto-Bold", 14px, #ffffff);
}

.startApplication_href:hover {
    @include fontStyle("Roboto-Bold", 14px, #ffffff);
}

#start_application_btn:hover {
    @include fontStyle("Roboto-Bold", 14px, #ffffff);
    cursor: pointer;
    background-color: #e92138;
}

.business_id-copy {
    display: flex;
    flex-direction: row;
}