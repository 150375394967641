@import "font/Font.scss";
@import "./_variables";

.p-datatable-responsive-table {
    padding: 1rem;

    .p-checkbox-box {
        width: 16px !important;
        height: 16px !important;
        border: 1px solid #333 !important;

        &.p-highlight {
            background-color: #fff !important;
            color: #333;
            border-color: #000 !important;
        }

        .p-checkbox-icon {
            color: #333;
            font-size: 12px !important;
        }
    }

    .p-datatable-tbody>tr>td {
        color: #666678;
        border: 0;
        border-bottom: 1px solid #e0e0e0;
    }

    .p-datatable-tbody>tr {
        background-color: #fff !important;
    }

    .p-datatable-tbody>tr.p-highlight {
        background-color: #f5f5f5f5 !important;

        td {
            border: 0;
        }
    }

    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
        background-color: #304dae42 !important;
    }

    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
    .p-sortable-column.p-highlight {
        background-color: #304dae42 !important;
        color: #666678;
    }
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


body .p-datatable .p-datatable-thead>tr>th {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 0.001rem solid #dadae8;
}

// body .p-inputswitch .p-inputswitch-slider {
//     background-color: #467fb0 !important;
//     border-color: #949ba1;
//     height: 1.4rem;
//     width: 2.6rem;
//     border-radius: 18px;
// }

// body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
//     background-color: #b0d683 !important;
//     border-color: #b0d683;
//   }

.bold-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;

    p {
        color: #000;
    }
}

.detail-navigation {
    margin: 0;
    color: black;
    cursor: pointer;

    &.tier {
        color: #000;
        margin-bottom: 3px;
        font-weight: bold;
    }

    &:hover {
        color: #2196f3;
    }
}

.status-accepted {
    background-color: #c8e6c9;
    color: #c8e6c9;
}

.status-completed,
.status-processed,
.status-complete {
    background-color: #D8D8DE;
    color: #000;
}

.status-active {
    background-color: #c8e6c9;
    color: #256029;
    border-radius: 10px;
    padding: 4px 25px !important;
    font-family: "Roboto";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
}

.status-suscribed {
    background-color: #c8e6c9;
    color: #256029;
}

.status-renewed {
    background-color: #c8e6c9;
    color: #256029;
}

.status-cancelled {
    text-decoration-color: white;
    background-color: #ffcdd2;
}

.status-expired {
    text-decoration-color: white;
    background-color: #ffcdd2;
}

.status-missed {
    background-color: #feedaf;
    border-radius: 10px;
    padding: 5px;
    color: #8a5340;
    font-family: "Roboto";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
}

.status-reversed {
    background-color: #FFCDD2;
    border-radius: 10px;
    padding: 5px;
    color: #C63750;
    font-family: "Roboto";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
}

.status-pending {
    background-color: #ffd8b2;
    color: #805b36;
}

.status-available {
    background-color: #b2eaff;
    color: #366180;
}

.status-error {
    background-color: #ffcdd2;
}

.status-AVAILABLE {
    color: $rev_blue;
    font-family: "Roboto";
    font-weight: bold;
    background-color: #b2eaff;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-size: 12px;
}

.status-PENDING {
    color: #8a5340;
    background-color: #ffd8b2;
    font-family: "Roboto";
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-size: 12px;
}

.status-EXPIRED {
    color: $rev_pri;
    font-family: "Roboto";
    font-weight: bold;
    background-color: #ffcdd2;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-size: 12px;
}

.status-renewed,
.status-suscribed,
.status-complete,
.status-completed,
.status-processed,
.status-pending,
.status-accepted,
.status-available,
.status-error {
    border-radius: 10px;
    padding: 5px 19px;
    font-family: "Roboto";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
    width: -moz-fit-content;
    width: fit-content;
}

.status-pending {
    border-radius: 10px;
    padding: 5px 25px !important;
    font-family: "Roboto";
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
    width: -moz-fit-content;
    width: fit-content;
}

.status-expired,
.status-cancelled {
    border-radius: 10px;
    font-family: "Roboto";
    font-size: 10px;
    color: #c63750;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 20px;
}

.status-paused {
    border-radius: 10px;
    padding: 5px 23px !important;
    font-family: "Roboto";
    color: #8a5340;
    font-size: 10px;
    background-color: #feedaf;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
}

.status-badge {
    border-radius: 10px !important;
    padding: 5px;
    font-family: "Roboto" !important;
    font-size: 10px !important;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;
}

* {
    font-family: Roboto;
    font-size: 14px;
}

.dot {
    height: 4px;
    width: 4px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-top: 15px;
    margin-left: -8px;
}

body .p-radiobutton.p-highlight .p-radiobutton-box {
	border-color: #7fbed7;
	background-color: #2196f3;
	color: #ffffff;
  }
  body .p-radiobutton.p-highlight .p-radiobutton-box .p-radiobutton-icon {
	background-color: #ffffff;
  }

body .p-inputtext:enabled:focus,
body .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus,
body .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem #a6d5fa !important;
    border-color: #2196f3 !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #d0021b;
    border-color: #d0021b;
    color: #fff;
}

body .p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem #495057;
    outline: 0 none;
}

body .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #e9ecef;
    color: #495057;
}

body .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #e9ecef;
    color: #000;
}

body .p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: #000;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
body .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #000;
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    justify-content: left;
}

.p-dialog .p-dialog-footer {
    background: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: none !important;
    color: #323130;
    padding: 0.571rem 1rem;
    text-align: right;
}

.p-dialog .p-dialog-header {
    background: #d0021b;
    border-bottom: none !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #ffffff;
    padding: 1rem;
}

.defaultDial.p-dialog .p-dialog-header {
    background-color: #d0021b;
    height: 3rem;
    padding: 0.5rem;
}

.p-column-filter-overlay-menu div.p-column-filter-constraint,
.p-column-filter-overlay-menu div.p-column-filter-buttonbar {
    padding: 0px;
}

.infoCard {
    padding: 2rem;

    .green {
        color: $rev_green !important;
    }

    .red {
        color: $rev_sec !important;
    }

    .yellow {
        color: #ffb300 !important;
    }

    .black {
        color: #000 !important;
    }

    .gray {
        color: $rev_gray !important;
    }

    .blue {
        color: $rev_blue !important;
    }

    .h1 {
        @include fontStyle("Roboto-Bold", 20px, #000);
    }

    .label1 {
        @include fontStyle("RobotoCondensed-Bold", 16px, $rev_gray);
    }

    .value1 {
        @include fontStyle("Roboto", 16px, #000);
    }

    .subtext1 {
        @include fontStyle("RobotoCondensed-Bold", 12px, #000);
    }

    .label2 {
        @include fontStyle("Roboto-Bold", 12px, #000);
    }

    .label3 {
        @include fontStyle("Roboto-Bold", 16px, #000);
    }

    &.p-card .p-card-body {
        padding: 0px;
    }

    &.p-card .p-card-content {
        padding: 0px;
    }

    .grid {
        margin-left: 0px;
        margin-right: 0px;
    }

    .gab {
        margin-top: 1.5rem;
    }
}

.p-dialog .p-dialog-header {
    background-color: #d0021b !important;
    color: #ffffff !important;
}

.p-treeselect {
    background-color: white !important;
}

.main_div_action_btns {
    display: flex;
    justify-content: space-between;
    height: auto;
    padding: 1rem 0.3rem 1rem 0.3rem;
    flex-direction: row;
    flex-wrap: wrap;
}

.actionBar_btns {
    background-color: transparent !important;
    border: 1px solid #d0021b !important;
    border-radius: 5px !important;
    color: #d0021b !important;
    padding: 0rem 0.5rem !important;
    height: 3rem;
}

.actionBar_btns:hover {
    background-color: #d0021b !important;
    color: #fff !important;
}

#global_search {
    background-color: transparent !important;
    border: 1px solid #d0021b !important;
    border-radius: 3px !important;
    color: #495057 !important;
    height: 3rem;
    min-width: 25rem;
}

.global_search_btn {
    margin-left: -2.7rem !important;
    color: #d0021b !important;
}

#create_group {
    margin-right: 1rem;
}

.route-bar-div {
    display: flex;
    align-items: center;
    border: none;
    padding: 0.5rem;
    justify-content: space-between;
}

.hr_customers {
    max-width: 100%;
    height: 2px;
    background-color: #D9D9D9;
    margin-top: 0rem;
    margin-bottom: 0rem !important;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    padding: 0.6rem 1.25rem 0rem 1.25rem !important;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    background-color: #e0e0e0 !important;
}


#customersCancelBtn {
    color: #d0021b;
    background-color: transparent;
    border: none;
}

#customersCancelBtn :active {
    color: #d0021b !important;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

#customersSearchBtn {
    color: grey;
    background-color: transparent;
    border: none;
}

.customerIdAutoComplete {
    @include fontStyle("Roboto-Bold", 12px, #008DD5);
    padding-right: 3.1rem;
}

.customerRefAutoComplete {
    @include fontStyle("Roboto-Bold", 12px, #000);
}

.customerNameAutoComplete {
    @include fontStyle("Roboto-Bold", 14px, #000);
}

.customerPhoneNoAutoComplete {
    @include fontStyle("Roboto-Bold", 12px, #495057);
    padding-right: 5rem;
    padding-bottom: 0.4rem;
}

.customerEmailAutoComplete {
    @include fontStyle("Roboto-Bold", 12px, #495057);
    padding-bottom: 0.4rem;
}

.p-autocomplete-items-wrapper {
    max-height: 400px !important;
}

.business-account-id {
    font-family: 'Roboto-Bold';
    font-size: 20px;
    color: #fff;
}

.id-container {
    padding: 12px 0px 0px 18px;
}

.layout-wrapper .layout-menu-container .layout-menu {
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 8px 12px 0;
}

.setup_fee_main_div {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 0.3rem;
}

.setup_fee_div {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
}

.setup_fee_tag {
    @include fontStyle("Roboto-Bold", 12px, #000000);
    background-color: #FFB300;
    border-radius: 3px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px;
}

.setup_fee_value {
    @include fontStyle("Roboto-Bold", 14px, #D0021B);
    padding-left: 0.5rem;
}

.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.active .layout-menuitem-icon,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.active .layout-menuitem-text,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.active .layout-submenu-toggler {
    color: #d0021b;
}