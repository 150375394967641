@import "../__variables";

.tab1 {
	border-radius: 4px 0 0 4px;

	a {
		background-color: transparent !important;
		border: 0 !important;

		span {
			color: #0c0c0c !important;
		}
	}
}

.p-tabmenuitem.p-highlight .p-menuitem-link {
	background-color: #02d088 !important;
	border: none !important;
}

.edge-tab-end {
	border-radius: 0 4px 4px 0;

	&.p-highlight {
		a {

			border: 0 !important;

			span {
				color: #fff !important;
			}
		}
	}

	a {
		background-color: transparent !important;
		border: 0 !important;

		span {
			color: #0c0c0c !important;
		}
	}
}

.default-tab {
	width: auto;
	background-color: white;
	font-family: "Roboto-bold" 14px #666678;

	&.p-highlight {
		a {
			border: 0 !important;

			span {
				color: #fff !important;
			}
		}
	}

	a {
		background-color: transparent !important;
		border: 0 !important;

		span {
			color: #0c0c0c !important;
		}
	}
}

.tab-success {
	&.p-highlight {
		a {
			background-color: rgba($color: #0f0, $alpha: 7) !important;
			border: 0 !important;

			span {
				color: #fff !important;
			}
		}
	}

	a {
		background-color: transparent !important;
		border: 0 !important;

		span {
			color: #0f0 !important;
		}
	}
}

.tabMenu {
	ul {
		background-color: transparent !important;
	}

	&.is-center {
		ul {
			background-color: transparent !important;
			display: flex;
			justify-content: center;
		}
	}
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
	margin-right: 0px !important;
	background-color: white;
	border: 1px solid black;
	margin: 0;
}

.users {
	background-color: rgba(red, 0.9) !important;

	i,
	.overview-subinfo {
		color: #ffb3b3 !important;
	}
}

.overview-box {
	padding: 16px;
	color: #ffffff;

	.overview-icon {
		font-size: 48px;
		float: right;
	}

	.overview-title {
		margin-bottom: 8px;
		display: inline-block;
		font-size: 18px;
	}

	.overview-arrow {
		color: #ffffff;
		margin-left: 8px;
		font-size: 16px;
	}

	.overview-numbers {
		margin-bottom: 12px;
		font-size: 18px;
	}

	.overview-subinfo {
		font-size: 13px;
		border-left: 4px solid;
		margin-left: -16px;
		padding-left: 12px;
	}

	&.sales {
		background-color: #42a5f5;

		.overview-icon {
			color: #bbdefb;
		}

		.overview-subinfo {
			border-color: #bbdefb;
			color: #bbdefb;
		}
	}

	&.views {
		background-color: #7e57c2;

		.overview-icon {
			color: #d1c4e9;
		}

		.overview-subinfo {
			border-color: #d1c4e9;
			color: #d1c4e9;
		}
	}

	&.users {
		background-color: #ffb300;

		.overview-icon {
			color: #ffecb3;
		}

		.overview-subinfo {
			border-color: #ffecb3;
			color: #ffecb3;
		}
	}

	&.checkin {
		background-color: #66bb6a;

		.overview-icon {
			color: #fff8e1;
		}

		.overview-subinfo {
			border-color: #fff8e1;
			color: #fff8e1;
		}
	}
}

.summary-section {
	display: flex;
	flex-wrap: wrap;
	background-color: #d0021b;
	max-width: 1600px;
	width: 100%;
	height: auto;
	border-radius: 10px;
	margin-top: 15px;
	margin-bottom: 25px;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	padding: 1rem;
	align-items: center;
	justify-content: space-between;
	padding-left: 0px;
	padding-bottom: 0px;
	padding-right: 1rem;
}

.p-overlaypanel-close {
	color: #ffffff !important;
	background-color: #303030 !important;
}

.card-title-overlay {
	background: #fe3031;
	width: auto;
	border-radius: 10px;
	color: #fff;
	min-width: 23%;
	height: 10rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 1rem;
	margin-bottom: 1rem;
	padding-bottom: 1rem;

	@media screen and (min-width: 990px) {
		transform: scale(1);
	}

	@media screen and (max-width: 1180px) {
		width: 45%;
	}

	@media screen and (max-width: 650px) {
		width: 98%;
		min-width: 10rem;
	}
}

.card-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.card-title {
	@include fontStyle("Roboto", medium 12px, #f2f2f2);
	margin-top: 0.5rem;
	margin-left: 0.6rem;
	font-weight: 800;
}

.card-value-prefix {
	@include fontStyle("Roboto", 16px, #f2f2f2);
	padding: 0.6rem;
}

.card-value {
	@include fontStyle("Roboto", 26px, #f2f2f2);
	font-weight: bold;
	word-break: "break-word";
}

.title {
	color: "#373738";
	font-weight: bold;
}

.hr {
	color: "#CCCCD2";
}

.desc {
	font-size: 14px;
}

.tab {
	border-color: #ccccd2 !important;

	&.p-highlight {

		a.p-menuitem-link {
			border: 0 !important;
			margin-left: 0px !important;

			span {
				color: #fff !important;
			}
		}
	}
}

.tab2 {
	border-radius: 4px 0 0 4px;
	width: 1px;
	border: 0 !important;

	a {
		background-color: transparent !important;
		border: 0 !important;

		span {
			color: #0c0c0c !important;
		}
	}
}

.next {
	border-radius: 0 0 0 0;

	a {
		background-color: transparent !important;
		border: 0 !important;

		span {
			color: #0c0c0c !important;
		}
	}
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
	margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
	box-shadow: none;
}

body .p-tabmenu .p-tabmenu-nav {
	border-bottom: 0;
	background: none;
}

.p-button.p-button-icon-only.p-button-rounded.cardSectionsButton {
	&:focus {
		box-shadow: none;
	}
}

.chart-info {
	display: flex;
	flex-direction: column;
}

.centerText {
	font-size: 12px;
	font-weight: lighter;
	margin-bottom: 8px;
}

.chart {
	border-radius: 8px;
}

#chart-amount {
	font-size: 16px;
	font-weight: bolder;
}

.chart-duration {
	font-size: 9px;
	font-weight: lighter;
}

.dashboard-charts {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px
}