@import "../_variables";

.report_cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 8px;
}

.report_cards>.reports-card-content {
    background: #ffffff;
    color: #000000;
}

.reports-card-content {
    background-color: #fff;
    width: 200px;
    border-radius: 4px;
    color: #000000;
    height: 7rem;
    margin-left: 1rem;
    padding: 5px 10px 10px 10px;
    height: auto;
    display: grid;
}

.chart_section {
    display: flex;
    flex-wrap: wrap;
}

.chart_section>.card {
    width: 45rem;
    margin: 1rem;
}

.card-titles {
    @include fontStyle("RobotoCondensed-bold", 12px, #000);
    margin-left: 5px;
}

.accepted-invitations,
.new-subscriptions,
.active-subscriptions,
.completed-subscriptions,
.collected-payments {
    color: #02d088;
}

.pending-invitations,
.paused-subscriptions,
.pending-payments {
    color: $rev_yellow;
}

.expired-invitations,
.cancelled-subscriptions,
.missed-payments {
    color: $rev_pri;
}

.reversed-transactions {
    color: #e74e69;
}

.bank-channel_type {
    color: #835E3A;
}

.mobile-channel_type {
    color: #9A4CFF;
}

.p-datepicker .report-calender .p-datepicker-header {
    background-color: #ffffff !important;
}

#report-calender {
    background-color: #ffffff !important;
}

.report_calender>.p-datepicker-header {
    background-color: #ffffff !important;
}

.activity-dropdown {
    div {
        background-color: $rev_blue !important;
    }
}

.custom-dropdown {
    width: 100%;
    height: 3rem;
    text-align: center;
    background-color: transparent !important;
    color: #495057;
    border-radius: 4px !important;

    div {
        background-color: #fff !important;
    }
}

.report-plan-customer-dropdown {
    background-color: $rev_blue !important;
}

#report-calender {
    color: #000;
    font-weight: 600;
}

.p-inputtext:disabled {
    background-color: #e0e0e0;
    color: #000;
}

.report-plan-customer-dropdown>.p-dropdown-trigger {
    background-color: $rev_blue !important;
    color: white !important;
}

.report-plan-customer-dropdown>.p-inputtext {
    color: white !important;
}

.summary-card-info {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 5px;
}

.summary-card-text {
    margin: 0px 5px 5px 5px;
    font-family: Roboto-Bold;
    font-size: 18px;
}

.summary-card-subDesc {
    margin: 0px 5px 5px 5px;
    font-size: 9px;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #f7f6f6;
}

.export-text {
    color: $rev_blue;
    font-family: Roboto-Bold;
    font-size: 11px;
    cursor: pointer;
}

body .p-autocomplete .p-autocomplete-input {
    padding: 0.76em;
}

.report-card-content {
    display: flex;
    margin-top: 0px;
    align-items: center;
}

.export-text:hover {
    color: #7fafbd;
}

.charts_main_div:nth-child(1) {
    page-break-before: always;
    page-break-after: always;
    background-color: #f2f2f2;
}

.hide-on-screen {
    display: none;
}

#report-plan-customer-dropdown>.p-dropdown-trigger {
    background-color: $rev_blue !important;
    color: white !important;
}

.report-calender>.p-button-icon-only {
    width: 2.5rem !important;
}

@media print {
    @page {
        size: portrait;
        background-color: #f2f2f2;
    }

    body * {
        visibility: hidden;
    }

    .hide-on-screen {
        display: block;
    }

    .section-to-print,
    .section-to-print * {
        visibility: visible;
    }

    .section-to-print {
        position: absolute;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100vh;
        top: 0;
        background-color: #f2f2f2;
    }

    .charts_main_div {
        page-break-before: always;
        page-break-after: always;
        background-color: #f2f2f2;
    }

    .table_div {
        margin: 0rem 1rem 1rem 1rem;
    }

    .labels_main_div {
        background-color: #d8d8de;
        display: flex;
        flex-direction: row;
        border-top-right-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
        width: 100%;
    }

    .info_div {
        width: 100%;
    }

    .labels {
        margin-bottom: 1rem;
        @include fontStyle("RobotoCondensed", 17px, #000);
    }

    .summary_title {
        margin: 2rem 0rem 0.3rem 1.5rem;
    }

    .summary {
        font-weight: bold;
        @include fontStyle("Roboto", 30px, #000);
    }

    .retrieved_date {
        @include fontStyle("Roboto", 16px, #000);
    }

    .generation_txt {
        @include fontStyle("Roboto", 12px, #000);
        margin-right: 0.2rem;
    }

    .reports-card-content {
        width: 190px;
    }

    .report_cards {
        display: flex;
        width: 100%;
    }

    .logo {
        height: 0.5rem;
        width: 5rem;
        margin-top: 0.4rem;
        margin-left: 0.3rem;
    }
}

#filters-card>.p-card-body {
    padding: 0.5rem 0.5rem 0.2rem 0.8rem;
}

#filters-card>.p-card-body>.p-card-content {
    padding: 0.5rem 0.5rem 0.2rem 0.8rem;
}

.report_amount_label,
.report_account_name_label,
.report_name_label {
    @include fontStyle("Roboto", 11px, #495057);
}