@import "../__variables";

.info_div {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    height: auto;
    display: flex;
    justify-content: row;
    border: 2px solid #d8d8de;
    border-top: none;
    border-top-left-radius: none;
    border-top-right-radius: none;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.bus_name {
    @include fontStyle("RobotoCondensed-bold", 20px, #000);
    display: flex;
    text-align: right;
}

.customer_p {
    margin-left: auto;
    margin-top: 0.5rem;
    font-weight: bolder;
    @include fontStyle("RobotoCondensed", 16px, #000);
}

.cus_info,
.bus_info {
    @include fontStyle("RobotoCondensed", 17px, #000);
}

.busNo {
    margin: 2rem 0rem 0.5rem;
}

.busEmail {
    margin-bottom: 0.5rem;
}

.bus_info_div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
}

.hr_tag {
    background-color: #d8d8de;
    height: 1.3px;
    margin-top: 3rem;
}

.invoice_txt {
    margin: 0.5rem 0rem 0.2rem;
    font-weight: bold;
    @include fontStyle("RobotoCondensed", 20px, #000);
}

.invoice_val {
    @include fontStyle("RobotoCondensed", 17px, #97979b);
    margin-top: 0.2rem;
}

#print_btn {
    display: block;
    margin-bottom: 25px;
    margin-left: 6px;
    background-color: #d0021b;
    border: none;
}

.print_section {
    background-color: white;
    padding: 1.5rem;
}

.bus_info_main_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    margin-top: 1rem;
}

.generation-div {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
}

.generation_img {
    height: 0.9rem;
    width: 5rem;
    margin-top: 0.2rem;
    margin-right: 0.3rem;
}

@media print {
    @page {
        size: portrait;
    }

    body * {
        visibility: hidden;
    }

    .section-to-print,
    .section-to-print * {
        visibility: visible;
    }

    .section-to-print {
        position: absolute;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100vh;
        top: 0;
    }

    .bus_info_div {
        margin-bottom: 2rem;
    }

    .info_div {
        display: flex;
        justify-content: row;
        border: 1px solid #d8d8de;
        border-top: 0;
        margin: 0;
        width: 100%;
        min-width: 100%;
    }

    .right-span-first-last {
        display: flex;
        border: 1px solid #d8d8de;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        font-family: "RobotoCondensed-Bold";
        font-size: 30px;
        color: rgb(36, 35, 35);
    }

    .paymentAmount {
        font-weight: bold;
        @include fontStyle("RobotoCondensed", 37px, #000);
        padding-left: 1rem;
        display: flex;
        flex-direction: row;
    }

    .transactions_payments_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
    }

    .sidebars {
        width: 100%;
        min-width: 100%;
        margin-top: 1rem;
    }

    .hr_tag {
        background-color: none;
        height: 0;
        margin-top: 2rem;
    }

    .last_div {
        height: 3.2rem;
    }

    .generation-div {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .generation_img {
        height: 0.8rem;
        width: 5rem;
        margin-bottom: 0.2rem;
    }
}

.payment-style {
    @include fontStyle("Roboto" !important, 10px !important, normal);
    color: #495057;
}

.paymentID {
    height: 5rem;
    font-weight: bold !important;
}

.firstPayment {
    @include fontStyle("Roboto-bold" !important, 12px !important, #66BB6A !important);
    padding-bottom: 10px;
    height: 1.7rem;
}

.feePayment {
    @include fontStyle("Roboto-bold" !important, 12px !important, #FE3031 !important);
    height: 5rem;
}

.revenue {
    @include fontStyle("Roboto-bold" !important, 12px !important, #008DD5 !important);
    height: 5rem;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup>.p-inputwrapper:last-child,
.p-inputgroup>.p-inputwrapper:last-child>.p-inputtext {
    margin-right: 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.div_logo_side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.2rem;
}

.businessLogoStyle {
    height: 70px;
    width: 70px;
}

.cusNo {
    padding: 0.5rem 0rem 0.5rem;
}

.plan_description {
    @include fontStyle("RobotoCondensed", 17px, #000);
}

.plan_description_main_div {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1rem !important;
}

.paymentAmount {
    font-weight: bold;
    @include fontStyle("RobotoCondensed", 37px, #000);
    padding-top: 0.5rem;
    display: flex;
    flex-direction: row;
}

.plan-name_description {
    @include fontStyle("RobotoCondensed", 19px, #000);
}

.border1 {
    border: 2px solid #d8d8de;
    border-radius: 5px;
}

.second-border2 {
    padding-top: 0.5rem;
    align-items: center;
    padding-left: 1rem;
}

.payments-setup {
    @include fontStyle("RobotoCondensed", 18px, #000);
}
.setupAmount {
    @include fontStyle("RobotoCondensed", 30px, #000);
}
.total-label {
    @include fontStyle("RobotoCondensed", 37px, #000);
    font-weight: 500;
}
.payments-total {
    @include fontStyle("RobotoCondensed", 37px, #000);
    font-weight: 500;
}
.setup_fee_div_payments {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 0.6rem;
}

.border2 {
    border: 2px solid #d8d8de;
    border-radius: 5px;
    margin: 0.8rem;
}

.reference_div {
    margin-right: 0.5rem;
}

.payment_method_div {
    margin-top: 1.5rem;
    margin-right: 0.5rem;
}

.subscriber-info-header {
    background-color: #d8d8de;
    border: 2px solid #d8d8de;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 0px;
}

.subscriber-info-div {
    padding: 0.5rem 0rem;
    border: 2px solid #d8d8de;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 0px;
}

.span_divs {
    height: 3.5rem;
    border: 2px solid #d8d8de;
    border-radius: 5px;
}

.left_span_divs {
    background-color: #d8d8de;
    display: flex;
    align-items: center;
}

.left_spans {
    @include fontStyle("RobotoCondensed", 16px, #000);
    margin-left: auto;
}

.right_spans {
    @include fontStyle("RobotoCondensed", 16px, #000);
}

.right_span_divs {
    display: flex;
    text-align: left;
    align-items: center;
}

.subs_reference {
    padding: 0rem 0rem 0.5rem;
}

#businessCustomerNum {
    text-align: left;
}