@import "__variables";

#data_table {
    margin-bottom: 20px;
    cursor: pointer;
    .datatable-cell-default {
        border: 0px;
        border-bottom: 1px solid #e0e0e0;
        padding: auto;
        color: #000;
        


        .p-checkbox-box {
            margin-left: 0;
        }
    }
    .datatable-header-default .p-column-title {
        @include fontStyle("Roboto", 14px, #666678);
        text-transform: capitalize;
        font-weight: 700;
    }
    a,
    .link {
        margin: 0;
        color: black;
        cursor: pointer;

        &:hover {
            color: #2196f3;
        }
    }
    padding: 0px;
    // width: 90%;
    .p-datatable-header {
        padding: 0px;
    }
    .p-datatable-wrapper {
        padding: 1rem;
    }
    a {
        color: $rev_blue;
        cursor: pointer;
    }
}
