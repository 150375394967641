.p-treeselect .p-treeselect-label.p-placeholder {
    color: #9b9b9b;
    padding-top: 0.7rem;
    font-family: Roboto;
}

.field-checkbox label {
    font-family: Roboto;
}

.p-dialog-content {
    overflow-y: none;
    flex-grow: 1;
}

.invite-phone>div>.PhoneInputCountry {
    display: none !important;
}

.invite-phone>div>.PhoneInputInput {
    padding: 0.7rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #ffffff;
}

.p-dialog .p-dialog-header-icons :hover {
    border-radius: 50% !important;
    background-color: #d4d0d0 !important;
}

.migrate_description2 {
    color: #d0021b;
    font-size: 16px;
    font-family: Roboto-bold;
}

body .p-datepicker .p-datepicker-header .p-datepicker-prev,
body .p-datepicker .p-datepicker-header .p-datepicker-next {
    color: #ffffff;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #ffffff !important;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #d0021b !important;
}

.sourceName {
    font-family: Roboto-Bold;
    font-size: 14px;
    color: #23547B;
}

.isterik {
    color: #d0021b;
    font-size: 12px;
}

.red_txt {
    color: #d0021b;
    font-family: Roboto-Bold;
    font-size: 16px;
}

.migrate_tier_labels {
    color: #000;
    font-family: Roboto;
    font-size: 11px;
    font-weight: bold;
}

.desc {
    font-size: 16px;
    color: #000;
    font-family: Roboto;
}

.targetName {
    font-style: Roboto-Bold;
    font-size: 14px;
    color: #256029;
    font-weight: bold;
}

.migrate_at_text2 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    color: #256029;
}

.tier_name_green_background {
    margin-top: 0.4rem;
    background-color: rgba(202, 231, 203, 0.5);
    border-radius: 4px;
    padding: 0.3rem;
    height: max-content;
}

.desc_title {
    color: #23547B;
    font-weight: 700;
    font-size: 14px;
}

.flex_styling {
    display: flex;
    flex-direction: row;
}

.tier_label {
    color: #000;
    font-size: 16px;
    font-weight: 700;
}

.tier_name_blue_background {
    margin-top: 0.4rem;
    height: 5vh;
    background-color: rgba(179, 229, 252, 0.5);
    border-radius: 4px;
    padding: 0.3rem;
    height: max-content;
}

.migrate_at_text1 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    color: #23547B;
}