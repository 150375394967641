@import "../__variables";

@media print {
      @page {
            size: A4;
            margin: 0;
      }

      html,
      body {
            width: 210mm;
            height: 297mm;
            margin: 0;
            padding: 0;
      }
}

.main_div_heading {
      display: flex;
      flex-direction: column;
}

.div_img_heading {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 1rem;
}

.image_style {
      height: 7.5rem;
      width: 7.6rem;
}

.business_logo {
      margin-top: 0.5rem;
      width: 32%;
      height: auto;
      display: flex;
}

.bold_heading {
      @include fontStyle("RobotoCondensed-bold", 38px, #000000);
}

.second_bold_heading {
      display: flex;
      justify-content: flex-end;
}

.scan_text {
      @include fontStyle("Roboto-bold", 100px, #D0021B);
      margin-top: 1rem;
}

.pdf_qrCode {
      width: 540px;
      height: 540px;
      border: 2px solid #D0021B;
      border-radius: 20px;
      margin: 1rem 0rem 1.5rem 0rem;
}

.process_tags {
      @include fontStyle("RobotoCondensed", 30px, #FFFFFF);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.4rem 1rem 0rem 0rem;
      height: 37px;
      width: 37px;
      border-radius: 50%;
      background-color: #D0021B;
}

.process_tag_div {
      display: flex;
      flex-direction: row;
      margin-left: 2rem;
      margin-top: 1rem;
}

.process_tag_hr {
      margin-top: 1.4rem;
      height: 1px;
      width: 11rem;
      background-color: #D0021B;
}

.process_tag_2,
.process_tag_3,
.process_tag_4 {
      padding-left: 0.7rem;
}

.pdf_print_section {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      border: 6px solid #E8E8E8;
      background-color: #F7E7F0;
      width: 100%;
      max-width: 900px;
}

.main_div_process_texts {
      display: flex;
      flex-direction: row;
      padding: 2rem 0rem 1rem 1rem;
      justify-content: space-around;
}

.process_texts {
      display: flex;
      flex-direction: column;
}

.first_process_texts {
      margin-right: 7rem;
}

.process_texts_second {
      margin-left: 0.8rem;
}

.process_texts_last_two {
      margin-left: 8rem;
}

#transparent_logo {
      width: 9rem;
      margin-right: 2rem;
}

.business_phone {
      @include fontStyle("RobotoCondensed-bold", 25px, #000000);
      margin-left: 2rem;
}

.bold_process_texts {
      @include fontStyle("RobotoCondensed-Bold", 16px, #000000);
}

.powered_text {
      @include fontStyle("RobotoCondensed", 20px, #000000);
      padding-right: 0.5rem;
}

.pdf_footer {
      border: 2.5px solid #D0021B;
      height: 3rem;
      width: 95%;
      border-radius: 1.5rem;
      display: flex;
      justify-content: space-between;
      margin: 1.5rem 1rem 1.5rem 1.2rem;
      align-items: center;
}