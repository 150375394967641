@import "../__variables";

.loader2 {
	margin: 0 auto;
	width: 100%;
	height: 50px;
	text-align: center;
	font-size: 10px;
	display: flex;
	justify-content: center;
	z-index: 500;
	text-shadow: 0 0 10px #fff;
	margin-top: 400px;

	>div {
		height: 100%;
		width: 8px;
		float: left;
		margin-left: 2px;
		animation: delay 0.8s infinite ease-in-out;
	}

	.bar1 {
		background-color: #754fa0;
	}

	.bar2 {
		background-color: #09b7bf;
		animation-delay: -0.7s;
	}

	.bar3 {
		background-color: #90d36b;
		animation-delay: -0.6s;
	}

	.bar4 {
		background-color: #f2d40d;
		animation-delay: -0.5s;
	}

	.bar5 {
		background-color: #fcb12b;
		animation-delay: -0.4s;
	}

	.bar6 {
		background-color: #ed1b72;
		animation-delay: -0.3s;
	}
}

@keyframes delay {

	0%,
	40%,
	100% {
		transform: scaleY(0.05);
		-webkit-transform: scaleY(0.05);
	}

	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

#second-card {
	background-color: white;
}

#third-card {
	box-shadow: none !important;
	background-color: white !important;
}

.text-card {
	background-color: none !important;
	border: none !important;
}

.info {
	@include fontStyle("Roboto-Bold", 24px, #000);
}

.info-label {
	@include fontStyle("RobotoCondensed-Bold", 18px, rgb(36, 35, 35));
}

.info-detail {
	@include fontStyle("Roboto", 18px, #000)
}

#display_name {
	@include fontStyle("Roboto-Bold", 24px, #000);
}

#role {
	@include fontStyle("Roboto-Bold", 16px, rgb(36, 35, 35));
}

@media only screen and (max-width: 1313px) {
	#main-card {
		width: 100%;
	}
}

#avatar>.p-avatar-text {
	font-size: 40px;
}

.edit-button {
	display: flex;
	justify-content: flex-end;
	padding-right: 6rem;
}

#avatar {
	background-color: #666;
	color: #ffffff;
	height: 80px;
	width: 80px;
	border-radius: 50%;
	font-size: 40px;
}

.p-fileupload-content>.p-progressbar {
	width: 0px !important;
}


.p-fileupload .p-fileupload-buttonbar {
	background-color: white !important;
	border: none !important;
}

.p-fileupload .p-fileupload-content {
	border: none !important;
}

.user-info {
	font-size: 13px;
	margin-bottom: 2px;
	color: #727272;
}

.profile_img {
	width: 60px;
	height: 60px;
}