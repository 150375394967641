@import "../__variables";

body .planShare {
  border-radius: 0.5rem;
  overflow: hidden;
  width: 46rem;

  &Header.p-dialog-header {
    background-color: $rev_pri !important;
    color: #fff;
    padding-left: 1.5rem;
  }

  &Header.p-dialog-header .p-dialog-header-icon {
    color: #fff;
  }

  .border{
    border: 1px solid rgb(184, 184, 245) ;
    background-color: white ;
    color: #7c7a7a ;
    letter-spacing: 0.12rem;
    font-family: "RobotoCondensed-SemiBold";
    font-size: 2rem;
    opacity: 0.7;
    width: 15rem;
    margin: 0 0 1rem 0;
  }

  .borderless {
    border: none !important;
    background-color: white !important;
    color: #000 !important;
    letter-spacing: 0.2rem;
    font-family: "RobotoCondensed-SemiBold";
    font-size: 2.5rem;
    opacity: 0.7;
    width: auto;
    margin: 0 0 1rem 0;
  }
  
  .changePin{
    border: 1px solid rgb(184, 184, 245) ;
    background-color: white ;
    color: #000000 !important;
    letter-spacing: 0.12rem;
    font-family: "RobotoCondensed-SemiBold";
    font-size: 2rem;
    opacity: 0.7;
    width: 15rem;
    text-align: start;
  }

  .p-checkbox {
    margin-right: 1rem;

    &-hint {
      font-size: 10px;
      margin-left: 3rem;
    }
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #666;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }

  & .p-dialog-content {
    padding: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .save_btn {
    height: 2rem;
    background-color: $rev_pri;
    margin-bottom: 0.5rem;
  }

  .copy_btn {
    margin-top: 0px;
    height: 2rem !important;
  }

  .change_pin {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1rem;
    padding: 0;
  }

  .p-inputtext {
    font-size: 2rem;
    width: 7.5rem;
    border: 2px solid #5d88d6a1;
  }

  .p-error {
    font-size: 10px;
  }

  .share_dialog .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: white;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #fff !important;
  }
}

.share-title {
  color: #000;
  font-family: "Roboto-Bold";
  font-size: 14px;
}
.styleCode{
  @include fontStyle("RobotoCondensed", 15px, #000, bold);
}
.text-dis{
  display: flex;
  flex-direction: column;
}
.title_btn{
  display: flex;
  justify-content: space-between;
}
.productShare-box{
  border: 1.7px solid #9747ff;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.hint-style{
  color: #9747ff;
  display: block;
}
.pin-box{
  margin: 0 0 0 1rem;
}
.text-change{
  text-align: start !important;
  margin-top: 0 !important;
}
.main-hint{
  font-size: 13px;
  font-weight: bold;
}