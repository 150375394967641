@import "../__variables";

.p-card {
    width: 70%;
    margin-bottom: 2em;
    margin-left: 2em;
    border-radius: 8px;
}

.detail_card {
    width: 98%;
    margin-left: 0;
    margin-bottom: 20px;
    display: inline-block;
}

.customer_detail_pagination, .subscription_detail_pagination{
    margin-left: 1rem;
    width: 100%;
}

.customer-name {
    color: #008dd5;
    font-family: "Roboto-Medium";
    font-size: 16px;
}

.customer-info {
    color: #495057;
    font-family: "Roboto";
    font-size: 16px;
}

#customer-id {
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
}

.width {
    max-width: 120px;
}

.plan-details {
    color: #000000;
    font-family: "Roboto-Bold";
    font-size: 20px;
}

.plan-tier-details {
    color: #000000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
}

.status-content {
    max-width: 94px;
    margin-right: 1em;
    margin-bottom: "1.5rem";
}

.plan-policy {
    font-weight: bold;
    margin-left: 2px;
}

.button-content {
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
}

.deductions-content {
    background-color: #008dd5;
    border: 0px;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    font-family: "Roboto-Bold";
}

.total-received {
    background-color: #4caf50;
    border: 0px;
    color: black;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    font-weight: bolder;
}

.missed-content {
    background-color: #d0021b;
    border: 0px;
    outline: none;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    font-weight: bolder;
}

.notice {
    color: #000000;
    font-family: "Roboto-Bold";
    font-size: 16px;
    margin-left: 5px;
}

.alert {
    margin-bottom: 10px;
}

#side-menu {
    color: $rev_pri;
    margin-left: 2rem;
    cursor: pointer;

    span {
        cursor: pointer;
        font-weight: bold;

        &:hover {
            color: rgba($rev_pri, 0.3);
        }

        &:active {
            color: #0074ae;
        }
    }

    .pi {
        margin-right: 20px;
        font-weight: bold;
    }
}

.pi-pencil {
    // margin-right: 20px;
    font-weight: bold;
}

.pi-info-circle {
    size: 24px;
    // margin-right: 5px;
}

.bold-style {
    font-weight: bold;
    background-color: red;
}

.status-badge {
    max-width: 94px;
    margin-right: 2em;
    margin-top: 6px;
}

.transactions-title {
    color: #008dd5;
    font-weight: bold;
    font-family: "Roboto";
    font-size: 12px;
    margin-top: 0;
    // margin-left: 2.3rem;
}

.subs_info_content {
    margin-bottom: 15px;
    width: auto;
    margin-top: 10px;
}

.name-content {
    max-width: 150px;
}

.subs-customer-id {
    color: #495057;
    font-weight: lighter;
}

.plan-name {
    margin-bottom: 0px;
}

.plan-group-content {
    margin-bottom: 12px;
}

.deduction {
    margin-left: 15px;
}

.subs-deduction {
    color: #000000;
    font-size: 16px
}

.subs-buttons-info {
    margin-bottom: 10px;
}

.subs-status {
    font-family: Roboto;
    font-size: 14px;
    padding: 10px;
    margin-right: 33px;
}

.subs-icon {
    margin-right: 5px;
}

.subs-button-content {
    margin-top: 1px;
    width: auto;
}

.ded-policy-text {
    margin-left: 5px;
    color: #495057;
}

.spacing {
    width: auto;
}

.subs-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.date, .time{
	color: #666678;
	font-family: Roboto;
	font-size: 12px;
	font-weight: 400;
	text-align: end;
}

.subscriptionDetailCard{

    &-name{
        color: #008dd5;
        font-weight: bold;
    }
    &-phoneNo{
        color: #d0021b;
        font-weight: bold;
    }
    &-stroke{
        color: #000000;
        font-weight: bold;
    }

}
.notice-alert{
    margin-bottom: 1rem;
    font-family: "RobotoCondensed-Bold";
    font-size: 11px;
    color: #000;
    font-weight: normal;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
    background-color: rgba(179, 229, 252, 0.5);
    border-left: 3px solid #23547B;
    font-weight: 600;
}
.text-col{
    font-family: "Roboto";
    font-size: 10px !important;
    color: #000000;
}
.trans_fee{
    font-family: "Roboto-bold";
    font-size: 12px;
    color: #D0021B;
    font-weight: normal;
}
.trans_redeemable{
    font-family: "Roboto-bold";
    font-size: 12px;
    color: #008DD5;
    font-weight: normal;
}
.missed-pay{
    color: #D0021B;
    font-size: 18px;
    font-weight: bold;
}
.date-format{
    font-family: "Roboto-bold";
    font-size: 12px;
    color: #000000;
    font-weight: normal
}
.notice-label{
    font-family: "RobotoCondensed-Bold";
    font-size: 14px;
    color: #000;
    font-weight: normal;
}
.flex_label{
    font-size: 12px ;
    color: #d0021b;
}